import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetPoliticiansDTO } from '../../dto/politician'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { POLITICIANS_ACTIONS } from './politicians.constants'

export const getPoliticians = async () => {

    const search = store.getState().politicians.search
    const order = store.getState().politicians.order

    const promise = createCall(`politicians?search=`+search+`&order=`+order, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetPoliticiansDTO

        try {
            body = plainToClass( GetPoliticiansDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_INVALID_JSON } )
            return
        }

        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_INVALID_RESPONSE } )
            return
        }

        store.dispatch( { type: POLITICIANS_ACTIONS.GET_POLITICIANS_OK, politicians: body.politicians } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
