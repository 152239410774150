import { GamificationState } from '../state'
import { message } from '../helper'

const initialState: GamificationState  = {

    questGroups: [],
    quests: [],
    league: null,
    leagues: [],
    prizes: [],
    defaultExperiencePoints: [],
    advancedUsers: [],
    advancingUsers: [],
    notAdvancingUsers: [],
    userRounds: [],
    userRoundsPagination: {
        page: 1,
        from: 0,
        to: 0,
        count: 0
    },
    userRoundsOrder: {
        by: `ur.id`,
        direction: `ASC`
    },
    stats: null,

    years: {}

}

export const gamificationReducer = ( state: GamificationState = initialState, action: any ): GamificationState => {

    let i

    switch ( action.type ) {

        case `QUEST_GROUPS_ADD_QUEST`:
            ( state.questGroups[ action.index ] as any ).quests.push( {
                name: ``,
                type: 0,
                questConditionId: 5,
                contentId: null,
                repeats: 1
            } )
            return { ...state, questGroups: state.questGroups }

        case `QUEST_GROUPS_DELETE_QUEST`:
            state.questGroups[ action.questGroupIndex ].deleteQuests.push( state.questGroups[ action.questGroupIndex ].quests[ action.index ].id )
            state.questGroups[ action.questGroupIndex ].quests!.splice( action.index, 1 )
            return { ...state, questGroups: state.questGroups }

        case `QUEST_GROUPS_DELETE`:
            state.questGroups.splice( action.index, 1 )
            return { ...state, questGroups: state.questGroups }

        case `CREATE_QUEST_GROUP_OK`:
            return { ...state, questGroups: [ ...state.questGroups, action.questGroup ] }

        case `QUEST_GROUPS_QUEST_UPDATE_FIELD`:
            if ( action.field === `repeats` && action.value < 1 ) { action.value = 1 }
            ( ( state.questGroups[ action.questGroupIndex ] as any ).quests[ action.index ] as any)[ action.field ] = action.value
            return { ...state, questGroups: state.questGroups }

        case `QUEST_GROUPS_UPDATE_FIELD`:
            ( state.questGroups[ action.index ] as any )[ action.field ] = action.value
            return { ...state, questGroups: state.questGroups }

        case `LIST_QUEST_GROUPS_OK`:
            return { ...state, questGroups: action.questGroups }


        case `QUEST_UPDATE_FIELD`:
            if ( action.field === `repeats` && action.value < 1 ) { action.value = 1 }
            ( state.quests[ action.index ] as any )[ action.field ] = action.value
            return { ...state, quests: state.quests }

        case `QUEST_DELETE`:
            state.quests.splice( action.index, 1 )
            return { ...state, quests: state.quests }

        case `LIST_QUESTS_OK`:
            return { ...state, quests: action.quests }

        case `CREATE_QUEST_OK`:
            return { ...state, quests: [ ...state.quests, action.quest ] }


        case `GET_LEAGUE_OK`:
            return { ...state, league: action.league }


        case `LIST_LEAGUES_OK`:
            return { ...state, leagues: action.leagues }


        case `GET_LEADERBOARD_OK`:
            return { ...state, advancedUsers: action.advancedUsers, advancingUsers: action.advancingUsers, notAdvancingUsers: action.notAdvancingUsers }


        case `LIST_PRIZES_OK`:
            return { ...state, prizes: action.prizes }


        case `UPDATE_LEAGUE`:
            ( state.league as any )[ action.field ] = action.value
            return { ...state, league: Object.assign( {}, state.league ) }


        case `LIST_DEFAULT_EXPERIENCE_POINTS_OK`:
            return { ...state, defaultExperiencePoints: action.defaultExperiencePoints }

        case `LIST_USER_ROUNDS_OK`:
            return { ...state, userRounds: action.userRounds, userRoundsPagination: action.pagination }

        case `USER_ROUND_UPDATE`:
            i = state.userRounds.findIndex( userRound => userRound.id === action.id )
            if ( i === -1 ) { return state }
            ( state.userRounds[ i ] as any )[ action.field ] = action.value
            return { ...state, userRounds: [ ... state.userRounds ] }

        case `USER_ROUNDS_CHANGE_ORDER`:
            return { ...state, userRoundsOrder: { by: action.by, direction: action.direction } }

        case `SAVE_QUEST_OK`:
            message( `A küldetés sikeresen mentésre került!` )
            return state

        case `DELETE_QUEST_OK`:
            message( `A küldetés sikeresen törlésre került!` )
            return state

        case `SAVE_QUEST_GROUP_OK`:
            message( `A küldetés csoport sikeresen mentésre került!` )
            return state

        case `DELETE_QUEST_GROUP_OK`:
            message( `A küldetés csoport sikeresen törlésre került!` )
            return state

        case `SAVE_USER_ROUND_OK`:
            message( `A nyertes adatai sikeresen mentésre kerültek!` )
            return state

        case `DELETE_WINNER_OK`:
            message( `A nyertes sikeresen törlésre került!` )
            return state

        case `SAVE_LEAGUE_OK`:
            message( `A liga adatai sikeresen mentésre kerültek!` )
            return state

        case `SAVE_PRIZE_OK`:
            message( `A nyeremény adatai sikeresen mentésre kerültek!` )
            return state


        case `SEND_PUSH_LEAGUE_OK`:
            message( `Az értesítés sikeresen kiküldésre került!` )
            return state


        case `LIST_LEAGUE_USERS_OK`:
            return { ...state, advancedUsers: action.users.advancedUsers, advancingUsers: action.users.advancingUsers, notAdvancingUsers: action.users.notAdvancingUsers }


        case `GET_LEAGUE_STATS_OK`:
            return { ...state, stats: action.stats }


        case `LIST_ROUNDS_OK`:
            return { ...state, years: action.years }

        case `TOGGLE_YEAR`:
            const years = { ...state.years }
            years[ action.year ].open = !( years[ action.year ].open === true )
            return { ...state, years: years }

        case `TOGGLE_WEEKGROUP`:
            const y = { ...state.years }
            y[ action.year ][ action.weekGroup ].open = !( y[ action.year ][ action.weekGroup ].open === true )
            return { ...state, years: y }

        case `TOGGLE_WEEK`:
            const y2 = { ...state.years }
            y2[ action.year ][ action.weekGroup ][ action.index ].open = !( y2[ action.year ][ action.weekGroup ][ action.index ].open === true )
            return { ...state, years: y2 }

        default:
            return state

    }

}
