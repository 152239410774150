import { AchievementsState } from "../state"
import { message } from '../helper'

const initialDashboardState: AchievementsState  = {

    achievementGroups: []

}

export const achievementsReducer = ( state: AchievementsState = initialDashboardState, action: any ): AchievementsState => {
    switch ( action.type ) {

        case `LIST_ACHIEVEMENT_GROUPS_OK`:
            return { ...state, achievementGroups: action.achievementGroups }

        case `CREATE_ACHIEVEMENT_GROUP_OK`:
            message( `A kitüntetés sikeresen mentésre került!` )
            return state

        case `MODIFY_ACHIEVEMENT_GROUP_OK`:
            message( `A kitüntetés sikeresen mentésre került!` )
            return state

        case `DELETE_ACHIEVEMENT_GROUP_OK`:
            message( `A kitüntetés sikeresen törlésre került!` )
            return state

        default:
            return state
    }
}
