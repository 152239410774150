import './winner-modal.scss'
import { prizeType } from '../../helper'
import { TextField } from '../field'
import { TextArea } from '../textarea'
import { Modal } from '../modal'
import { useState } from 'react'

interface WinnerModalProps {

    winner: any

    onSave?: ( winner: any ) => void
    onClose?: () => void

}

export const WinnerModal = ( props: WinnerModalProps ) => {

    const [ form, setForm ] = useState( props.winner )

    const updateField = ( field: string, value: any ) => {

        const newForm = { ... form }
        newForm[ field ] = value
        setForm( newForm )

    }

    const onSave = () => {

        if ( props.onSave ) props.onSave( form )

    }
    const onClose = () => {

        if ( props.onClose ) props.onClose()

    }

    return (

        <Modal className="winner-modal" onModalClose={ onClose }>

            <div className="modal-title">{ props.winner.id } - { props.winner.name }</div>

            <div className="columns">

                <div className="field">

                    <label>Nyeremény típusa</label>
                    <span>{ prizeType( parseInt( props.winner.prize_type ) ) }</span>

                </div>

                <div className="field">

                    <label>Nyereménye</label>
                    <span>{ props.winner.prize_type === `2` ? `${ props.winner.prize_exp } tapasztalati pont` : props.winner.prize_name }</span>

                </div>

                { props.winner.prize_type == 0 ? (

                    <div className="field">

                        <label style={{ alignSelf: `flex-start`, marginTop: 10 }}>Cím</label>
                        <div>

                            <TextField field={{ value: form.country ?? ``, error: `` }} onChange={ e => updateField( `country`, e.target.value ) } placeholder="Ország" />

                            <TextField field={{ value: form.city ?? ``, error: `` }} onChange={ e => updateField( `city`, e.target.value ) } placeholder="Város" />

                            <TextField field={{ value: form.zipcode ?? ``, error: `` }} onChange={ e => updateField( `zipcode`, e.target.value ) } placeholder="Irányítószám" />

                            <TextField field={{ value: form.address ?? ``, error: `` }} onChange={ e => updateField( `address`, e.target.value ) } placeholder="Utca" />

                            <TextField field={{ value: form.houseNumber ?? ``, error: `` }} onChange={ e => updateField( `houseNumber`, e.target.value ) } placeholder="Házszám" />

                            <TextField field={{ value: form.floorAndDoor ?? ``, error: `` }} onChange={ e => updateField( `floorAndDoor`, e.target.value ) } placeholder="Emelet, ajtó" />

                        </div>

                    </div>

                ) : null }

                <div className="field">

                    <label>E-mail cím</label>
                    <TextField field={{ value: form.email ?? ``, error: `` }} onChange={ e => updateField( `email`, e.target.value ) } placeholder="E-mail cím" />

                </div>

                <div className="field">

                    <label>Státusz</label>
                    <TextField field={{ value: form.status ?? ``, error: `` }} onChange={ e => updateField( `status`, e.target.value ) } placeholder="Feldolgozásra vár" />

                </div>

                <div className="field">

                    <label style={{ alignSelf: `flex-start`, marginTop: 2 }}>Kategória színe</label>
                    <div>

                        <div className="color-bar" style={{ backgroundColor: form.color }}></div>
                        <div className="color-select">

                            { [ `#27B06C`, `#C30870`, `#FFD201` ].map( color => (

                                <div className={ `color${ form.color === color ? ` color--selected` : `` }` } onClick={ () => updateField( `color`, color ) }>

                                    <div className="color-circle" style={{ backgroundColor: color }}></div>

                                </div>

                            ) ) }

                        </div>

                    </div>


                </div>

                <div className="field">

                    <label style={{ alignSelf: `flex-start`, marginTop: 10 }}>Megjegyzés</label>
                    <TextArea field={{ value: form.note ?? ``, error: `` }} onChange={ e => updateField( `note`, e.target.value ) } />

                </div>

            </div>

            <footer>

                <button className="button button--primary" style={{ minWidth: 182 }} onClick={ onSave }>Mentés</button>

            </footer>

        </Modal>

    )

}