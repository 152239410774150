import { createFormattedDateString, message } from '../helper'
import { UsersState } from '../state'

const initialUsersState: UsersState = {
    order: {
        by: '',
        direction: 'ASC'
    },
    stats: {
        usersCount: 0,
        last30days: 0,
        last30daysbefore: 0,
        activelast30days: 0,
        activelast30daysbefore: 0
    },
    user: null,
    userActivities: [],
    interests: [],
    userForm: {},
    userTags: [],
    users: [],
    pagination: {
        from: 1,
        to: 10,
        count: 10,
        page: 1
    },
    userGroups: [],
    searchTerm: '',
    selectedUser: null,
    forgotPasswordBusy: false,
    forgotPasswordEmailRequested: null,
    resetPasswordBusy: false,
    resetPasswordEmailRequested: null,
    resetTokenValid: null,
    adminSetForm: {
        firstName: {
            error: ``,
            required: true,
            value: ``
        },
        lastName: {
            error: ``,
            required: true,
            value: ``
        }
    },
    setAdminPasswordBusy: false,
    setAdminPasswordDone: null,
    setAdminPasswordTokenValid: null,
    adminActivities: []

}

export const usersReducer = ( state: UsersState = initialUsersState, action: any ): UsersState => {

    switch ( action.type ) {

        case 'GET_USER_INVALIDJSON':
        case 'GET_USER_INVALIDRESPONSE':
        case 'GET_USER_NOUSER':
        case 'GET_USER_TIMEOUT':
        case 'GET_USER_UNAUTHORIZED':
        case 'GET_USER_UNEXPECTEDRESPONSE':
            return state

        case 'GET_USER_OK':
            return { ...state, user: action.user }

        case 'GET_USER_STATS_INVALIDJSON':
        case 'GET_USER_STATS_INVALIDRESPONSE':
        case 'GET_USER_STATS_NOUSER':
        case 'GET_USER_STATS_TIMEOUT':
        case 'GET_USER_STATS_UNAUTHORIZED':
        case 'GET_USER_STATS_UNEXPECTEDRESPONSE':
            return state

        case 'GET_USER_STATS_OK':
            return { ...state, stats: action.stats }

        case 'GET_USER_ACTIVITIES_INVALIDJSON':
        case 'GET_USER_ACTIVITIES_INVALIDRESPONSE':
        case 'GET_USER_ACTIVITIES_NOUSER':
        case 'GET_USER_ACTIVITIES_TIMEOUT':
        case 'GET_USER_ACTIVITIES_UNAUTHORIZED':
        case 'GET_USER_ACTIVITIES_UNEXPECTEDRESPONSE':
            return state

        case 'GET_USER_ACTIVITIES_OK':
            return { ...state, userActivities: action.userActivities }

        case 'GET_INTERESTS_INVALIDJSON':
        case 'GET_INTERESTS_INVALIDRESPONSE':
        case 'GET_INTERESTS_NOUSER':
        case 'GET_INTERESTS_TIMEOUT':
        case 'GET_INTERESTS_UNAUTHORIZED':
        case 'GET_INTERESTS_UNEXPECTEDRESPONSE':
            return state
        
        case 'GET_INTERESTS_OK':
            return { ...state, interests: action.interests }

        case 'GET_USER_TAGS_INVALIDJSON':
        case 'GET_USER_TAGS_INVALIDRESPONSE':
        case 'GET_USER_TAGS_NOUSER':
        case 'GET_USER_TAGS_TIMEOUT':
        case 'GET_USER_TAGS_UNAUTHORIZED':
        case 'GET_USER_TAGS_UNEXPECTEDRESPONSE':
            return state

        case 'GET_USER_TAGS_OK':
            return { ...state, userTags: action.userTags }

        case 'GET_USERS_INVALIDJSON':
        case 'GET_USERS_INVALIDRESPONSE':
        case 'GET_USERS_NOUSER':
        case 'GET_USERS_TIMEOUT':
        case 'GET_USERS_UNAUTHORIZED':
        case 'GET_USERS_UNEXPECTEDRESPONSE':
            return state

        case 'GET_USERS_OK':
            return { ...state, users: action.users, pagination: action.pagination }

        case 'SELECT_USER':
            const userForm = {
                lastName: { error: '', name: 'lastName', required: true, value: action.user.lastName },
                firstName: { error: '', name: 'firstName', required: true, value: action.user.firstName },
                email: { disabled: true, error: '', name: 'email', value: action.user.email },
                membershipId: { disabled: true, error: '', name: 'membershipId', value: action.user.membershipId },
                birthdate: { error: '', name: 'birthdate', value: createFormattedDateString( action.user.birthdate ) },
                gender: { error: '', name: 'gender', value: action.user.gender },
                phone: { error: '', name: 'phone', value: action.user.phone },
                country: { error: '', name: 'country', value: action.user.country },
                county: { error: '', name: 'county', value: action.user.county },
                city: { error: '', name: 'city', value: action.user.city },
                zipcode: { error: '', name: 'zipcode', value: action.user.zipcode },
                position: { error: '', name: 'position', value: action.user.position },
            }
            return { ...state, user: action.user, userForm: userForm, selectedUser: action.user.membershipId }

        case 'SAVE_USER_OK':
            message( `A felhasználó sikeresen mentésre került!` )
            return { ...state, userForm: {}, selectedUser: null }

        case 'UPDATE_USER_FORM':
            const updatedUserForm = state.userForm
            updatedUserForm[ action.field ] = { ...state.userForm[ action.field ], value: action.value }
            return { ...state, userForm: updatedUserForm }

        case 'CLOSE_USER_FORM':
            return { ...state, selectedUser: null }

        case 'USERS_CHANGE_CHECK':
            return { ...state, users: state.users.map( user => ( { ...user, checked: user.membershipId === action.membershipId ? !user.checked : user.checked } ) ) }

        case 'UPDATE_SEARCHTERM':
            return { ...state, searchTerm: action.searchTerm }

        case 'CHANGE_ORDER':
            return { ...state, order: { by: action.by, direction: action.direction } }


        case `FORGOT_PASSWORD_CLEAR`:
            return { ...state, forgotPasswordBusy: false, forgotPasswordEmailRequested: false }

        case `FORGOT_PASSWORD_REQUEST`:
            return { ...state, forgotPasswordBusy: true }

        case `FORGOT_PASSWORD_TIMEOUT`:
        case `FORGOT_PASSWORD_UNEXPECTEDRESPONSE`:
            return { ...state, forgotPasswordEmailRequested: false, forgotPasswordBusy: false }

        case `FORGOT_PASSWORD_OK`:
            return { ...state, forgotPasswordEmailRequested: true, forgotPasswordBusy: false }


        case `RESET_PASSWORD_CLEAR`:
            return { ...state, resetPasswordBusy: false, resetPasswordEmailRequested: false }

        case `RESET_PASSWORD_REQUEST`:
            return { ...state, resetPasswordBusy: true }

        case `RESET_PASSWORD_TIMEOUT`:
        case `RESET_PASSWORD_UNEXPECTEDRESPONSE`:
            return { ...state, resetPasswordEmailRequested: false, resetPasswordBusy: false }

        case `RESET_PASSWORD_OK`:
            return { ...state, resetPasswordEmailRequested: true, resetPasswordBusy: false }


        case `CHECK_RESET_PASSWORD_CLEAR`:
            return { ...state, resetTokenValid: null }

        case `CHECK_RESET_PASSWORD_UNEXPECTEDRESPONSE`:
        case `CHECK_RESET_PASSWORD_TIMEOUT`:
            return { ...state, resetTokenValid: false }


        case `LIST_USER_GROUPS_OK`:
            return { ...state, userGroups: action.userGroups }


        case `RESET_ADMIN_SET_FORM`:
            return {
                ...state, adminSetForm: {
                    firstName: {
                        error: ``,
                        required: true,
                        value: ``
                    },
                    lastName: {
                        error: ``,
                        required: true,
                        value: ``
                    }
                }
            }

        case `SET_ADMIN_SET_FORM`:
            const adminSetForm = { ...state.adminSetForm } as any
            adminSetForm[ action.field ].value = action.value
            return { ...state, adminSetForm: adminSetForm }


        case `CHECK_ADMIN_SET_PASSWORD_CLEAR`:
            return { ...state, setAdminPasswordTokenValid: null }

        case `CHECK_ADMIN_SET_PASSWORD_UNEXPECTEDRESPONSE`:
        case `CHECK_ADMIN_SET_PASSWORD_TIMEOUT`:
            return { ...state, setAdminPasswordTokenValid: false }

        case `CHECK_ADMIN_SET_PASSWORD_OK`:
            return { ...state, setAdminPasswordTokenValid: true }


        case `SET_ADMIN_PASSWORD_CLEAR`:
            return { ...state, setAdminPasswordBusy: false, setAdminPasswordDone: false }

        case `SET_ADMIN_PASSWORD_REQUEST`:
            return { ...state, setAdminPasswordBusy: true }

        case `SET_ADMIN_PASSWORD_TIMEOUT`:
        case `SET_ADMIN_PASSWORD_UNEXPECTEDRESPONSE`:
            return { ...state, setAdminPasswordDone: false, setAdminPasswordBusy: false }

        case `SET_ADMIN_PASSWORD_OK`:
            return { ...state, setAdminPasswordDone: true, setAdminPasswordBusy: false }

        case `USER_ADD_TAG`:
            return { ...state, user: { ... state.user!, tags: [ ...state.user!.tags, action.tag ] } }

        case `USER_REMOVE_TAG`:
            return { ...state, user: { ... state.user!, tags: state.user!.tags.filter( t => t.id !== action.tag.id ) } }


        case `DELETE_USER_OK`:
            message( `A felhasználó sikeresen törlésre került!` )
            return state

        case `CREATE_ADMIN_OK`:
            message( `A meghívás sikeresen elküldésre került!` )
            return state


        case `LIST_ADMIN_ACTIVITIES_OK`:
            return { ...state, adminActivities: action.adminActivities }

        default:
            return state

    }
}
