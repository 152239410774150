import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNews, getNewsItemStats, modifyNewsStatus } from '../../../action/news'
import { Checkbox, Modal, Table, Tag } from '../../../component'
import { News } from '../../../entity'
import { NewsStatus } from '../../../enum/news-status.enum'
import { State } from '../../../state'
import { NewsModal } from './../news-modal'
import './news-table.scss'
import { CREATE_NEWS_ACTIONS, NEWS_ACTIONS } from '../../../action/news/news.constants'
import { NewsTableMenuButton } from './new-table-menu-button'
import { NewsTableStatusDropdown } from './new-table-status-dropdown'
import { createFormattedDateTimeString } from '../../../helper'
import { NewsVisibility } from '../../../enum/news-visibility.enum'
import { useNavigate } from 'react-router-dom'

interface NewsTableProps {
    newsArray: News[]
}

export const NewsTable = ( props: NewsTableProps ) => {
    
     
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deleted = useSelector( ( state: State ) => state.news.deleted )

    const [ previewNews, setPreviewNews ] = useState<News | null >( null )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const { order } = useSelector( ( state: State ) => state.news )
    const { page, from, to, count } = useSelector( ( state: State ) => state.news.pagination )

    const [ checked, setChecked ] = useState( [] as number[] )
    const [ pageChecked, setPageChecked ] = useState( false )


    const onPageChange = ( page: number ) => {
        dispatch( { type: NEWS_ACTIONS.GET_NEWS_CHANGE_PAGE, page: page } )
        getAllNews()
    }

    const onOrderChange = ( column: string ) => {
        dispatch( { type: NEWS_ACTIONS.GET_NEWS_ORDER } )
        getAllNews() 
    }

    const visibilityOptions = [
        { id: NewsVisibility.Open, value: 'Nyílt', icon: "lock_open" },
        { id: NewsVisibility.Internal, value: 'Belső', icon: "my_location" },
        { id: NewsVisibility.Closed, value: 'Zárt', icon: "lock" },
    ]

    const check = ( event: ChangeEvent<HTMLInputElement>, id: number ) => {

        let c = [ ...checked ]
        const value = checked.find( i => id === i ) !== undefined

        if ( value ) {

            setChecked( c.filter( i => i !== id ) )
            c = c.filter( i => i !== id )

        } else {

            setChecked( [ ...c, id ] )
            c.push( id )

        }

        setPageChecked( props.newsArray.filter( q => c.find( id => q.id === id ) !== undefined ).length === props.newsArray.length )

    }

    const checkPage = ( event: ChangeEvent<HTMLInputElement> ) => {

        let c = [ ...checked ]
        setPageChecked( event.target.checked )

        props.newsArray.forEach( news => {

            if ( event.target.checked ) {

                if ( c.find( id => news.id === id ) === undefined ) {

                    c.push( news.id )

                }

            } else {

                c = c.filter( id => news.id !== id )
            }

         } )

        setChecked( c )

    }

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onDeleteAllClick = ( ) => {
        setDeleteFunc( () => {
            return async () => {
                if(checked.length != 0){
                    modifyNewsStatus(checked, NewsStatus.Deleted)
                    setPageChecked(false)
                    setChecked([])
                }
            }
        } )
        setShowConfirmModal( true )
    }

    const onDraftAllClick = ( ) => {
        if(checked.length != 0){
            modifyNewsStatus(checked, NewsStatus.Draft)  

            setPageChecked(false)
            setChecked([])
        }        
    }

    const columns = [
        {
            canOrder: false,
            name: `check`,
            render: ( data: any ) => <Checkbox checked={ checked.find( id => data.id === id ) !== undefined } id="null" onChange={ e => check( e, data.id ) } />,
            headerRender: () => <Checkbox checked={ pageChecked } id="null" onChange={ checkPage } />,
            width: 50
        },
        {
            align: `center`,
            caption: `ID`,
            key: `id`,
            name: `id`,
            width: 110
        },
        {
            caption: `Kép`,
            key: `image`,
            name: `image`,
            render: ( data: any ) =>  <span className="material-symbols-outlined image">image</span>,
            width: 60
        },
        {
            caption: ``,
            name: `title`,
            render: ( row: any ) => <span className="news-title">{ row.statementBy != null ? row.statementBy +':' : "" } { row.title }</span> ,
            headerRender: () => <>
                                    <div className="header-action-container" >
                                        <button className="header-action-item" style={(checked.length == 0 ||  deleted) ? {display: "none"} : {display: "inline"}}  onClick={ onDeleteAllClick }>
                                            <img alt="Törlés gomb" src="/image/trash.svg" />
                                        </button>

                                        <button className="header-action-item" style={checked.length == 0 ? {display: "none"} : {display: "inline"}} onClick={ onDraftAllClick }>
                                            <img alt="Vázlat gomb" src="/image/eye_slashed.svg" />
                                        </button>
                                    </div>
                                </>,
            width: 300
        },
        {
            caption: ``,
            key: `name`,
            name: `name`,
            render: ( data: any ) =>  data.category != null ?  <div className="tag-container" > <Tag text={ data.category.name } color={ data.category.color } />   </div> : "" ,
            width: 180
        },
        {
            align: `center`,
            caption: `Hír láthatósága`,
            key: `type`,
            name: `type`,
            width: 180,
            render: ( data: any ) => {
                return ( <>
                        <span className="label-text news-visibility">{ visibilityOptions[data.visibility].value }</span>
                        <span className="material-symbols-outlined" style={{fontSize:"14px", marginLeft:'8px'}}>{ visibilityOptions[data.visibility].icon }</span>
                        </>
                )
            }
        },
        {
            align: `center`,
            caption: `Hír időpontja`,
            render: ( data: any ) => createFormattedDateTimeString( data.visibleFrom ),
            name: `visibleFrom`,
            width: 220
        },
        {
            align: `center`,
            caption: `Státusz`,
            render: ( data: any ) => <NewsTableStatusDropdown newsElement={ data } />,
            width: 160
        },
        {
            align: `center`,
            caption: ``,
            name: `actions`,
            render: ( data: any ) => <NewsTableMenuButton newsElement={ data } onPreviewClick={ onPreviewButtonClick } />,
            width: 80
        }
    ]

    const onRowClick = ( row: News ) => {
        getNewsItemStats( row.id )

        dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_MODIFY_NEWS, news: row } )
        navigate("/hirek/uj")
    }

    const onPreviewButtonClick = ( news: News ) => {
        setPreviewNews(news)
    }

    const onPreviewCloseButtonClose = ( event: MouseEvent ) => {
        setPreviewNews(null)
    }

    return (
        <>
            <Table className="news-table"
                columns={ columns }
                dataset={ props.newsArray }
                order={ { by: `visibleFrom`, direction: order } }
                onOrderChange={ onOrderChange }
                onRowClick={ onRowClick }
                pagination={ { page: page, from: from, to: to, count: count, onPageChange: onPageChange } } 
            />

            { previewNews !== null ? <NewsModal newsElement={ previewNews } onCloseClick={ onPreviewCloseButtonClose } /> : null }

            { showConfirmModal ? (

                <Modal className="news-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a hírt?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>
    )

}
