import { ChangeEvent, MouseEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { SearchButton, SearchInput } from '../../component/search'
import { State } from '../../state'
import './questionnaire-list.page.scss'
import { QuestionnaireTable } from './component/questionnaire-table'
import { CREATE_QUESTIONNAIRE_ACTIONS, GET_QUESTIONNAIRES_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { getQuestionnaires } from '../../action/questionnaires'

interface QuestionnaireListProps { }

export const QuestionnaireList = ( props: QuestionnaireListProps ) => {

    const dispatch = useDispatch()

    const questionnaires = useSelector( ( state: State ) => state.questionnaires.questionnaires )
    const deleted = useSelector( ( state: State ) => state.questionnaires.deleted )

    useEffect( () => { 
        dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CLEAR } )
    }, [] )

    const onSearchChange = async ( event: ChangeEvent<HTMLInputElement> ) => {
        event.preventDefault()

        dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_SEARCH , search: event.target.value} )

        if(event.target.value.length == 0){
            await getQuestionnaires() 
        }
    }

    const onSearchButtonClick = async ( event: MouseEvent | KeyboardEvent ) => {
        event.preventDefault()

        await getQuestionnaires() 
    }

    const onDeltedButtonClick = async ( event: MouseEvent ) => {
        event.preventDefault()

        dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_DELETED } )

        await getQuestionnaires() 
    }

    return (
        <main className="page page-questionnaires page-questionnaire-list">
            
            <div className="main-div">
                
                <div className="navigation-title">
                    <NavLink to="/kerdoivek" >
                        <h1 className="title-text-h1">Kérdőívek áttekintése {'>'}</h1>
                    </NavLink>
                    <h1 className="title-text-h1">{deleted == 0 ? "Kérdőívek lista" : "Kérdőívek lista (Archív)"}</h1>
                </div>

                <div className="toolbar">
                    <SearchInput onSearch={ onSearchButtonClick } onChange={ onSearchChange } placeholder="Keresés a kérdőívek között" />
                    <SearchButton onClick={ onSearchButtonClick } />
                    <button className="button button-white archive-button" onClick={ onDeltedButtonClick } > {deleted == 0 ? "Archív kérdőívek" : "Kérdőívek"}</button>
                    <NavLink className="new-a" to="/kerdoivek/uj">
                        <button className="button new-button" >+ Új kérdőív</button>
                    </NavLink>
                </div>

                {/* <div className="info">
                    <h2 className="title-text-h2">Kiemelt statisztikai adatok</h2>

                </div> */}

                <QuestionnaireTable questionnaireArray={ questionnaires || [] }/> 

            </div>

        </main>
    )

}