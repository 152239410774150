import { exportUsers, getUsers } from '../../../../action'
import { SearchButton, SearchInput } from "../../../../component/search";
import { store } from "../../../../store";
import { ChangeEvent } from "react";

export const Toolbar = () => {
  const updateSearch = (event: ChangeEvent<HTMLInputElement>) => {
    store.dispatch({
      type: "UPDATE_SEARCHTERM",
      searchTerm: event.target.value,
    });
    if (event.target.value.length === 0) {
      getUsers([1]);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const button = event.currentTarget.querySelector("button");
      if (button) {
        button.click();
      }
    }
  };

  const startExport = async () => {

      const csv = new File( [ await exportUsers() as string ], `users.csv`, { type: `text/csv` } )

      const a = document.createElement( `a` )
      a.download = `users.csv`
      a.href = URL.createObjectURL( csv )
      a.click()

  }

  return (
    <div className="toolbar">
      <div className="search" onKeyDown={handleKeyPress}>
        <SearchInput
          placeholder="Keresés a tagok között"
          onChange={updateSearch}
          onSearch={ () => getUsers() }
        />
        <SearchButton onClick={() => getUsers()} />
        <button className="button button--ghost" style={{ marginLeft: 20 }} onClick={ startExport }>Exportálás</button>

      </div>

    </div>
  );
};
