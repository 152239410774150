import { MouseEventHandler } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPoliticianStats } from '../../../action/politician/get-politician-stats.action'
import { Politician, PoliticianQuestion, PoliticianQuestionAnswer } from '../../../entity'
import './politician-card.scss'

interface PoliticianCardProps {
    politicianElement: Politician | null 
    onDeleteClick?: MouseEventHandler | null
    uncompletedQuestionCountvisible?: boolean
}

export const PoliticianCard = ( props: PoliticianCardProps ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onPoliticianClick = (  ) => {
        getPoliticianStats( props.politicianElement ? props.politicianElement. id : 0 )

        dispatch( { type: 'CREATE_POLITICIAN_MODIFY_POLITICIAN', politician: props.politicianElement } )
        navigate("/politikusok/uj")
    }

    function getUncompletedQuestionCount  (  )   {
        var count = 0
        if ( props.politicianElement !== null  &&  props.politicianElement !== undefined  &&  props.politicianElement.questions !== null ) {
            props.politicianElement.questions!.map(( question: PoliticianQuestion  ) => {
                if ( question.answers !== null &&  question.answers !== undefined && question.answers.length != 0 ) {
                    question.answers.map(( answer: PoliticianQuestionAnswer  ) => {
                        if ( answer !== null  &&  answer !== undefined &&  answer.answer.length != 0) {

                        } else {
                            count += 1
                        }
                    })
                } else {
                    count += 1
                }
            })
        }

        return count
    }

    return (
        <div className={ `politician-card` } onClick={ ( props.uncompletedQuestionCountvisible != null )? onPoliticianClick : undefined }>
  
            { (props.uncompletedQuestionCountvisible != null && getUncompletedQuestionCount() != 0 )? <span className="question-count"  >{ getUncompletedQuestionCount() }</span> : ""}

            <div className="politician-image-div" >
                <img className= { `${props.politicianElement == null ? "" : "pol-image"}` } 
                    src= { `${ props.politicianElement == null ? null : `${ props.politicianElement.imageFile == null ? "/image/dk-logo.svg" : props.politicianElement.imageFile.url }` }` } alt= "Politikus képe" />
            </div> 

            { props.onDeleteClick == null ? "" : <button type='button' id={ props.politicianElement == null ? "" : `${props.politicianElement.id}`} className="close-button" onClick={ props.onDeleteClick }> <img alt="Törlés gomb" src="/image/close.svg" /></button> }     

            { props.politicianElement == null ? "" : (
                <div className="text-container">
                    <h3 className="title-text-h3 title-text"> { props.politicianElement.lastName  } {  props.politicianElement.firstName }</h3>
                    <h3 className="title-text-h3 id-text"> { props.politicianElement.id.toString().padStart( 4, `0` ) }</h3>
                    <h3 className="title-text-h3 description-text"> { props.politicianElement.innerPosition }</h3>
                </div>
            )}
            
        </div>
    )

}
