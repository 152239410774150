import { CoursesState, LoginState } from '../state'
import { v4 as uuid } from 'uuid'
import { message } from '../helper'

const initialState: CoursesState = {

    archive: false,
    highlighted: null,
    course: null,
    courses: [],
    courseTopics: [],
    lesson: null,
    keyword: ``,
    pagination: {
        page: 1,
        from: 1,
        to: 1,
        count: 1
    },
    order: {
        by: `id`,
        direction: `ASC`
    },
    stats: {
        newest: null,
        mostAnswer: null,
        leastAnswer: null,
        courses: 0,
        answers: 0
    }

}

export const coursesReducer = ( state: CoursesState = initialState, action: any ): CoursesState => {

    switch ( action.type ) {

        case `TOGGLE_COURSE_ARCHIVE`:
            return { ...state, archive: !state.archive }

        case `SET_COURSE_KEYWORD`:
            return { ...state, keyword: action.keyword }

        case `COURSE_ORDER_CHANGE`:
            return { ...state, order: action }


        case `GET_COURSE_STATS_OK`:
            return { ...state, stats: action.stats }


        case `LIST_COURSES_OK`:
            return { ...state, courses: action.courses, pagination: action.pagination, highlighted: action.highlighted }


        case `GET_COURSE_OK`:
            if ( action.course === null ) {
                action.course = {
                    award: {
                        bronzeImage: null,
                        bronzeExperience: 0,
                        silverImage: null,
                        silverExperience: 0,
                        goldImage: null,
                        goldExperience: 0
                    },
                    isDraft: true,
                    description: ``,
                    lead: ``,
                    courseTopic: state.courseTopics.length === 0 ? null : state.courseTopics[ 0 ],
                    experience: 25,
                    image: null,
                    politician: null,
                    restartPrice: 2500,
                    startDate: new Date().getTime() / 1000,
                    endDate: null,
                    tags: [],
                    lessons: []
                }
            }
            return { ...state, course: action.course }

        case `COURSE_ADD_TAG`:
            if ( state.course!.tags.find( t => t.id === action.tag.id ) === undefined ) state.course!.tags.push( action.tag )
            return { ...state, course: { ...state.course!, tags: state.course!.tags } }

        case `COURSE_REMOVE_TAG`:
            state.course!.tags = state.course!.tags.filter( t => t.id !== action.tag.id )
            return { ...state, course: { ...state.course!, tags: state.course!.tags } }

        case `UPDATE_COURSE`:
            const course: any = { ...state.course! }
            course[ action.field ] = action.value
            return { ...state, course: course }

        case `UPDATE_LESSON`:
            const lesson: any = { ...state.lesson! }
            lesson[ action.field ] = action.value
            return { ...state, lesson: lesson }

        case `UPDATE_LESSON_QUIZ`:
            const lessonQuiz: any = { ...state.lesson!.quiz }
            lessonQuiz[ action.field ] = action.value
            return { ...state, lesson: { ...state.lesson!, quiz: lessonQuiz } }

        case `UPDATE_COURSE_AWARD`:
            const award: any = { ...state.course!.award }
            award[ action.field ] = action.value
            return { ...state, course: { ...state.course!, award: award } }


        case `LIST_COURSE_TOPICS_OK`:
            return { ...state, courseTopics: action.courseTopics }


        case `CREATE_LESSON`:
            let max = 0
            for ( const l of state.course!.lessons ) { max = ( l.n > max ) ? l.n : max }
            const newLesson: any = {
                id: null,
                n: max + 1,
                name: ``,
                length: 1,
                oneStarExperience: 1,
                twoStarExperience: 1,
                threeStarExperience: 1,
                quiz: {
                    questions: []
                },
                pages: []
            }
            return { ...state, lesson: newLesson }


        case `CREATE_LESSON_PAGE`:
            const newLessonPage: any = {
                id: uuid(),
                description: ``,
                isNew: true,
                type: null,
                image: null
            }
            return { ...state, lesson: { ...state.lesson!, pages: [ ...state.lesson!.pages, newLessonPage ] } }

        case `CREATE_QUIZ_QUESTION`:
            const newQuizQuestion: any = {
                id: uuid(),
                type: 0,
                isNew: true,
                answers: [
                    { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: true }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }, { id: uuid(), isNew: true, tags: [], answer: ``, image: null, isSolution: false }
                ]
            }
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions, newQuizQuestion ] } } }

        case `UPDATE_LESSON_PAGE`:
            const index = state.lesson!.pages.findIndex( p => p.id === action.id );
            ( state.lesson!.pages[ index ] as any )[ action.field ] = action.value
            return { ...state, lesson: { ...state.lesson!, pages: [ ...state.lesson!.pages ] } }

        case `DELETE_LESSON_PAGE`:
            return { ...state, lesson: { ...state.lesson!, pages: [ ...state.lesson!.pages.filter( p => p.id !== action.id ) ] } }


        case `UPDATE_QUIZ_QUESTION`:
            const quizQuestionIndex = state.lesson!.quiz.questions.findIndex( q => q.id === action.id );
            ( state.lesson!.quiz.questions[ quizQuestionIndex ] as any )[ action.field ] = action.value
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }


        case `QUIZ_ANSWER_ADD_TAG`:
            const quizQuestionIndex3 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex2 = state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers.findIndex( a => a.id === action.id );
            if ( ( state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.find( ( t: any ) => t.id === action.tag.id ) === undefined ) {
                ( state.lesson!.quiz.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.push( action.tag )
            }

            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }

        case `QUIZ_ANSWER_REMOVE_TAG`:
            const quizQuestionIndex4 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex3 = state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers.findIndex( a => a.id === action.id );
            state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags = state.lesson!.quiz.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags.filter( t => t.id !== action.tag.id )

            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }

        case `UPDATE_QUIZ_ANSWER`:
            const quizQuestionIndex2 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex = state.lesson!.quiz.questions[ quizQuestionIndex2 ].answers.findIndex( a => a.id === action.id );
            ( state.lesson!.quiz.questions[ quizQuestionIndex2 ].answers[ quizAnswerIndex ] as any )[ action.field ] = action.value
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }


        case `UPDATE_QUIZ_SOLUTION`:
            const quizQuestionIndex5 = state.lesson!.quiz.questions.findIndex( q => q.id === action.questionId );
            state.lesson!.quiz.questions[ quizQuestionIndex5 ].answers.forEach( q => q.isSolution = false )
            const quizAnswerIndex4 = state.lesson!.quiz.questions[ quizQuestionIndex5 ].answers.findIndex( a => a.id === action.id );
            state.lesson!.quiz.questions[ quizQuestionIndex5 ].answers[ quizAnswerIndex4 ].isSolution = true

            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions ] } } }


        case `DELETE_QUIZ_QUESTION`:
            return { ...state, lesson: { ...state.lesson!, quiz: { ...state.lesson!.quiz, questions: [ ...state.lesson!.quiz.questions.filter( q => q.id !== action.id ) ] } } }


        case `SELECT_LESSON`:
            const selectedLesson = state.course!.lessons!.find( lesson => lesson.id === action.id )
            if ( selectedLesson === undefined ) return state
            return { ...state, lesson: selectedLesson }


        case `SAVE_COURSE_OK`:
            message( `A képzés sikeresen mentésre került!` )
            return state

        case `SAVE_LESSON_OK`:
            message( `A lecke sikeresen mentésre került!` )
            return state

        case `DELETE_COURSE_OK`:
            message( `A képzés sikeresen törlésre került!` )
            return state

        case `DELETE_COURSE_TOPIC_ERROR`:
            message( `A téma törlése sikertelen volt, mert a téma tartalmaz képzéseket!`, `Hiba` )
            return state

        case `SAVE_COURSE_TOPIC_OK`:
            message( `A téma sikeresen mentésre került!` )
            return state

        case `DELETE_COURSE_TOPIC_OK`:
            message( `A téma sikeresen törlésre került!` )
            return state

        default:
            return state

    }

}
