import { CREATE_NEWS_ACTIONS, GET_NEWS_ITEM_STATS_ACTIONS } from '../../action/news/news.constants'
import { NewsStatus } from '../../enum/news-status.enum'
import { NewsVisibility } from '../../enum/news-visibility.enum'
import { getLocalISOTime, createLocalISOTime, getLocalISOTimePlus, message } from '../../helper'
import { CreateNewsState } from '../../state/news'

const initialCreateNewsState: CreateNewsState = {
    form: {
        id: null,
        visibleFrom: getLocalISOTime(),
        tags: [],
       // visibleTo: getLocalISOTimePlus( 1 ),
        visibleTo: null,
        status: NewsStatus.Draft,
        imageVisibilityInList: 0,
        category: 1,
        visibility: NewsVisibility.Open,
        highlighted: 0,
        sendPush: 1,
        mediaLibraryFile: null,
        imageUrl: "",
        statementBy: {
            error: '',
            value: '',
            required: false
        },
        title: {
            error: '',
            value: '',
            required: true
        },
        lead: {
            error: '',
            value: '',
            required: true
        },
        description: {
            error: '',
            value: '',
            required: true
        },
        question: {
            error: '',
            value: '',
            required: false,
            correctAnswerId: 1,

            answer1: {
                error: '',
                value: '',
                required: false
            },
            answer2: {
                error: '',
                value: '',
                required: false
            },
            answer3: {
                error: '',
                value: '',
                required: false
            },
            answer4: {
                error: '',
                value: '',
                required: false
            }
        },
        relatedPoliticians: [],
        experience: {
            error: '',
            value: '',
            required: true
        },
        quizExperience: {
            error: '',
            value: '',
            required: true
        }
    },
    saveDate: null,
    news: null,

    stats: {
        openCount: 0,
        readCount: 0,
        readTime: 0,
        sendQuestionCount: 0
    }
}


export const createNewsReducer = ( state: CreateNewsState = initialCreateNewsState, action: any ): CreateNewsState => {
    switch ( action.type ) {
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_MEDIA_LIBRARY_FILE:
            return { ...state, form: { ...state.form, mediaLibraryFile: action.mediaLibraryFile, imageUrl: action.mediaLibraryFile ? action.mediaLibraryFile.url : "" } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBLE_FROM:
            return { ...state, form: { ...state.form, visibleFrom: action.visibleFrom } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBLE_TO:
            return { ...state, form: { ...state.form, visibleTo: action.visibleTo } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_STATUS:
            return { ...state, form: { ...state.form, status: action.status } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_IMAGE_VISIBILITY_IN_LIST:
            return { ...state, form: { ...state.form, imageVisibilityInList: action.imageVisibilityInList } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_CATEGORY:
            return { ...state, form: { ...state.form, category: action.category } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBILITY:
            return { ...state, form: { ...state.form, visibility: action.visibility } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_HIGHLIGHTED:
            return { ...state, form: { ...state.form, highlighted: state.form.highlighted == 0 ? 1 : 0 } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_PUSH:
            return { ...state, form: { ...state.form, sendPush: action.sendPush  } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_STATEMENT_BY:
            return { ...state, form: { ...state.form, statementBy: { ...state.form.statementBy, value: action.statementBy } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_TITLE:
            return { ...state, form: { ...state.form, title: { ...state.form.title, value: action.title } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_LEAD:
            return { ...state, form: { ...state.form, lead: { ...state.form.lead, value: action.lead } } }
        case `CREATE_NEWS_CHANGE_EXPERIENCE`:
            if ( isNaN( action.experience ) ) { return state }
            if ( action.experience === `` ) { action.experience = `0` }
            return { ...state, form: { ...state.form, experience: { ...state.form.experience, value: action.experience } } }
        case `CREATE_NEWS_CHANGE_QUIZ_EXPERIENCE`:
            if ( isNaN( action.quizExperience ) ) { return state }
            if ( action.quizExperience === `` ) { action.quizExperience = `0` }
            return { ...state, form: { ...state.form, quizExperience: { ...state.form.quizExperience, value: action.quizExperience } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_DESCRIPTION:
            return { ...state, form: { ...state.form, description: { ...state.form.description, value: action.description } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_QUESTION:
            return {
                ...state, form: {
                    ...state.form,
                    question: {
                        ...state.form.question, value: action.question,
                        answer1: { ...state.form.question.answer1, required: action.question.length != 0 },
                        answer2: { ...state.form.question.answer2, required: action.question.length != 0 },
                        answer3: { ...state.form.question.answer3, required: action.question.length != 0 },
                        answer4: { ...state.form.question.answer4, required: action.question.length != 0 }
                    }

                }
            }
        case `NEWS_ADD_TAG`:
            return { ...state, form: { ...state.form, tags: [ ...state.form.tags, action.tag ] } }
        case `NEWS_REMOVE_TAG`:
            return { ...state, form: { ...state.form, tags: state.form.tags.filter( t => t.id !== action.tag.id ) } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER1:
            return { ...state, form: { ...state.form, question: { ...state.form.question, answer1: { ...state.form.question.answer1, value: action.answer } } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER2:
            return { ...state, form: { ...state.form, question: { ...state.form.question, answer2: { ...state.form.question.answer2, value: action.answer } } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER3:
            return { ...state, form: { ...state.form, question: { ...state.form.question, answer3: { ...state.form.question.answer3, value: action.answer } } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER4:
            return { ...state, form: { ...state.form, question: { ...state.form.question, answer4: { ...state.form.question.answer4, value: action.answer } } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_CORRECT_ANSWER_ID:
            return { ...state, form: { ...state.form, question: { ...state.form.question, correctAnswerId: action.id } } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_ADD_POLITICIAN:
            if ( state.form.relatedPoliticians.some( item => action.selectedPolitician.id === item.id ) ) {
                return state
            }
            return { ...state, form: { ...state.form, relatedPoliticians: [ ...state.form.relatedPoliticians, action.selectedPolitician ] } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_REMOVE_POLITICIAN:
            return { ...state, form: { ...state.form, relatedPoliticians: state.form.relatedPoliticians.filter( item => action.selectedPoliticianId !== item.id ) } }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_STARTED:
            return { ...state, saveDate: new Date() }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_INVALID_JSON:
            return { ...state, saveDate: null, news: null }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_INVALID_RESPONSE:
            return { ...state, saveDate: null, news: null }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_OK:
            message( `A hír sikeresen mentésre került!` )
            return { ...state, saveDate: null, news: action.news }
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_CLEAR:
            return initialCreateNewsState
        case CREATE_NEWS_ACTIONS.CREATE_NEWS_MODIFY_NEWS:

            let correctAnswerId = 1

            if ( action.news.question != null ) {
                if ( action.news.question.answers[ 0 ] != null && action.news.question.answers[ 0 ].isSolution ) {
                    correctAnswerId = 1
                }
                if ( action.news.question.answers[ 1 ] != null && action.news.question.answers[ 1 ].isSolution ) {
                    correctAnswerId = 2
                }
                if ( action.news.question.answers[ 2 ] != null && action.news.question.answers[ 2 ].isSolution ) {
                    correctAnswerId = 3
                }
                if ( action.news.question.answers[ 3 ] != null && action.news.question.answers[ 3 ].isSolution ) {
                    correctAnswerId = 4
                }
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    id: action.news.id,
                    visibleTo: action.news.visibleTo != null ? createLocalISOTime( action.news.visibleTo ) : null,
                    visibleFrom: createLocalISOTime( action.news.visibleFrom ),
                    status: action.news.status,
                    imageVisibilityInList: action.news.imageVisibilityInList == true ? 0 : 1,
                    category: action.news.category.id,
                    visibility: action.news.visibility,
                    highlighted: action.news.highlighted,
                    sendPush: action.news.sendPush == true ? 0 : 1,
                    tags: action.news.tags,
                    experience: {
                        error: '',
                        value: action.news.experience.toString(),
                        required: false
                    },
                    quizExperience: {
                        error: '',
                        value: action.news.quizExperience.toString(),
                        required: false
                    },
                    statementBy: {
                        error: '',
                        value: action.news.statementBy,
                        required: false
                    },
                    title: {
                        error: '',
                        value: action.news.title,
                        required: true
                    },
                    lead: {
                        error: '',
                        value: action.news.lead,
                        required: true
                    },
                    description: {
                        error: '',
                        value: action.news.description,
                        required: true
                    },
                    imageUrl: action.news.imageFile != null ? action.news.imageFile.url : '',
                    mediaLibraryFile: action.news.imageFile != null ? {
                        id: action.news.imageFile.id ,
                        extension: "jpg",
                        name: "",
                        width: 0,
                        height: 0,
                        size: "",
                        thumbnail: "",
                        type: "file",
                        url: "",
                        created: 0
                    } : null,
                    question: {
                        error: '',
                        value: action.news.question != null ? action.news.question.question : '',
                        required: false,

                        correctAnswerId: correctAnswerId,

                        answer1: {
                            error: '',
                            value: action.news.question != null ? action.news.question.answers[ 0 ] != null ? action.news.question.answers[ 0 ].answer : '' : '',
                            required: false
                        },
                        answer2: {
                            error: '',
                            value: action.news.question != null ? action.news.question.answers[ 1 ] != null ? action.news.question.answers[ 1 ].answer : '' : '',
                            required: false
                        },
                        answer3: {
                            error: '',
                            value: action.news.question != null ? action.news.question.answers[ 2 ] != null ? action.news.question.answers[ 2 ].answer : '' : '',
                            required: false
                        },
                        answer4: {
                            error: '',
                            value: action.news.question != null ? action.news.question.answers[ 3 ] != null ? action.news.question.answers[ 3 ].answer : '' : '',
                            required: false
                        }
                    },
                    relatedPoliticians: action.news.relatedPoliticians
                }
            }
        case GET_NEWS_ITEM_STATS_ACTIONS.GET_NEWS_ITEM_STATS_OK:
            return { ...state, stats: { openCount: action.openCount, readCount: action.readCount, readTime: action.readTime, sendQuestionCount: action.sendQuestionCount} }
        default:
            return state
    }
}

