import { User } from '../../entity'
import { store } from '../../store'
import { getUser } from './get-user.action'
import { getMe } from './get-me.action'

export const modifyUser = async ( user: Partial<User> ) => {

    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/users/me`, {
            body: JSON.stringify( user ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'PATCH'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'MODIFY_USER_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'MODIFY_USER_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'MODIFY_USER_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'MODIFY_USER_OK' } )
    await getMe()

}
