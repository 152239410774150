import { Modal } from "../../modal";
import { Politician, PoliticianPhrase } from "../../../entity";
import "./politician-modal.scss";
import { ChangeEvent, MouseEvent, MouseEventHandler, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../state";
import { PoliticianElement } from "./politician-element";
import { SearchInput } from "../../search";
import { PoliticianCard } from "../politician-card";
import { PoliticianPhraseElement } from "./politician-phrase-element";

interface PoliticianModalProps {
  onCloseClick?: MouseEventHandler;
  onModalClose?: () => void;
  onPoliticianSelected?: ( politician: Politician ) => void
}

export const PoliticianModal = (props: PoliticianModalProps) => {
  const dispatch = useDispatch();

  const { politicians, selectedPolitician } = useSelector(
    (state: State) => state.politicians
  );
  const [foundPoliticians, setFoundPoliticians] = useState(politicians);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;

    if (keyword !== "") {
      const results = politicians?.filter((politician) => {
        const name = politician.lastName + politician.firstName;
        return name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundPoliticians(results);
    } else {
      setFoundPoliticians(politicians);
    }
  };

  const onAddPoliticianClick = (event: MouseEvent) => {
    event.preventDefault();
    dispatch({
      type: "CREATE_NEWS_ADD_POLITICIAN",
      selectedPolitician: selectedPolitician,
    });
    dispatch({
      type: "EVENTS_ADD_POLITICIAN",
      selectedPolitician: selectedPolitician,
    });
      dispatch({
          type: "UPDATE_COURSE",
          field: `politician`,
          value: selectedPolitician
      });

    if ( props.onPoliticianSelected ) { props.onPoliticianSelected( selectedPolitician! ) }

    props.onCloseClick!(event);
  };

  const politiciansElements: JSX.Element[] = [];
  if (foundPoliticians !== null && foundPoliticians !== undefined) {
    foundPoliticians.map((politicianElement: Politician) => {
      politiciansElements.push(
        <PoliticianElement politicianElement={politicianElement} />
      );
    });
  }

  const phrasesElements: JSX.Element[] = [];
  if (
    selectedPolitician !== null &&
    selectedPolitician !== undefined &&
    selectedPolitician.phrases !== null &&
    selectedPolitician.phrases !== undefined
  ) {
    selectedPolitician.phrases.map(
      (politicianPhraseElement: PoliticianPhrase) => {
        phrasesElements.push(
          <PoliticianPhraseElement
            politicianPhraseElement={politicianPhraseElement}
          />
        );
      }
    );
  }

  return (
    <Modal onModalClose={() => props.onModalClose && props.onModalClose()}>
      <div className="politicians-modal">
        <div className="politicians-modal__header">
          <div className="politicians-modal__title"> Politikus hozzáadása </div>
          <button className="close-button" onClick={props.onCloseClick}>
            <img alt="bezárás gomb" src="/image/close.svg" />
          </button>
        </div>

        <div className="container">
          <div className="politicians-table-container">
            <div className="politicians-modal__header">
              <div>
                <div className="lineBreak">
                  <h3 className="title-text-h2 text-right-margin ">
                    Politikusaink
                  </h3>
                </div>
                <div className="lineBreak">
                  <SearchInput
                    onChange={onSearchChange}
                    placeholder="Kezdj el gépelni!"
                  />
                </div>
              </div>
            </div>

            <ul id={"news_category_table"} className="politicians-table-list">
              {politiciansElements.length != 0 ? (
                politiciansElements
              ) : (
                <span className="label-text empty-text">
                  Nincs megjeleníthető politikus.
                </span>
              )}
            </ul>
          </div>

          <div className="separator" />

          <div className="detail_container">
            <div className="container remove-margin">
              <PoliticianCard politicianElement={selectedPolitician} />

              {selectedPolitician == null ? (
                ""
              ) : (
                <div className="detail-text-container">
                  <h3 className="title-text-h2 text-bottom-margin">
                    {selectedPolitician.lastName} {selectedPolitician.firstName}
                  </h3>
                  <h3 className="title-text-h3">
                    {selectedPolitician.position}
                  </h3>
                  <h3 className="label-text">
                    {selectedPolitician.description}
                  </h3>
                </div>
              )}
            </div>

            <div className="separator-horizontal" />

            {selectedPolitician == null ? (
              ""
            ) : (
              <h3 className="title-text-h3">
                {selectedPolitician.lastName} {selectedPolitician.firstName}
                mondatai
              </h3>
            )}

            <ul id={"news_category_table"} className="politician-phreses-list">
              {phrasesElements.length != 0 ? (
                phrasesElements
              ) : (
                <span className="label-text empty-text">
                  Nincs megjeleníthető mondat.
                </span>
              )}
            </ul>
          </div>
        </div>

        <footer className="news-modal__footer">
          <button className="button" onClick={onAddPoliticianClick}>
            Politikus hozzáadása
          </button>
        </footer>
      </div>
    </Modal>
  );
};
