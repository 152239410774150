import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteQuestionnaire } from '../../../../action/questionnaires'
import { CREATE_QUESTIONNAIRE_ACTIONS } from '../../../../action/questionnaires/questionnaires.constants'
import { MenuButton, Modal } from '../../../../component'
import { Questionnaire } from '../../../../entity'
import { useState } from 'react'

interface QuestionnaireTableMenuButtonProps {
    questionnaire: Questionnaire
}

export const QuestionnaireTableMenuButton = ( props: QuestionnaireTableMenuButtonProps ) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onMenuItemChange = async ( itemId: any ) => {
        switch(itemId) {
            case 1:
                dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_MODIFY_QUESTIONNAIRE, questionnaire: props.questionnaire } )
                navigate("/kerdoivek/uj")
                break
            case 2:
                //props.onPreviewClick(props.questionnaireElement)
                break
            default:
                setDeleteFunc( () => {
                    return async () => {
                        deleteQuestionnaire(props.questionnaire.id)
                    }
                } )
                setShowConfirmModal( true )
                break
        }
    }

    return (
        <>

            <MenuButton
                hasBackground={ true }
                items={ [
                    { id: 1, value: 'Szerkesztés', icon: 'edit.svg' },
                    { id: 3, value: 'Törlés', icon: 'trash.svg' }
                ] }
                onChange={ onMenuItemChange }
            />

            { showConfirmModal ? (

                <Modal className="questionnaire-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a kérdőívet?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}