import { GET_QUESTIONNAIRES_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { QuestionnairesState } from '../../state/questionnaires'

const initialQuestionnairesState: QuestionnairesState  = {
    questionnaires: undefined,
 
    search: "",
    order: 'DESC',
    deleted: 0,

    questionnaireTags: undefined,
    questionnairsNotEmpty: false,

    pagination: {
        page: 1,
        from: 0,
        to: 0,
        count: 0
    },
}

export const questionnairesReducer = ( state: QuestionnairesState = initialQuestionnairesState, action: any ): QuestionnairesState => {
    switch ( action.type ) {
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_OK:
            return { ...state, questionnaires: action.questionnaires, pagination: action.pagination}
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_JSON:
            return { ...state, questionnaires: null }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_RESPONSE:
            return { ...state, questionnaires: null }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_SEARCH:
            return { ...state,  search: action.search }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_ORDER:
            return { ...state, order: state.order == 'ASC' ? 'DESC' : 'ASC'}
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_DELETED:
            return { ...state, search: "", deleted: state.deleted == 0 ? 1 : 0}
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_OK:
            return { ...state, questionnaireTags: action.questionnaireTags }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_INVALID_JSON:
            return { ...state, questionnaireTags: null }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_INVALID_RESPONSE:
            return { ...state, questionnaireTags: null }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY:
            return { ...state, questionnairsNotEmpty: true }
        case GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRE_TAG_QUESTIONNAIRE_NOT_EMPTY_CLEAR:
            return { ...state, questionnairsNotEmpty : false }    
        default:
            return state
    }
}
