export const status = ( status: number ): string => {

    if ( status === undefined ) {

        return ``

    }

    switch ( status ) {

        case 0:
            return `Aktív`
        case 1:
            return `Csak ütemezett`
        case 2:
            return `Nem aktív`

        default:
            return ``

    }

}

export const statusColor = ( status: number ): string => {

    if ( status === undefined ) {

        return `var( --color-dark )`

    }

    switch ( status ) {

        case 0:
            return `var( --color-green )`
        case 2:
            return `var( --color-warning )`

        default:
            return `var( --color-dark )`

    }

}
