import { QuestionnaireQuestion } from '../../../../entity'
import './user-image-answer-element.scss'

interface UserImageAnswerElementProps {
    questionElement: QuestionnaireQuestion
    position: number
}

export const UserImageAnswerElement = ( props: UserImageAnswerElementProps ) => {

    return (
        <div className="image-section-container" >                                

            { props.questionElement.answers != null && props.questionElement.answers[props.position] != null && props.questionElement.answers[props.position].imageUrl !== "" ? 
                <img className= "image" src= { props.questionElement.answers[props.position].imageUrl } alt= "Kérdőívhez tartozó kép" />
                    : 
                 null
            }

            { ( props.questionElement.answers != null && props.questionElement.answers[props.position] != null && props.questionElement.answers[props.position].userAnswers != null ) ?
                <div className="percent-text-div">
                    <div className="percent-div">
                         <div className="percent" style={{width: `${(100 * props.questionElement.answers[props.position].userAnswers!.length /  props.questionElement.answers[props.position].allAnswerCount!).toFixed(0)}%` }}/>
                    </div>

                    <h3 className="title-text-h3 text">{props.questionElement.answers[props.position].userAnswers?.length } - { (100 * props.questionElement.answers[props.position].userAnswers!.length /  props.questionElement.answers[props.position].allAnswerCount!).toFixed(0)} %</h3>

                </div>
                    : 
                <div className="percent-text-div">
                    <div className="percent-div">
                        <div className="percent" style={{width: `0%` }}/>
                    </div>

                    <h3 className="title-text-h3 text">0 - 0 %</h3>

                </div>
            }       
        </div>
    )

}
