import { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { Modal } from '../modal'
import { SearchInput } from '../search'
import { status, statusColor } from '../../helper'
import './quotes-modal.scss'

interface QuotesModalProps {

    onModalClose?: () => void
    onSelect?: ( id: number ) => void

}

export const QuotesModal = ( props: QuotesModalProps ) => {

    const { allQuotes } = useSelector( ( state: State ) => state.quotes )
    const [ selected, setSelected ] = useState( null as number | null )
    const [ filter, setFilter ] = useState( `` )

    if ( selected === null && allQuotes.length > 0 ) {

        setSelected( allQuotes[ 0 ].id )

    }

    return (

        <Modal className="quotes-modal" onModalClose={ () => props.onModalClose && props.onModalClose() }>

            <header>
                <div className="quotes-modal__title">Ütemezett idézetkártya hozzáadása</div>
                <SearchInput placeholder="Kezdj el gépelni!" onChange={ e => setFilter( e.target.value ) } />
                <img className="close-modal" src="/image/close.svg" onClick={ e => props.onModalClose && props.onModalClose() } />

            </header>

            <main>

                { allQuotes.filter( quote => quote.quote.indexOf( filter ) !== -1 ).map( quote => (

                    <div key={ quote.id } className={ `quote${ selected === quote.id ? ` quote--selected` : `` }` } onClick={ e => setSelected( quote.id ) }>

                        <div className="quote__id">{ quote.id }</div>
                        <div className="quote__quote"><span className="politician">{ quote.politician }</span>: { quote.quote }</div>
                        <div className="quote__popular_reaction"></div>
                        <div className="quote__views">{ quote.views }</div>
                        <div className="quote__status" style={ { color: statusColor( quote.status ) } }>{ status( quote.status ) }</div>

                    </div>

                ) ) }

            </main>

            <footer>

                <button className="button" style={ { minWidth: 313 } } disabled={ selected === null } onClick={ e => props.onSelect && props.onSelect( selected! ) }>Idézetkártya hozzáadása</button>

            </footer>

        </Modal>

    )

}
