import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getPushSettings } from './get-push-settings.action'
import { CHANGE_PUSH_SETTINGS_ACTIONS } from './push.constants'

export const modifyPushSettings = async () => {
    store.dispatch({ type: CHANGE_PUSH_SETTINGS_ACTIONS.CHANGE_PUSH_SETTINGS_STARTED })

    const body = JSON.stringify({
        questDailyClosing: store.getState().push.settings.questDailyClosing,
        questFridayBeforeLeagueClose: store.getState().push.settings.questFridayBeforeLeagueClose,
        questWeeklyClosing: store.getState().push.settings.questWeeklyClosing,
        questMiddleOfTheWeek: store.getState().push.settings.questMiddleOfTheWeek,
        eventsOneDayBeforeTheEvent: store.getState().push.settings.eventsOneDayBeforeTheEvent,
        activityNotLogInForThreeDay: store.getState().push.settings.activityNotLogInForThreeDay,
        activityNotLogInForSixDay: store.getState().push.settings.activityNotLogInForSixDay,
        activityLogInForThreeDay: store.getState().push.settings.activityLogInForThreeDay,
        activityLogInForSixDay: store.getState().push.settings.activityLogInForSixDay,
        activityDontBreakTheStreak: store.getState().push.settings.activityDontBreakTheStreak,
        oneDayAfterRegistrationIfHaventCompletedAnyMission: store.getState().push.settings.oneDayAfterRegistrationIfHaventCompletedAnyMission,
        birthday: store.getState().push.settings.birthday,

        markAsAFriend: store.getState().push.settings.markAsAFriend,
        friendConfirmed: store.getState().push.settings.friendConfirmed,
    })

    const promise = createCall(`firebase/push-settings`, BASE_METHODS.PUT, body)
    promise.then(async (response) => {
        store.dispatch({ type: CHANGE_PUSH_SETTINGS_ACTIONS.CHANGE_PUSH_SETTINGS_OK })

        getPushSettings()
    })

    promise.catch((error) => {
        store.dispatch({ type: error })
    })
}
