import { useSelector } from "react-redux";
import { InfoSlot } from "../../component";
import { News, Event, PushMessage } from "../../entity";
import { State } from "../../state";
import { DashboardTableElement } from "./component/dashboard-table-element";
import "./dashboard.page.scss";
import {
  createFormattedFullDateString,
  createFormattedDateTimeString,
} from "../../helper";
import { NavLink } from "react-router-dom";

interface DashboardProps {}

export const Dashboard = (props: DashboardProps) => {
  const {
    applicationLaunchedCount,
    applicationLaunchedCountPercent,
    userCount,
    userCountPercent,
    lastNews,
    lastEvents,
    lastPushMessages,
  } = useSelector((state: State) => state.dashboard);

  const newsElements: JSX.Element[] = [];
  if (lastNews != null) {
    lastNews.map((newsElement: News) => {
      newsElements.push(
        <DashboardTableElement
          id={newsElement.id}
          to={"/hirek/"}
          title={newsElement.lead}
          description={createFormattedDateTimeString(newsElement.visibleFrom)}
          image={newsElement.imageFile?.url}
        />
      );
    });
  }

  const eventsElements: JSX.Element[] = [];
  if (lastEvents != null) {
    lastEvents.map((eventElement: Event) => {
      eventsElements.push(
        <DashboardTableElement
          id={eventElement.id}
          to={"/esemenyek/"}
          title={eventElement.title}
          description={createFormattedFullDateString(eventElement.startDate)}
          image={eventElement.imageFile?.url}
        />
      );
    });
  }

  const pushMessagesElements: JSX.Element[] = [];
  if (lastPushMessages != null) {
    lastPushMessages.map((pushMessageEleement: PushMessage) => {
      pushMessagesElements.push(
        <DashboardTableElement
          id={null!}
          to={"/push-uzenetek"}
          title={pushMessageEleement.title}
          description={createFormattedFullDateString(
            pushMessageEleement.created
          )}
          image={null}
        />
      );
    });
  }

  return (
    <main className="page page-dashboard">
      <div className="main-div">
        <div className="navigation-title">
          <h1 className="title-text-h1">Vezérlőpult</h1>
        </div>

        <div className="card-div container-top-margin">
          <header>
            <h2 className="title-text-h2">Kiemelt statisztikai adatok</h2>
          </header>

          <div className="caption">
            A legfőbb adatok itt jelennek meg az elmúlt hét napból:
          </div>

          <div className="info-slots">
            <InfoSlot
              additionalInfo={`${applicationLaunchedCountPercent.toFixed(
                0
              )}%-al több megnyitás mint az előző héten`}
              caption="Megnyitások száma"
              color="green"
              info={{ suffix: `db`, value: `${applicationLaunchedCount}` }}
            />

            <InfoSlot
              additionalInfo={`${userCountPercent.toFixed(
                0
              )}%-al több felhasználó mint az előző héten`}
              caption="Felhaszálók száma"
              color="red"
              info={{ suffix: `fő`, value: `${userCount}` }}
            />
          </div>
        </div>

        <div className="container">
          <div className="inner-container ">
            {/* --- Következő esemény --- */}
            <div className="card-div card-div-right-margin card-div-bottom-padding">
              <div className="">
                <h2 className="title-text-h2">Következő esemény</h2>

                <ul id={"dashboard_event_table"} className="dashboard-table">
                  {eventsElements.length != 0 ? eventsElements : null}
                </ul>
              </div>

              <NavLink to="/esemenyek">
                <button className="button"> Összes megtekintése </button>
              </NavLink>
            </div>

            {/* --- Következő feladatok --- */}
            <div className="card-div card-div-bottom-padding">
              <div className="">
                <h2 className="title-text-h2">Következő feladatok</h2>
                <div className="dashboard-divider" />
              </div>

              <span className="description">Nincsenek következő feladatok</span>
            </div>
          </div>

          <div className="inner-container  ">
            {/* --- Legutóbbi hírek --- */}
            <div className="card-div card-div-right-margin card-div-bottom-padding">
              <div className="">
                <h2 className="title-text-h2">Legutóbbi hírek</h2>

                <ul id={"dashboard_news_table"} className="dashboard-table">
                  {newsElements.length != 0 ? newsElements : null}
                </ul>
              </div>

              <NavLink to="/hirek/lista">
                <button className="button"> Összes megtekintése </button>
              </NavLink>
            </div>

            {/* --- Legutóbbi push üzenetek --- */}
            <div className="card-div card-div-bottom-padding">
              <div className="">
                <h2 className="title-text-h2">Legutóbbi “értesítések”</h2>

                <ul id={"dashboard_push_table"} className="dashboard-table">
                  {pushMessagesElements.length != 0
                    ? pushMessagesElements
                    : null}
                </ul>
              </div>

              <NavLink to="/push-uzenetek">
                <button className="button"> Összes megtekintése </button>
              </NavLink>
            </div>
          </div>

          <div className="inner-container">
            {/* --- App látogatottsága --- */}
            <div className="card-div">
              <div className="">
                <h2 className="title-text-h2">App látogatottsága</h2>
              </div>
            </div>

            {/* --- Legutóbbi Admin tevékenységek --- */}
            <div className="card-div card-div-bottom-padding">
              <div className="">
                <h2 className="title-text-h2">Legutóbbi Admin tevékenységek</h2>
                <div className="dashboard-divider" />
              </div>

              <NavLink to="/tagok/adminok">
                <button className="button"> Összes megtekintése </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
