import { Expose } from 'class-transformer'
import { IsDefined } from 'class-validator'

export class GetPushSettingsDTO {
    @Expose()
    @IsDefined()
    pushSettings!: {
        questDailyClosing: boolean

        questFridayBeforeLeagueClose: boolean

        questWeeklyClosing: boolean

        questMiddleOfTheWeek: boolean

        eventsOneDayBeforeTheEvent: boolean

        activityNotLogInForThreeDay: boolean

        activityNotLogInForSixDay: boolean

        activityLogInForThreeDay: boolean

        activityLogInForSixDay: boolean

        activityDontBreakTheStreak: boolean

        birthday: boolean

        oneDayAfterRegistrationIfHaventCompletedAnyMission: boolean

        markAsAFriend: boolean

        friendConfirmed: boolean
    }
}
