import './achievement-form.scss'
import { Dropdown } from '../dropdown'
import { useState } from 'react'
import { TextField } from '../field'
import { TextArea } from '../textarea'
import { AddImage } from '../add-image'
import { MediaLibraryFile, Tag } from '../../entity'
import {MessageModal, Modal} from '../modal'
import { MediaLibrary } from '../media-library'
import { ImageEditor } from '../image-editor/image-editor'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import {deleteAchievementGroup} from "../../action/achievements"

interface AchievementFormProps {

    id?: number

    category?: number
    activity?: number
    name?: string
    description?: string
    type?: number

    bronzeDesiredValue?: number
    silverDesiredValue?: number
    goldDesiredValue?: number

    bronzeImage?: MediaLibraryFile
    silverImage?: MediaLibraryFile
    goldImage?: MediaLibraryFile

    bronzeExp?: number
    silverExp?: number
    goldExp?: number

    tagId?: number | null
    politicianId?: number | null

    showTypeSelect?: boolean
    showHeader?: boolean

    onSave?: ( achievementData: any ) => void
    onDelete?: () => void

}

const activities = [
    { id: 0, value: `Applikáció megnyitások száma` },
    { id: 1, value: `Applikáció megnyitások száma egymás után` },
    { id: 2, value: `Tapasztalat` },
    { id: 3, value: `Elvégzett képzések száma` },
    { id: 4, value: `Tökéletes kvízek száma` },
    { id: 5, value: `Elolvasott hírek száma` },
    { id: 6, value: `Megnyert ligák száma` },
    { id: 7, value: `Továbbjutások száma` },
    { id: 8, value: `Megismert politikusok száma` },
    { id: 9, value: `Esemény részvételek száma` },
    { id: 10, value: `Véleményvezér kártyák száma` },
    { id: 15, value: `Politikus megismerése` },
    { id: 16, value: `Címke gyüjtés` }
]

export const AchievementForm = ( props: AchievementFormProps ) => {

    const { politicians } = useSelector( ( state: State ) => state.politicians )
    const { tagGroups } = useSelector( ( state: State ) => state.tags )

    const [ activity, setActivity ] = useState( props.activity ? props.activity : 0 )
    const [ type, setType ] = useState( props.type ? props.type : 0 )
    const [ name, setName ] = useState( props.name ? props.name : `` )
    const [ description, setDescription ] = useState( props.description ? props.description : `` )

    const [ bronzeImage, setBronzeImage ] = useState( props.bronzeImage ? props.bronzeImage : null )
    const [ silverImage, setSilverImage ] = useState( props.silverImage ? props.silverImage : null )
    const [ goldImage, setGoldImage ] = useState( props.goldImage ? props.goldImage : null )

    const [ bronzeExp, setBronzeExp ] = useState( props.bronzeExp ? props.bronzeExp : 0 )
    const [ silverExp, setSilverExp ] = useState( props.silverExp ? props.silverExp : 0 )
    const [ goldExp, setGoldExp ] = useState( props.goldExp ? props.goldExp : 0 )

    const [ bronzeDesiredValue, setBronzeDesiredValue ] = useState( props.bronzeDesiredValue ? props.bronzeDesiredValue : 0 )
    const [ silverDesiredValue, setSilverDesiredValue ] = useState( props.silverDesiredValue ? props.silverDesiredValue : 0 )
    const [ goldDesiredValue, setGoldDesiredValue ] = useState( props.goldDesiredValue ? props.goldDesiredValue : 0 )

    const [ tagId, setTagId ] = useState( props.tagId ? props.tagId : null )
    const [ politicianId, setPoliticianId ] = useState( props.politicianId ? props.politicianId : null )

    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ imageType, setImageType ] = useState( null as string | null )

    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        deleteAchievementGroup( props.id! )
        setShowConfirmModal( false )
        if ( props.onDelete ) props.onDelete()

    }

    const onAddImage = ( type: string ) => {

        setImageType( type )
        setShowMediaLibrary( true )

    }

    const checkFields = (): boolean => {

        if ( name.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés megnevezésének megadása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( description.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés leírásának megadása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( type === 1 && bronzeImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A bronz kitüntetés képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( type === 1 && silverImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `Az ezüst kitüntetés képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        if ( goldImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `Az arany kitüntetés képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )
            return false

        }

        return true

    }

    const onImageSelected = ( f: MediaLibraryFile ) => {

        if ( imageType === `gold` ) { setGoldImage( f ) }
        if ( imageType === `silver` ) { setSilverImage( f ) }
        if ( imageType === `bronze` ) { setBronzeImage( f ) }

        setShowImageEditor( false )

    }

    const save = () => {

        if ( !checkFields() ) { return }
        if ( props.onSave ) {

            props.onSave( {

                id: props.id,
                activity: activity,
                name: name,
                category: props.category,
                description: description,
                type: type,
                bronzeImage: type === 1 ? bronzeImage : null,
                silverImage: type === 1 ? silverImage: null,
                goldImage: goldImage,
                bronzeExp: type === 1 ? bronzeExp : null,
                silverExp: type === 1 ? silverExp : null,
                goldExp: goldExp,
                bronzeDesiredValue: type === 1 ? bronzeDesiredValue : null,
                silverDesiredValue: type === 1 ? silverDesiredValue : null,
                goldDesiredValue: goldDesiredValue,
                tagId: tagId,
                politicianId: politicianId

            } )

        }

    }

    const onDeleteAchievementGroup = () => {

        setShowConfirmModal( true )

    }


    return (

        <div className="achievement-form">

            { props.showTypeSelect !== false ? (

                <div className="achievement-form__type">

                    <Dropdown value={ type } items={ [ { id: 0, value: `Egy darabos kitüntetés` }, { id: 1, value: `Több darabos kitüntetés` } ] } onChange={ setType } compact={ false } />

                </div>

            ) : null }

            { props.showHeader !== false ? (

                <div className="achievement-form__header">

                    <div className={ `achievement-form__activity ${ type === 0 ? `one` : `` }` }>

                        <label>Aktivitás</label>
                        <Dropdown value={ activity } onChange={ setActivity } compact={ false } items={ activities } />

                        { activity === 15 ? (

                            <>

                                <div style={{ height: 20 }} />
                                <Dropdown value={ politicianId } onChange={ ( id: number ) => setPoliticianId( id ) } compact={ false } items={ politicians!.map( politician => ( { id: politician.id, value: `${ politician.lastName } ${ politician.firstName }` } ) ) } />

                            </>

                        ) : null }

                        { activity === 16 ? (

                            <>

                                <div style={{ height: 20 }} />
                                <Dropdown value={ tagId } onChange={ ( id: number ) => setTagId( id ) } compact={ false } items={ tagGroups!.reduce( ( tags, tagGroup ) => [ ...tags, ...tagGroup.tags ], [] as Tag[] ).map( tag => ( { id: tag.id, value: tag.name } ) ) } />

                            </>

                        ) : null }

                    </div>

                    { type === 1 ? (

                        <>

                            <div className="achievement-form__value">

                                <label>Bronz aktivitás szám</label>
                                <TextField field={{ value: bronzeDesiredValue.toString(), error: `` }} onChange={ e => isNaN( parseInt( e.target.value ) ) ? bronzeDesiredValue : setBronzeDesiredValue( parseInt( e.target.value ) ) } />

                            </div>

                            <div className="achievement-form__value">

                                <label>Ezüst aktivitás szám</label>
                                <TextField field={{ value: silverDesiredValue.toString(), error: `` }} onChange={ e => isNaN( parseInt( e.target.value ) ) ? silverDesiredValue : setSilverDesiredValue( parseInt( e.target.value ) ) } />

                            </div>

                        </>

                    ) : null }

                    <div className="achievement-form__value">

                        <label>{ type === 1 ? `Arany a` : `A` }ktivitás szám</label>
                        <TextField field={{ value: goldDesiredValue.toString(), error: `` }} onChange={ e => isNaN( parseInt( e.target.value ) ) ? goldDesiredValue : setGoldDesiredValue( parseInt( e.target.value ) ) } />

                    </div>

                </div>

            ) : null }

            <div className="achievement-form__name">

                <label>Kitüntetés megnevezése *</label>
                <div className="info-text">Ez a megnevezés fog megjelenni a felhasználók profiljában és a képzések oldalán, bronz, ezüst és arany előtaggal</div>
                <TextField field={ { value: name, error: `` } } onChange={ e => setName( e.target.value ) } />

            </div>

            <div className="achievement-form__description">

                <label>Kitüntetés leírása *</label>
                <div className="info-text">Ez a felirat fog megjelenni a kitüntetés beloldalán</div>
                <TextArea field={ { value: description, error: `` } } onChange={ e => setDescription( e.target.value ) } />

            </div>

            <div className="achievement-form__image">

                <div></div>

                { type === 1 ? (

                    <>

                        <div>

                            <label>Bronz* (.png)</label>
                            <AddImage imageUrl={ bronzeImage ? bronzeImage.url! : `` } text="Bronz kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `bronze` ) } onDeleteImage={ () => setBronzeImage( null ) } />

                        </div>

                        <div>

                            <label>Ezüst* (.png)</label>
                            <AddImage imageUrl={ silverImage ? silverImage.url! : `` } text="Ezüst kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `silver` ) } onDeleteImage={ () => setSilverImage( null ) } />

                        </div>

                    </>

                ) : ( <div /> ) }

                <div>

                    <label>{ type === 1 ? `Arany*` : `Kitüntetés*` } (.png)</label>
                    <AddImage imageUrl={ goldImage ? goldImage.url! : `` } text="Arany kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `gold` ) } onDeleteImage={ () => setGoldImage( null ) } />

                </div>

            </div>

            <div className="achievement-form__exp">

                <div>

                    <img alt="" src="/image/exp.svg" />

                </div>

                { type === 1 ? (

                    <>

                        <div>

                            <TextField field={ { value: bronzeExp.toString(), error: `` } } onChange={ e => isNaN( parseInt( e.target.value ) ) ? bronzeExp : setBronzeExp( parseInt( e.target.value ) ) } />

                        </div>

                        <div>

                            <TextField field={ { value: silverExp.toString(), error: `` } } onChange={ e => isNaN( parseInt( e.target.value ) ) ? silverExp : setSilverExp( parseInt( e.target.value ) ) } />

                        </div>

                    </>

                ) : ( <div /> ) }

                <div>

                    <TextField field={ { value: goldExp.toString(), error: `` } } onChange={ e => isNaN( parseInt( e.target.value ) ) ? goldExp : setGoldExp( parseInt( e.target.value ) ) } />

                </div>

            </div>

            <div className="achievement-form__footer">

                { props.id ? (
                    <button className="button button--warning" onClick={ onDeleteAchievementGroup }>Törlés</button>
                ) : null }
                <button className="button button--primary" onClick={ save }>Mentés</button>

            </div>

            { showConfirmModal ? (

                <Modal className="achievement-form-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a kitüntetést?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

            { infoModalVisibility ? (

                <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => { setInfoModalVisibility( false ) } } />

            ) : null }

            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor aspectRatio={ { name: `Kitüntetés képe`, ratio: 1 } } mediaLibraryFile={ file! } onReady={ f => onImageSelected( f ) } onClose={ () => setShowImageEditor( false ) } />

            ) : null }

        </div>

    )

}