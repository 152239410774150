import { QuestionnaireQuestionAnswer } from '../../../../entity'
import './questionnaire-user-answer-answer-element.scss'

interface QuestionnaireUserAnswerAnswerElementProps {
    position: number
    answerElement: QuestionnaireQuestionAnswer
}

export const QuestionnaireUserAnswerAnswerElement = ( props: QuestionnaireUserAnswerAnswerElementProps ) => {

    return (
        <li key={ props.answerElement.id+props.position} className="questionnaire-answer-answer-table__questionnaire-answer-answer-element" >

            <div className="text-section-container">

                <h3 className="title-text-h3">{props.position}. {props.answerElement.answer}</h3>

                <div className="percent-text-div">
                    <div className="percent-div">
                        { ( props.answerElement.userAnswers != null ) ? 
                            <div className="percent" style={{width: `${(100 * props.answerElement.userAnswers!.length /  props.answerElement.allAnswerCount!).toFixed(0)}%` }}/>
                            : 
                            <div className="percent" style={{width: `0%` }}/>
                        }
                    </div>

                    { ( props.answerElement.userAnswers != null ) ? 
                        <h3 className="title-text-h3 text">{props.answerElement.userAnswers?.length } - { (100 * props.answerElement.userAnswers!.length /  props.answerElement.allAnswerCount!).toFixed(0)} %</h3>
                        : 
                        <h3 className="title-text-h3 text">0 %</h3>
                    }
                </div>

            </div>
        
        </li >
    )

}
