import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getAllPushMessage } from './get-all-push.action'
import { CREATE_PUSH_ACTIONS } from './push.constants'

export const createPush = async () => {
    store.dispatch({ type: CREATE_PUSH_ACTIONS.CREATE_PUSH_STARTED })

    const contentAction = {
        actionId: store.getState().push.form.contentAction.actionId,
        contentUrl: store.getState().push.form.contentAction.contentUrl.value.length !== 0 ? store.getState().push.form.contentAction.contentUrl.value : null,
        contentId: store.getState().push.form.contentAction.contentId !== -1 ? store.getState().push.form.contentAction.contentId : null,
    }

    const body = JSON.stringify({
        title: store.getState().push.form.title.value,
        body: store.getState().push.form.body.value,
        data: null,
        topic: 'general',
        contentAction: contentAction,
        targets: store.getState().push.form.targets,
        mediaLibraryFileId: store.getState().createNews.form.mediaLibraryFile?.id ?? null,
        timing: store.getState().push.form.timing != null ? new Date(store.getState().push.form.timing!).getTime() / 1000 : null,
        immediate: store.getState().push.form.immediate,
    })

    const promise = createCall(`firebase/send-push`, BASE_METHODS.POST, body)
    promise.then(async (response) => {
        store.dispatch({ type: CREATE_PUSH_ACTIONS.CREATE_PUSH_OK })

        getAllPushMessage()
    })

    promise.catch((error) => {
        store.dispatch({ type: error })
    })
}
