import { MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PushMessageTarget } from '../../../entity'
import { Dropdown } from '../../../component'
import { CREATE_PUSH_ACTIONS } from '../../../action/push/push.constants'
import './target-element.scss'

interface TargetElementProps {
    targetElement: PushMessageTarget
    position: number
}

export const TargetElement = (props: TargetElementProps) => {
    const dispatch = useDispatch()

    const [subTypeElements, setSubTypeElements] = useState([{ value: 'Válassz egy kategóriát', id: -1 }])
    const [subSubTypeElements, setSubSubTypeElements] = useState([{ value: 'Válassz egy kategóriát', id: -1 }])

    const typeElements = [
        { id: -1, value: 'Válassz egy kategóriát' },
        { id: 0, value: 'Demográfia' },
        { id: 1, value: 'Aktivitás' },
    ]

    const onTypeChange = (typeId: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_TYPE,
            typeId: typeId,
            id: props.targetElement.id,
        })

        if (typeId == 0) {
            setSubTypeElements([
                { id: -1, value: 'Válassz egy kategóriát' },
                { id: 0, value: 'Neme' },
                // { id: 1, value: 'Hely' },  //Jelenleg még nem elérhető a hely
                { id: 2, value: 'Kor' },
            ])
        } else if (typeId == 1) {
            setSubTypeElements([
                { id: -1, value: 'Válassz egy kategóriát' },
                { id: 3, value: 'Aktív' },
                { id: 4, value: 'Inaktív' },
            ])
        }
    }

    const onSubTypeChange = (typeId: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_SUB_TYPE,
            typeId: typeId,
            id: props.targetElement.id,
        })

        if (typeId == 0) {
            setSubSubTypeElements([
                { id: -1, value: 'Válassz egy kategóriát' },
                { id: 0, value: 'Nem adom meg' },
                { id: 1, value: 'Férfi' },
                { id: 2, value: 'Nő' },
                { id: 3, value: 'Összes' },
            ])
        } else if (typeId == 1) {
            //Jelenleg még nem elérhető a hely
            setSubSubTypeElements([{ id: -1, value: 'Válassz egy kategóriát' }])
        } else if (typeId == 2) {
            setSubSubTypeElements([
                { id: -1, value: 'Válassz egy kategóriát' },
                { id: 0, value: '0-18' },
                { id: 1, value: '18-25' },
                { id: 2, value: '25-40' },
                { id: 3, value: '40-55' },
                { id: 4, value: '55+' },
                { id: 5, value: 'Mindenki' },
            ])
        } else if (typeId == 3 || typeId == 4) {
            setSubSubTypeElements([
                { id: -1, value: 'Válassz egy kategóriát' },
                { id: 0, value: '1-4 nap' },
                { id: 1, value: '4-8 nap' },
                { id: 2, value: '8+' },
            ])
        }
    }

    const onSubSubTypeChange = (typeId: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TARGET_SUB_SUB_TYPE,
            typeId: typeId,
            id: props.targetElement.id,
        })
    }

    const onDeleteButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_REMOVE_TARGET,
            selectedTargetId: props.targetElement.id,
        })
    }

    return (
        <li key={props.targetElement.id} className="target-table__target-element">
            <div className="inner-container">
                <Dropdown value={props.targetElement.type} items={typeElements} onChange={onTypeChange} compact={false} />

                <div className="divider" />

                <Dropdown value={props.targetElement.subType} items={subTypeElements} onChange={onSubTypeChange} compact={false} />

                <div className="divider" />

                <Dropdown value={props.targetElement.subSubType} items={subSubTypeElements} onChange={onSubSubTypeChange} compact={false} />

                <div className="divider" />

                <img className="delete" onClick={onDeleteButtonClick} alt={'Kategória törlés ikon'} src="/image/trash.svg " />
            </div>

            <label className="and-label"> és </label>
        </li>
    )
}
