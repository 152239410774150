import { Table } from '../table'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import './quizes-table.scss'
import { QuizTopic } from '../quiz-topic'
import { createFormattedDateTimeString } from '../../helper'
import { MenuButton } from '../button'
import { Dropdown } from '../dropdown'
import { deleteQuiz, deleteTest, editQuiz, editTest, listQuizes } from '../../action/quizes'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../modal'
import React, { useState } from 'react'

export const QuizesTable = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { quizes, pagination, order } = useSelector( ( state: State ) => state.quizes )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )


    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onPageChange = async ( page: number ) => {

        await listQuizes( page )

    }

    const onOrderChange = async ( column: string ) => {

        dispatch( { type: `QUIZ_ORDER_CHANGE`, by: column, direction: ( order.by === column && order.direction === `ASC` ) ? `DESC` : `ASC` } )
        await listQuizes( pagination.page )

    }

    const open = ( type: string, id: number ) => {

        navigate( `/kvizek-es-tesztek/${ type === `quiz` ? `kvizek` : `tesztek` }/${ id }` )

    }

    const onMenuSelect = async ( action: number, id: number, type: string ) => {

        switch ( action ) {

            case 3:
                setDeleteFunc( () => {
                    return async () => {

                        if ( type === `quiz` ) {

                            await deleteQuiz( id )

                        } else {

                            await deleteTest( id )

                        }
                        await listQuizes( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            case 2:
                await onArchiveChange( id, type )
                break

            default:
                open( type, id )

        }

    }

    const onRowClick = ( quiz: any ) => {

        open( quiz.type, quiz.id )

    }

    const onStatusChange = async ( id: number, status: number, type: string ) => {

        if ( type === `quiz` ) {

            await editQuiz( id, { isDraft: status } )

        } else {

            await editTest( id, { isDraft: status } )

        }

        await listQuizes( pagination.page )

    }

    const onArchiveChange = async ( id: number, type: string ) => {

        if ( type === `quiz` ) {

            await editQuiz( id, { visibleTo: Math.round( new Date().getTime() / 1000 ) } )

        } else {

            await editTest( id, { visibleTo: Math.round( new Date().getTime() / 1000 ) } )

        }

        await listQuizes( pagination.page )

    }

    return (

        <>

            <Table className="quizes-table" columns={ [
                {
                    align: `center`,
                    name: `id`,
                    headerRender: () => `ID`,
                    render: quiz => quiz.id,
                    width: 100
                },
                {
                    align: `center`,
                    name: `type`,
                    headerRender: () => `Típus`,
                    render: quiz => quiz.type === `quiz` ? `Kvíz` : `Teszt`,
                    width: 100
                },
                {
                    align: `left`,
                    name: `title`,
                    headerRender: () => ``,
                    render: quiz => quiz.title,
                    width: 300
                },
                {
                    align: `center`,
                    canOrder: false,
                    name: `answers`,
                    headerRender: () => `Kitöltve`,
                    render: quiz => quiz.answers,
                    width: 100
                },
                {
                    align: `center`,
                    name: `topic_name`,
                    headerRender: () => `Téma`,
                    render: quiz => <QuizTopic color={ quiz.topic_color } name={ quiz.topic_name } />,
                    width: 150
                },
                {
                    align: `center`,
                    name: `visible_from`,
                    headerRender: () => `Publikálás`,
                    render: quiz => createFormattedDateTimeString( quiz.visibleFrom ),
                    width: 200
                },
                {
                    align: `center`,
                    name: `visible_to`,
                    headerRender: () => `Archiválás`,
                    render: quiz => quiz.visibleTo ? createFormattedDateTimeString( quiz.visibleTo ) : ``,
                    width: 200
                },
                {
                    align: `center`,
                    canOrder: false,
                    name: `is_draft`,
                    headerRender: () => ``,
                    render: quiz => <Dropdown compact={ true } items={ [
                        {
                            id: 0,
                            value: `Aktív`
                        },
                        {
                            id: 1,
                            value: `Vázlat`
                        }
                    ] } onChange={ ( status: number ) => onStatusChange( quiz.id, status, quiz.type ) } value={ quiz.isDraft } />,
                    width: 150
                },
                {
                    canOrder: false,
                    name: `actions`,
                    headerRender: () => ``,
                    render: quiz => <MenuButton hasBackground={ true } items={ [
                        {
                            id: 0,
                            value: `Szerkesztés`,
                            icon: `edit.svg`
                        },
                        {
                            id: 1,
                            value: `Megtekintés`,
                            icon: `eye.svg`
                        },
                        {
                            id: 2,
                            value: `Archiválás`,
                            icon: `folder-times.svg`
                        },
                        {
                            id: 3,
                            value: `Törlés`,
                            icon: `trash.svg`
                        }
                    ] } onChange={ ( action: number ) => onMenuSelect( action, quiz.id, quiz.type ) } />,
                    width: 100
                }
            ] } dataset={ quizes } order={ order } onRowClick={ onRowClick } pagination={ { ...pagination, onPageChange } } onOrderChange={ onOrderChange } />

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a kvízt?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}