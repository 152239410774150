import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ImageFile } from './image-file.entity'
import { MediaLibraryFile } from './media-library-file.entity'
import { QuestionnaireQuestionUserAnswer } from './questionnaire-question-user-answer.entity'
import { Tag } from './tag.entity'

export class QuestionnaireQuestionAnswer {

    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number 

    @Expose()
    @IsDefined()
    @IsString()
    answer!: string

    @IsOptional()
    @ValidateNested()
    @Type( () => ImageFile )
    imageFile?: ImageFile | null

    @Expose()
    tags!: Tag[]

    //mezők a formhoz
    answerField!: {
        error: string
        value: string 
        required: boolean
    }

    imageUrl!: string

    mediaLibraryFile!: MediaLibraryFile | null


    //ide gyűjtöm össze az adott válaszhoz a user válaszokat hogy kérdőíveknél a válasz részben a százalékos és egyébb eredményeket meg leshessen jeleníteni
    userAnswers?: QuestionnaireQuestionUserAnswer[] | null

    allAnswerCount?: number | null

    isNew!: boolean

}
