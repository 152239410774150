export enum ContentActionCodes {
    OPEN_DASHBOARD = 0,
    OPEN_PROFILE = 1,
    OPEN_ACHIVEMENTS = 2,
    OPEN_ACQUAINTANCES = 3,
    OPEN_NOTIFICATIONS_PAGE = 4,
    OPEN_EXTERNAL_LINK = 5,
    USER_SETTINGS = 6,
    NOTIFICATIONS_LIST = 7,
    OPEN_NEWS_LIST_PAGE = 10,
    OPEN_NEWS_DETAIL_PAGE = 11,
    OPEN_POLITICIANS_LIST_PAGE = 20,
    OPEN_POLITICIAN_DETAIL_PAGE = 21,
    OPEN_QUESTIONNAIRES_LIST_PAGE = 30,
    OPEN_QUESTIONNAIRE_DETAIL_PAGE = 31,
    OPEN_QUOTES_PAGE = 40,
    OPEN_EVENTS_LIST_PAGE = 50,
    OPEN_EVENT_DETAIL_PAGE = 51,
    OPEN_COURSES_LIST_PAGE = 60,
    OPEN_COURSE_DETAIL_PAGE = 61,
    OPEN_MISSIONS_PAGE = 70,
    OPEN_MISSIONS_DETAIL_PAGE = 71,
    OPEN_LEADERBOARD_PAGE = 72,
    OPEN_AWARDS_PAGE = 73,
    OPEN_QUIZES_LIST_PAGE = 80,
    OPEN_QUIZE_DETAIL_PAGE = 81,
    OPEN_TEST_DETAIL_PAGE = 82,
}
