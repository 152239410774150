import { ChangeEvent, createRef, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPush } from '../../action/push'
import { CREATE_PUSH_ACTIONS } from '../../action/push/push.constants'
import { TextField } from '../../component/field'
import { TextArea } from '../../component/textarea'
import { State } from '../../state'
import { PushMessageTable } from './component'
import { MessageModal } from '../../component/modal'
import { AddImage } from '../../component/add-image'
import { Dropdown } from '../../component'
import { MediaLibrary } from '../../component/media-library'
import { MediaLibraryFile } from '../../entity'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './push.page.scss'
import { getAllNews } from '../../action'
import { getPoliticians } from '../../action/politician'
import { getQuestionnaires } from '../../action/questionnaires'
import { getEvents } from '../../action/events'
import { listCourses } from '../../action/courses'
import { listQuests } from '../../action/gamification'
import { listQuizes } from '../../action/quizes'
import { TargetTable } from './component/target-table'
import { PushMessageSettingsModal } from './component/push-message-settings-modal'
import { ContentActionCodes } from '../../enum'

interface PushProps {}

export const Push = (props: PushProps) => {
    const dispatch = useDispatch()

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState('')
    const [infoModalDescription, setInfoModalDescription] = useState('')

    const [settingsModalVisibility, setSettingsModalVisibility] = useState(false)

    const [activeSection, setActiveSection] = useState(1)

    const [contentUrlVisibility, setContentUrlVisibility] = useState(false)
    const [contentIdVisibility, setContentIdVisibility] = useState(false)
    const [contentActionElements, setContentActionElements] = useState([{ value: 'Válassz egy kategóriát', id: -1, parentId: -1 }])
    const [contentContnetElements, setContentContnetElements] = useState([{ value: 'Válassz egy kategóriát', id: -1 }])

    const [mediaLibraryFile, setMediaLibraryFile] = useState(null as MediaLibraryFile | null)
    const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false)
    const [imageEditorVisibility, setImageEditorVisibility] = useState(false)

    const form = useSelector((state: State) => state.push.form)

    const { news } = useSelector((state: State) => state.news)
    const politicians = useSelector((state: State) => state.politicians.politicians)
    const questionnaires = useSelector((state: State) => state.questionnaires.questionnaires)
    const { events } = useSelector((state: State) => state.events)
    const { courses } = useSelector((state: State) => state.courses)
    const { quests } = useSelector((state: State) => state.gamification)
    const { quizes } = useSelector((state: State) => state.quizes)

    const formElement = createRef<HTMLFormElement>()

    let contentCategoryElements = [
        { value: 'Válassz egy kategóriát', id: -1 },
        { value: 'Általános', id: 0 },
        { value: 'Hírek', id: 1 },
        { value: 'Politikusok', id: 2 },
        { value: 'Kérdőívek', id: 3 },
        { value: 'Véleményvezérek', id: 4 },
        { value: 'Események', id: 5 },
        { value: 'Képzések', id: 6 },
        { value: 'Küldetések', id: 7 },
        { value: 'Kvízek és tesztek', id: 8 },
        { value: 'Külső hivatkozás', id: 9 },
    ]

    let allContentActionElements = [
        {
            value: 'Dashboard megnyitása',
            id: ContentActionCodes.OPEN_DASHBOARD,
            parentId: 0,
            hasContentId: false,
        },
        {
            value: 'Felhasználói profil megnyitása',
            id: ContentActionCodes.OPEN_PROFILE,
            parentId: 0,
            hasContentId: false,
        },
        {
            value: 'Kitüntetések megnyitása',
            id: ContentActionCodes.OPEN_ACHIVEMENTS,
            parentId: 0,
            hasContentId: false,
        },
        {
            value: 'Ismerőseim oldal megnyitása',
            id: ContentActionCodes.OPEN_ACQUAINTANCES,
            parentId: 0,
            hasContentId: false,
        },
        {
            value: 'Értesítések oldal megnyitása',
            id: ContentActionCodes.OPEN_NOTIFICATIONS_PAGE,
            parentId: 0,
            hasContentId: false,
        },
        {
            value: 'Felhasználói beállítások',
            id: ContentActionCodes.USER_SETTINGS,
            parentId: 0,
            hasContentId: false,
        },
        { value: 'Értesítések lista', id: ContentActionCodes.NOTIFICATIONS_LIST, parentId: 0, hasContentId: false },
        {
            value: 'Hírek lista oldal megnyitása',
            id: ContentActionCodes.OPEN_NEWS_LIST_PAGE,
            parentId: 1,
            hasContentId: false,
        },
        {
            value: 'Hír detail oldal megnyitása',
            id: ContentActionCodes.OPEN_NEWS_DETAIL_PAGE,
            parentId: 1,
            hasContentId: true,
        },
        {
            value: 'Politikusok lista oldal megnyitása',
            id: ContentActionCodes.OPEN_POLITICIANS_LIST_PAGE,
            parentId: 2,
            hasContentId: false,
        },
        {
            value: 'Politikus detail oldal megnyitása',
            id: ContentActionCodes.OPEN_POLITICIAN_DETAIL_PAGE,
            parentId: 2,
            hasContentId: true,
        },
        {
            value: 'Kérdőívek lista oldal megnyitása',
            id: ContentActionCodes.OPEN_QUESTIONNAIRES_LIST_PAGE,
            parentId: 3,
            hasContentId: false,
        },
        {
            value: 'Kérdőív detail oldal megnyitása',
            id: ContentActionCodes.OPEN_QUESTIONNAIRE_DETAIL_PAGE,
            parentId: 3,
            hasContentId: true,
        },
        {
            value: 'Véleményvezérek oldal megnyitása',
            id: ContentActionCodes.OPEN_QUOTES_PAGE,
            parentId: 4,
            hasContentId: false,
        },
        {
            value: 'Események lista oldal megnyitása',
            id: ContentActionCodes.OPEN_EVENTS_LIST_PAGE,
            parentId: 5,
            hasContentId: false,
        },
        {
            value: 'Esemény detail oldal megnyitása',
            id: ContentActionCodes.OPEN_EVENT_DETAIL_PAGE,
            parentId: 5,
            hasContentId: true,
        },
        {
            value: 'Képzések lista oldal megnyitása',
            id: ContentActionCodes.OPEN_COURSES_LIST_PAGE,
            parentId: 6,
            hasContentId: false,
        },
        {
            value: 'Képzés detail oldal megnyitása',
            id: ContentActionCodes.OPEN_COURSE_DETAIL_PAGE,
            parentId: 6,
            hasContentId: true,
        },
        {
            value: 'Küldetések kezdőoldal megnyitása',
            id: ContentActionCodes.OPEN_MISSIONS_PAGE,
            parentId: 7,
            hasContentId: false,
        },
        {
            value: 'Küldetés csoport detail oldal megnyitása',
            id: ContentActionCodes.OPEN_MISSIONS_DETAIL_PAGE,
            parentId: 7,
            hasContentId: true,
        },
        {
            value: 'Ranglista oldal megnyitása',
            id: ContentActionCodes.OPEN_LEADERBOARD_PAGE,
            parentId: 7,
            hasContentId: false,
        },
        {
            value: 'Jutalmak oldal megnyitása',
            id: ContentActionCodes.OPEN_AWARDS_PAGE,
            parentId: 7,
            hasContentId: false,
        },
        {
            value: 'Kvízek és tesztek lista oldal megnyitása',
            id: ContentActionCodes.OPEN_QUIZES_LIST_PAGE,
            parentId: 8,
            hasContentId: false,
        },
        {
            value: 'Kvíz detail oldal megnyitása',
            id: ContentActionCodes.OPEN_QUIZE_DETAIL_PAGE,
            parentId: 8,
            hasContentId: true,
        },
        {
            value: 'Teszt detail oldal megnyitása',
            id: ContentActionCodes.OPEN_TEST_DETAIL_PAGE,
            parentId: 8,
            hasContentId: true,
        },
    ]

    const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TITLE,
            title: event.target.value,
        })
    }

    const onBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_BODY,
            body: event.target.value,
        })
    }

    const onSendClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!checkFields()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await createPush()
    }

    const checkFields = (): boolean => {
        if (form.title.value.trim().length === 0) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A értesítés címének megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.body.value.trim().length === 0) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A értesítés szöveg megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.contentAction.categoryId === -1) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A célhely megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.contentAction.categoryId === 9 && form.contentAction.contentUrl.value.trim().length === 0) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A Link megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        } else if (form.contentAction.categoryId !== 9 && form.contentAction.actionId === -1) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A ID megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        } else if (
            (form.contentAction.actionId === ContentActionCodes.OPEN_NEWS_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_POLITICIAN_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_QUESTIONNAIRE_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_EVENT_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_COURSE_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_MISSIONS_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_QUIZE_DETAIL_PAGE ||
                form.contentAction.actionId === ContentActionCodes.OPEN_TEST_DETAIL_PAGE) &&
            form.contentAction.contentId === -1
        ) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('A tartalom kiválasztása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        //TODO id és conent id

        return true
    }

    const onNotificationSettingsClick = async (event: MouseEvent) => {
        event.preventDefault()

        setSettingsModalVisibility(true)
    }

    const onCopyNameTagClick = async (event: MouseEvent) => {
        event.preventDefault()

        navigator.clipboard.writeText('=NAME*')
    }

    const onContentCategoryIdChange = (categoryId: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CATEGORY_ID,
            categoryId: categoryId,
        })

        setContentUrlVisibility(false)
        setContentIdVisibility(false)

        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_URL,
            url: '',
        })

        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID,
            actionId: null,
        })

        setContentActionElements([{ value: 'Válassz egy kategóriát', id: -1, parentId: -1 }])

        if (categoryId == 9) {
            dispatch({
                type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID,
                actionId: 5,
            })
            setContentUrlVisibility(true)
        } else {
            allContentActionElements.map((item: any) => {
                if (item.parentId == categoryId) {
                    setContentActionElements((oldArray) => [...oldArray, item])
                }
            })
        }
    }

    const onContentActionIdChange = (actionId: any) => {
        setContentIdVisibility(false)

        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID,
            actionId: null,
        })

        contentActionElements.map(async (item: any) => {
            if (item.id == actionId && item.hasContentId) {
                setContentContnetElements([{ value: 'Válassz egy tartalmat', id: -1 }])

                switch (item.id) {
                    case ContentActionCodes.OPEN_NEWS_DETAIL_PAGE: //Hírek
                        await getAllNews()

                        if (news) {
                            news.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_POLITICIAN_DETAIL_PAGE: //Politikusok
                        await getPoliticians()

                        if (politicians) {
                            politicians.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.lastName + ' ' + item.firstName, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_QUESTIONNAIRE_DETAIL_PAGE: //Kérdőívek
                        await getQuestionnaires()

                        if (questionnaires) {
                            questionnaires.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_EVENT_DETAIL_PAGE: //Események
                        await getEvents()

                        if (events) {
                            events.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_COURSE_DETAIL_PAGE: //Képzések
                        await listCourses()

                        if (courses) {
                            courses.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_MISSIONS_DETAIL_PAGE: //Küldetések
                        await listQuests()

                        if (quests) {
                            quests.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.name, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_TEST_DETAIL_PAGE: //Kvízek
                        await listQuizes()
                        //TODO paginálás és kvíz választás

                        if (quizes) {
                            quizes.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    case ContentActionCodes.OPEN_TEST_DETAIL_PAGE: //Tesztek
                        //TODO paginálás és teszt választás
                        await listQuizes()

                        if (quizes) {
                            quizes.map(async (item: any) => {
                                setContentContnetElements((oldArray) => [...oldArray, { value: item.id + ' - ' + item.title, id: item.id }])
                            })
                        }
                        break
                    default:
                        return null
                }

                setContentIdVisibility(true)
            }
        })

        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_ID,
            actionId: actionId,
        })
    }

    const onContenUrlFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_URL,
            url: event.target.value,
        })
    }

    const onContentIdChange = (contentId: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_CONTENT_ACTION_CONTENT_ID,
            contentId: contentId,
        })
    }

    const onImmediateChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_IMMEDIATE,
            immediate: parseInt(event.target.value),
        })
    }

    const onTiminghange = (date: Date | null, event: any) => {
        dispatch({
            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_TIMING,
            timing: date!.toISOString(),
        })
    }

    return (
        <main className="page page-push">
            <div className="main-div">
                <div className="navigation-title">
                    <h1 className="title-text-h1">Értesítések</h1>
                </div>

                <form action="" className="create-push-form" ref={formElement}>
                    <div className="toolbar">
                        <button className="button" onClick={onNotificationSettingsClick}>
                            Általános értesítések kezelése
                        </button>
                    </div>

                    <div className="container">
                        <div className="card-div">
                            {/* Értesítés részletei */}
                            <div className="page-container-div">
                                <div
                                    className="title-div"
                                    onClick={(e) => {
                                        window.scrollTo({ top: 0, left: 0 })

                                        setActiveSection(1)
                                    }}
                                >
                                    <div className="progress-div">
                                        <p className={`progress-number ${activeSection == 1 ? `active` : ``}`}>1.</p>
                                    </div>

                                    <div className="text-section-container">
                                        <h2 className="title-text-h2">Értesítés részletei*</h2>
                                    </div>
                                </div>

                                <div className="content-div">
                                    <div className="progress-div">
                                        <div className="progress-line" />
                                    </div>

                                    <div className={`text-section-container ${activeSection != 1 ? `hidden` : ``}`}>
                                        <div className="inner-container">
                                            <div className="content-action-div column-direction">
                                                <h3 className="title-text-h3 title-text-bottom-padding">Értesítés címe*</h3>
                                                <TextField field={form.title} maxlength={255} onChange={onTitleChange} placeholder="" category="title" />

                                                <h3 className="title-text-h3 title-text-top-padding">Értesítés leírása*</h3>
                                                <TextArea field={form.body} onChange={onBodyChange} placeholder="" category="lead" />

                                                <h3 className="title-text-h3 title-text-bottom-padding title-text-top-padding">Értesítés képe (1:1) (opcionális)</h3>

                                                <AddImage
                                                    text="Kép hozzáadása"
                                                    imageUrl={form.imageUrl}
                                                    width="120px"
                                                    height="120px"
                                                    onAddImage={() => {
                                                        setMediaLibraryVisibility(true)
                                                    }}
                                                    onDeleteImage={() => {
                                                        setMediaLibraryFile(null)
                                                        dispatch({
                                                            type: CREATE_PUSH_ACTIONS.CREATE_PUSH_CHANGE_MEDIA_LIBRARY_FILE,
                                                            mediaLibraryFile: null,
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="phone-notification-container">
                                                <img className="phone-img" src="/image/phone_notification.png" />

                                                <label className="name-tag-description">A felhasználó keresztnevének beillesztéséhez írd be a =NAME* szöveget, aki kiküldéskor a felhasználó keresztnevére változik.</label>

                                                <button className="button" onClick={onCopyNameTagClick}>
                                                    Keresztnév tag másolása
                                                </button>
                                            </div>
                                        </div>

                                        <div className="content-action-div ">
                                            <div className="content-action-id-div">
                                                <h3 className="title-text-h3  title-text-top-padding">Célhely magadása</h3>

                                                <Dropdown value={form.contentAction.categoryId} items={contentCategoryElements} onChange={onContentCategoryIdChange} compact={false} />
                                            </div>

                                            <div className="page-container-div">
                                                <h3 className="title-text-h3  title-text-top-padding">ID/Link</h3>

                                                <div style={{ display: contentUrlVisibility ? 'none' : 'flex' }}>
                                                    <Dropdown value={form.contentAction.actionId} items={contentActionElements} onChange={onContentActionIdChange} compact={false} />

                                                    <div style={{ display: contentIdVisibility ? 'block' : 'none', marginLeft: '8px' }}>
                                                        <Dropdown value={form.contentAction.contentId} items={contentContnetElements} onChange={onContentIdChange} compact={false} />
                                                    </div>
                                                </div>

                                                <div style={{ display: contentUrlVisibility ? 'block' : 'none', marginTop: '14.5px' }}>
                                                    <TextField field={form.contentAction.contentUrl} maxlength={255} onChange={onContenUrlFieldChange} placeholder="" category="question" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Célzás */}
                            <div className="page-container-div">
                                <div
                                    className="title-div"
                                    onClick={(e) => {
                                        window.scrollTo({ top: 0, left: 0 })

                                        setActiveSection(2)
                                    }}
                                >
                                    <div className="progress-div">
                                        <p className={`progress-number ${activeSection == 2 ? `active` : ``}`}>2.</p>
                                    </div>

                                    <div className="text-section-container">
                                        <h2 className="title-text-h2">Célzás</h2>
                                    </div>
                                </div>

                                <div className="content-div">
                                    <div className="progress-div">
                                        <div className="progress-line" />
                                    </div>

                                    <div className={`text-section-container ${activeSection != 2 ? `hidden` : ``}`}>
                                        <div className="">
                                            <TargetTable targetArray={form.targets || []} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Időzítés */}
                            <div className="page-container-div">
                                <div
                                    className="title-div"
                                    onClick={(e) => {
                                        window.scrollTo({ top: 0, left: 0 })

                                        setActiveSection(3)
                                    }}
                                >
                                    <div className="progress-div">
                                        <p className={`progress-number ${activeSection == 3 ? `active` : ``}`}>3.</p>
                                    </div>

                                    <div className="text-section-container">
                                        <h2 className="title-text-h2">Időzítés*</h2>
                                    </div>
                                </div>

                                <div className="content-div">
                                    <div className="progress-div zero-height"></div>

                                    <div className={`text-section-container ${activeSection != 3 ? `hidden` : ``}`}>
                                        <div className="check-box-group">
                                            <div>
                                                <input checked={!form.immediate} type="radio" id="status_active" name="status" value={0} onChange={onImmediateChange} />
                                                <label className="label-text" htmlFor="status_active">
                                                    Időzítés
                                                </label>

                                                <input checked={form.immediate} type="radio" id="status_draft" name="status" value={1} onChange={onImmediateChange} />
                                                <label className="label-text" htmlFor="status_draft">
                                                    Most azonnal
                                                </label>
                                            </div>
                                        </div>

                                        <div className={`card-sub-div-right-margin ${form.immediate ? `hidden` : ``}`}>
                                            <DatePicker
                                                selected={form.timing != null ? new Date(form.timing) : null}
                                                onChange={onTiminghange}
                                                showTimeSelect
                                                timeIntervals={1}
                                                minDate={new Date()}
                                                minTime={
                                                    form.timing != null && new Date(form.timing).getDate() == new Date().getDate()
                                                        ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())
                                                        : new Date(new Date(form.timing != null ? form.timing : '').getFullYear(), new Date(form.timing != null ? form.timing : '').getMonth(), new Date(form.timing != null ? form.timing : '').getDate(), 0, 0)
                                                }
                                                maxTime={new Date(new Date(form.timing != null ? form.timing : '').getFullYear(), new Date(form.timing != null ? form.timing : '').getMonth(), new Date(form.timing != null ? form.timing : '').getDate(), 23, 59)}
                                                timeFormat="HH:mm"
                                                dateFormat="yyyy. MM. dd. HH:mm"
                                            />
                                        </div>

                                        <div className="button-div">
                                            <button className="button" onClick={onSendClick}>
                                                Mentés
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <PushMessageTable />
            </div>

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false)
                    }}
                />
            ) : null}

            {mediaLibraryVisibility ? (
                <MediaLibrary
                    onClose={() => setMediaLibraryVisibility(false)}
                    onMediaSelected={(file: MediaLibraryFile) => {
                        setMediaLibraryFile(file)
                        setMediaLibraryVisibility(false)
                        setImageEditorVisibility(true)
                    }}
                />
            ) : null}

            {settingsModalVisibility ? (
                <PushMessageSettingsModal
                    onCloseClick={() => {
                        setSettingsModalVisibility(false)
                    }}
                />
            ) : null}
        </main>
    )
}
