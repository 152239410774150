import { ApplicationVersionsState } from '../state'
import { message } from '../helper'

const initialEventsState: ApplicationVersionsState = {
    applicationVersion: null,
    applicationVersions: [],
    orderBy: `created`,
    orderDirection: `DESC`,
    pagination: {
        page: 0,
        from: 0,
        to: 0,
        count: 0
    }
}

export const applicationVersionsReducer = ( state: ApplicationVersionsState = initialEventsState, action: any ): ApplicationVersionsState => {

    switch ( action.type ) {

        case `LIST_APPLICATION_VERSIONS_OK`:
            return { ...state, applicationVersions: action.applicationVersions, pagination: action.pagination }

        case `APPLICATION_VERSIONS_CHANGE_ORDER`:
            return { ...state, orderBy: action.by, orderDirection: action.direction }

        case `SELECT_APPLICATION_VERSION`:
            let applicationVersion = null
            const filtered = state.applicationVersions.filter( av => av.id === action.applicationVersionId )
            if ( filtered.length === 1 ) {
                applicationVersion = filtered[ 0 ]
            }
            return { ...state, applicationVersion: applicationVersion }

        case `NEW_APPLICATION_VERSION`:
            const newApplicationVersion = { id: null, version: ``, critical: false, platform: 1 }
            return { ...state, applicationVersion: newApplicationVersion }

        case `DELETE_APPLICATION_VERSION_OK`:
            message( `Az alkalmazás verzió sikeresen törlésre került!` )
            return state

        case `SAVE_APPLICATION_VERSION_OK`:
            message( `Az alkalmazás verzió sikeresen létrehozásra került!` )
            return state


        default:
            return state

    }
}
