import { Modal } from '../modal'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { EmailField, TextField } from '../field'
import { saveUser } from '../../action'
import './user-modal.scss'
import { Dropdown } from '../dropdown'
import { counties, positions } from '../../helper'

interface UserModalProps {

    onClose?: () => void

}

export const UserModal = ( props: UserModalProps ) => {

    const dispatch = useDispatch()
    const { user, userForm } = useSelector( ( state: State ) => state.users )

    const onClose = () => {

        if ( props.onClose ) props.onClose()

    }

    const onSaveButtonClicked = () => {

        const userData: any = {}
        Object.keys( userForm ).forEach( key => {
            switch ( key ) {

                case 'birthdate':
                    userData[ key ] = new Date( userForm[ key ].value ).getTime() / 1000
                    break

                default:
                    userData[ key ] = userForm[ key ].value

            }

        } )

        saveUser( userData, user?.membershipId )

    }

    const updateUserForm = ( field: string, value: any ) => {

        dispatch( { type: 'UPDATE_USER_FORM', field: field, value: value } )

    }

    return (

        <Modal onModalClose={ onClose }>

            <div className="user-modal">

                <div className="user-modal__title">

                    { user?.lastName } { user?.firstName }

                </div>

                <form className="user-modal__form">

                    <div className="form-field">

                        <div className="form-field__caption">

                            Név

                        </div>

                        <div className="form-field__field">

                            <TextField field={ userForm.lastName } onChange={ e => updateUserForm( `lastName`, e.target.value ) } placeholder="Vezetéknév" type="small" />
                            <TextField field={ userForm.firstName } onChange={ e => updateUserForm( `firstName`, e.target.value ) } placeholder="Keresztnév" type="small" />

                        </div>

                    </div>

                    <div className="form-field form-field--disabled">

                        <div className="form-field__caption">

                            E-mail

                        </div>

                        <div className="form-field__field">

                            <EmailField field={ userForm.email } onChange={ e => updateUserForm( `email`, e.target.value ) } placeholder="E-mail cím" type="small" />

                        </div>

                    </div>

                    <div className="form-field form-field--disabled">

                        <div className="form-field__caption">

                            Tagsági szám

                        </div>

                        <div className="form-field__field">

                            <TextField field={ userForm.membershipId } onChange={ e => updateUserForm( `membershipId`, e.target.value ) } placeholder="Tagsági szám" type="small" />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Születési dátum

                        </div>

                        <div className="form-field__field">

                            <TextField field={ userForm.birthdate } onChange={ e => updateUserForm( `birthdate`, e.target.value ) } placeholder="Születési dátum" type="small" />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Nem

                        </div>

                        <div className="form-field__field">

                            <Dropdown value={ userForm.gender ? userForm.gender.value : 0 } items={ [ { id: 0, value: 'Nincs megadva' }, { id: 1, value: 'Férfi' }, { id: 2, value: 'Nő' } ] } onChange={ ( id: number ) => updateUserForm( `gender`, id ) } compact={ true } />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Telefonszám

                        </div>

                        <div className="form-field__field">

                            <TextField field={ userForm.phone } onChange={ e => updateUserForm( `phone`, e.target.value ) } placeholder="Telefonszám" type="small" />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Cím

                        </div>

                        <div className="form-field__field">

                            <TextField field={ userForm.country } onChange={ e => updateUserForm( `country`, e.target.value ) } placeholder="Ország" type="small" />

                            <Dropdown value={ userForm.county ? userForm.county.value : 0 } items={ counties() } onChange={ ( id: number ) => updateUserForm( `county`, id ) }  compact={ true } />

                            <TextField field={ userForm.city } onChange={ e => updateUserForm( `city`, e.target.value ) } placeholder="Város" type="small" />

                            <TextField field={ userForm.zipcode } onChange={ e => updateUserForm( `zipcode`, e.target.value ) } placeholder="Irányítószám" type="small" />

                        </div>

                    </div>

                    <div className="form-field">

                        <div className="form-field__caption">

                            Pozíció

                        </div>

                        <div className="form-field__field">

                            <Dropdown disabled={ true } value={ userForm.position ? userForm.position.value : 0 } items={ positions() } onChange={ ( id: number ) => updateUserForm( `position`, id ) } compact={ true } />

                        </div>

                    </div>

                </form>

                <footer className="user-modal__footer">

                    <button className="button" onClick={ onSaveButtonClicked }>

                        Mentés

                    </button>

                </footer>

            </div>

        </Modal>
    )
}
