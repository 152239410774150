import { listQuestGroups } from '../../../action/gamification'
import { listQuests } from '../../../action/gamification/list-quests.action'
import { LoaderFunctionArgs } from 'react-router-dom'

export const questsPageLoader = async ( args: LoaderFunctionArgs ) => {

    await listQuestGroups( parseInt( args.params.id as string ) )
    await listQuests( parseInt( args.params.id as string ) )

}
