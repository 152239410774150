import { MouseEvent } from 'react'
import './table.scss'

type HeaderRenderFunction = () => string | JSX.Element | JSX.Element[]
type OrderChangeFunction = ( column: string ) => void
type RenderFunction = ( row: any, column: TableColumnProps ) => string | JSX.Element | JSX.Element[]
type RowClickFunction = ( row: any ) => void
type PageChangeFunction = ( page: number ) => void

interface TableColumnProps {
    align?: string
    canOrder?: boolean,
    caption?: string
    headerRender?: HeaderRenderFunction
    name?: string
    key?: string
    render?: RenderFunction
    width?: number
}

interface TableProps {
    className?: string
    columns: TableColumnProps[]
    dataset: any[]
    onOrderChange?: OrderChangeFunction
    onRowClick?: RowClickFunction
    order?: { by: string, direction: 'ASC' | 'DESC' }
    pagination?: { page: number, from: number, to: number, count: number, onPageChange: PageChangeFunction }
    type?: 'simple-header'
}

export const Table = ( props: TableProps ) => {

    const rowClickHandler = ( event: MouseEvent, row: any ) => {

        const target = event.target as HTMLElement

        if ( target.className.indexOf( `table__body__column` ) === -1 && target.className.indexOf( `table__body__row` ) === -1 ) {

            return

        }

        if ( props.onRowClick !== undefined ) {

            props.onRowClick( row )

        }

    }

    return (
        <div className={ `table${ props.type === 'simple-header' ? ` table--simple-header` : `` }${ props.className !== undefined ? ` ${ props.className }` : `` }` }>
            <div className="table__header">
                { props.columns?.map( ( column, index ) => (
                    <div key={ index } className={ `table__header__column table__header__column--align-${ column.align }` } style={ { flexBasis: column.width, maxWidth: column.width === undefined ? `unset` : column.width } } onClick={ e => { if ( !(e.target as HTMLDivElement).classList.contains( `table__header__column` ) ) { return } return props.onOrderChange && column.canOrder !== false ? props.onOrderChange(column.name!) : null } }>
                        { column.caption !== undefined ? column.caption : null }
                        { column.headerRender !== undefined ? column.headerRender() : null }
                        <span className="order">
                            { props.order?.by === column.name ? (
                                <span className="material-symbols-outlined">{ props.order?.direction === 'ASC' ? `expand_more` : `expand_less` }</span>
                            ) : null }
                        </span>
                    </div>
                ) ) }
            </div>
            <div className="table__body">
                { props.dataset?.length === 0 ? <div className="table__body__empty">Nincs megjeleníthető  elem</div> : null }
                { props.dataset?.map( ( row, index ) => (
                    <div key={ index } className="table__body__row" style={ { cursor: props.onRowClick === undefined ? `unset` : `pointer` } } onClick={ event => rowClickHandler( event, row ) }>
                        { props.columns?.map( ( column, index ) => (
                            <div key={ index } className={ `table__body__column table__body__column--align-${ column.align }` } style={ { flexBasis: column.width, maxWidth: column.width === undefined ? `unset` : column.width } }>
                                { column.key !== undefined ? row[ column.key ] : null }
                                { column.render !== undefined ? column.render( row, column ) : null }
                            </div>
                        ) ) }
                    </div>
                ) ) }
            </div>
            <div className="table__pagination">
                <button disabled={ ( props.pagination?.from ?? 1 ) === 1 } onClick={ e => props.pagination?.onPageChange( props.pagination?.page - 1 ) }><img src="/image/chevron-left.svg" /></button>
                <span>{ props.pagination?.from ?? 1 } - { props.pagination?.to ?? props.dataset?.length } / { props.pagination?.count ?? props.dataset?.length }</span>
                <button disabled={ ( props.pagination?.to ?? props.dataset?.length ) === ( props.pagination?.count ?? props.dataset?.length ) } onClick={ e => props.pagination?.onPageChange( props.pagination?.page + 1 ) }><img src="/image/chevron-right.svg" /></button>
            </div>
        </div>
    )
}
