import './league-card.scss'
import { NavLink } from 'react-router-dom'

interface LeagueCardProps {

    id: number
    icon: string
    name: string
    quests: number
    users: number
    wide?: boolean

}

export const LeagueCard = ( props: LeagueCardProps ) => {

    return (

        <div className={ `card-div league league-card${ props.wide === true ? ` league-card--wide` : `` }` }>

            <div className="league-icon">

                <img alt={ props.name } src={ props.icon } />

            </div>

            <div className="league-info">

                <div className="league-name">

                    <NavLink to={ `/ligak/${ props.id }/statisztika` }>

                        { props.name }

                    </NavLink>

                </div>

                <div className="league__details">

                    { props.quests } kihívás • { props.users } résztvevő

                </div>

            </div>

            <div className="league-actions">

                <NavLink to={ `/ligak/${ props.id }/statisztika` }>

                    <img src="/image/leagues/actions/0001.svg" />

                </NavLink>

                <NavLink to={ `/ligak/${ props.id }/kihivasok` }>

                    <img src="/image/leagues/actions/0002.svg" />

                </NavLink>

                <NavLink to={ `/ligak/${ props.id }/ranglista` }>

                    <img src="/image/leagues/actions/0003.svg" />

                </NavLink>

                { props.id !== 1 ? (

                    <NavLink to={ `/ligak/${ props.id }/nyeremenyek` }>

                        <img src="/image/leagues/actions/0004.svg" />

                    </NavLink>

                ) : null }

                <NavLink to={ `/ligak/${ props.id }/push-uzenetek` }>

                    <img src="/image/leagues/actions/0005.svg" />

                </NavLink>

            </div>

        </div>

    )

}