import { Table } from '../table'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import './courses-table.scss'
import { CourseTopic } from '../course-topic'
import { createFormattedDateTimeString } from '../../helper'
import { MenuButton } from '../button'
import { Dropdown } from '../dropdown'
import { listCourses } from '../../action/courses/list-courses.action'
import { useNavigate } from 'react-router-dom'
import { deleteCourse, editCourse } from '../../action/courses'
import React, { useState } from 'react'
import { Modal } from '../modal'

export const CoursesTable = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { courses, pagination, order } = useSelector( ( state: State ) => state.courses )

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onPageChange = async ( page: number ) => {

        await listCourses( page )

    }

    const onOrderChange = async ( column: string ) => {

        dispatch( { type: `COURSE_ORDER_CHANGE`, by: column, direction: ( order.by === column && order.direction === `ASC` ) ? `DESC` : `ASC` } )
        await listCourses( pagination.page )

    }

    const onMenuSelect = async ( action: number, id: number ) => {

        switch ( action ) {

            case 3:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteCourse( id )
                        await listCourses( pagination.page )
                    }
                } )
                setShowConfirmModal( true )
                break

            case 2:
                await onArchiveChange( id )
                break

            default:
                navigate( `/kepzesek/${ id }` )

        }

    }

    const onRowClick = ( course: any ) => {

        navigate( `/kepzesek/${ course.id }` )

    }

    const onStatusChange = async ( id: number, status: number ) => {

        await editCourse( id, { isDraft: status === 1 } )
        await listCourses( pagination.page )

    }

    const onArchiveChange = async ( id: number ) => {

        await editCourse( id, { endDate: Math.round( new Date().getTime() / 1000 ) } )
        await listCourses( pagination.page )

    }

    return (

        <>

            <Table className="courses-table" columns={ [
                {
                    align: `center`,
                    name: `id`,
                    headerRender: () => `ID`,
                    render: course => course.id,
                    width: 100
                },
                {
                    align: `left`,
                    name: `title`,
                    headerRender: () => ``,
                    render: course => course.title,
                    width: 300
                },
                {
                    align: `center`,
                    name: `answers`,
                    headerRender: () => `Kitöltve`,
                    render: course => course.answers,
                    width: 100
                },
                {
                    align: `center`,
                    name: `topic_name`,
                    headerRender: () => `Téma`,
                    render: course => <CourseTopic color={ course.topic_color } name={ course.topic_name } />,
                    width: 150
                },
                {
                    align: `center`,
                    name: `startDate`,
                    headerRender: () => `Publikálás`,
                    render: course => createFormattedDateTimeString( course.startDate ),
                    width: 200
                },
                {
                    align: `center`,
                    name: `endDate`,
                    headerRender: () => `Archiválás`,
                    render: course => course.endDate ? createFormattedDateTimeString( course.endDate ) : ``,
                    width: 200
                },
                {
                    align: `center`,
                    canOrder: false,
                    name: `status`,
                    headerRender: () => ``,
                    render: course => <Dropdown compact={ true } items={ [
                        {
                            id: 0,
                            value: `Aktív`
                        },
                        {
                            id: 1,
                            value: `Vázlat`
                        }
                    ] } onChange={ ( status: number ) => onStatusChange( course.id, status ) } value={ course.isDraft } />,
                    width: 150
                },
                {
                    canOrder: false,
                    name: `actions`,
                    headerRender: () => ``,
                    render: course => <MenuButton hasBackground={ true } items={ [
                        {
                            id: 0,
                            value: `Szerkesztés`,
                            icon: `edit.svg`
                        },
                        {
                            id: 1,
                            value: `Megtekintés`,
                            icon: `eye.svg`
                        },
                        {
                            id: 2,
                            value: `Archiválás`,
                            icon: `folder-times.svg`
                        },
                        {
                            id: 3,
                            value: `Törlés`,
                            icon: `trash.svg`
                        }
                    ] } onChange={ ( action: number ) => onMenuSelect( action, course.id ) } />,
                    width: 100
                }
            ] } dataset={ courses } order={ order } onRowClick={ onRowClick } pagination={ { ...pagination, onPageChange } } onOrderChange={ onOrderChange } />

            { showConfirmModal ? (

                <Modal className="application-version-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a képzést?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}