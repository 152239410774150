import './course.page.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { TagSelector } from '../../../component/tag-selector'
import DatePicker from 'react-datepicker'
import hu from 'date-fns/locale/hu'
import { TextField } from '../../../component/field'
import { Dropdown } from '../../../component'
import { AddImage } from '../../../component/add-image'
import { MouseEvent, useState } from 'react'
import { MediaLibrary } from '../../../component/media-library'
import { MediaLibraryFile } from '../../../entity'
import { ImageEditor } from '../../../component/image-editor/image-editor'
import { TextArea } from '../../../component/textarea'
import { DescriptionTextArea } from '../../../component/textarea/description'
import { PoliticianModal } from '../../../component/politicians'
import { saveCourse } from '../../../action/courses'
import { MessageModal } from '../../../component/modal'


export const Course = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { course, courseTopics } = useSelector( ( state: State ) => state.courses )

    const [ showMediaLibrary, setShowMediaLibrary ] = useState( false )
    const [ showImageEditor, setShowImageEditor ] = useState( false )
    const [ file, setFile ] = useState( null as MediaLibraryFile | null )

    const [ showPoliticianModal, setShowPoliticianModal ] = useState( false )

    const [ imageType, setImageType ] = useState( null as string | null )

    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const checkFields = (): boolean => {

        if ( course!.endDate === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `Az elrejtés időpontjának megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( course!.image === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A képzés képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! course!.title || course!.title.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A képzés címének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! course!.lead || course!.lead.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A képzés lead szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! course!.description || course!.description.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A képzés szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( course!.politician === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A házigazda kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! course!.award.name || course!.award.name.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés megnevezésének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( course!.award.bronzeImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés bronz képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( course!.award.silverImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés ezüst képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( course!.award.goldImage === null ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A kitüntetés arany képének kiválasztása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        return true

    }

    const onAddImage = ( image: string ) => {

        setImageType( image )
        setShowMediaLibrary( true )

    }

    const onDeleteImage = ( image: string ) => {

        switch ( image ) {

            case `bronzeImage`:
            case `silverImage`:
            case `goldImage`:
                uA( image, null )
                break

            default:
                u( `image`, null )

        }

    }

    const onImageSelected = ( file: MediaLibraryFile ) => {

        switch ( imageType ) {

            case `bronzeImage`:
            case `silverImage`:
            case `goldImage`:
                uA( imageType, file )
                break

            default:
                u( `image`, file )
                setShowImageEditor( false )

        }

        setShowImageEditor( false )

    }

    const u = ( field: string, value: any ) => {

        switch ( field ) {

            case `experience`:
                if ( isNaN( parseInt( value ) ) ) value = 25
                if ( parseInt( value ) < 1 ) value = 1
                if ( parseInt( value ) > 50 ) value = 50
                break

            case `restartPrice`:
                if ( isNaN( parseInt( value ) ) ) value = 2500
                if ( parseInt( value ) < 2000 ) value = 2000
                if ( parseInt( value ) > 3000 ) value = 3000
                break

        }

        dispatch( { type: `UPDATE_COURSE`, field: field, value: value } )

    }

    const uA = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_COURSE_AWARD`, field: field, value: value } )

    }

    const onSave = async () => {

        if ( !checkFields() ) return
        const c = await saveCourse( course )
        if ( c !== null ) {
            navigate( `/kepzesek/${ c.id }` )
        }

    }

    return (

        <main className="page page--course">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/kepzesek">

                        <h1 className="title-text-h1">Képzések &gt;</h1>

                    </NavLink>

                    <NavLink to="/kepzesek/lista">

                        <h1 className="title-text-h1">Képzések lista &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">{ course!.id === undefined ? `Új képzés` : `Képzés szerkesztése` }</h1>

                </div>

                <div className="toolbar">

                    <NavLink to="/kepzesek/lista">

                        <button className="button button--ghost">Vissza</button>

                    </NavLink>

                    <button className="button button--primary" onClick={ onSave }>Mentés</button>

                </div>

                <div className="grid">

                    <div className="card-div card-div--00">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Megjelenés időpontja *</div>
                                <DatePicker
                                    selected={ new Date( course!.startDate * 1000 ) }
                                    onChange={ ( date ) => u( `startDate`, date ? Math.round( date.getTime() / 1000 ) : null ) }
                                    showTimeSelect
                                    timeIntervals={ 1 }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MMMM dd. - HH:mm"
                                    locale={ hu } />

                            </div>

                            <div className="col">

                                <div className="caption">Elrejtés időpontja *</div>
                                <DatePicker
                                    selected={ course!.endDate === null ? null : new Date( course!.endDate * 1000 ) }
                                    onChange={ ( date ) => u( `endDate`, date ? Math.round( date.getTime() / 1000 ) : null ) }
                                    showTimeSelect
                                    timeIntervals={ 1 }
                                    minDate={ new Date( course!.startDate * 1000 ) }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MMMM dd. - HH:mm"
                                    locale={ hu } />

                            </div>

                            <div className="col">

                                <div className="caption">Státusz *</div>
                                <div className="options">

                                    <div className="option">

                                        <input checked={ course!.isDraft } type="radio" value={ `true` } name="isDraft" onClick={ e => u( `isDraft`, true ) } id="isDraftTrue" />
                                        <label htmlFor="isDraftTrue">Vázlat</label>

                                    </div>

                                    <div className="option">

                                        <input checked={ !course!.isDraft } type="radio" name="isDraft" value={ `false` } onClick={ e => u( `isDraft`, false ) } id="isDraftFalse" />
                                        <label htmlFor="isDraftFalse">Aktív</label>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--01">

                        <TagSelector tags={ course!.tags } onTagAdd={ tag => dispatch( { type: `COURSE_ADD_TAG`, tag } ) } onTagDelete={ tag => dispatch( { type: `COURSE_REMOVE_TAG`, tag } ) } />

                    </div>

                    <div className="card-div card-div--02">

                        <div className="cols">

                            <div className="col">

                                <img alt="" src="/image/exp.svg" width={ 87 } />

                            </div>

                            <div className="col">

                                <div className="caption">Újrakezdés összege (2000 - 3000 TP) *</div>
                                <TextField field={ { value: course!.restartPrice.toString(), error: `` } } onChange={ e => u( `restartPrice`, e.target.value ) } />

                            </div>

                            <div className="col">

                                <div className="caption">Tapasztalati pont a jó válaszért (1-50 TP) *</div>
                                <TextField field={ { value: course!.experience.toString(), error: `` } } onChange={ e => u( `experience`, e.target.value ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--03">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Téma kiválasztása *</div>
                                <Dropdown value={ course!.courseTopic === null ? null : course!.courseTopic.id } items={ courseTopics.map( courseTopic => ( { id: courseTopic.id, value: courseTopic.name } ) ) } onChange={ ( id: number ) => { u( `courseTopic`, courseTopics.find( t => t.id === id ) ) } } compact={ false } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--04">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Kiemelt képzés</div>
                                <button className={ `button ${ course!.isHighlighted ? `button--primary` : `button--ghost` }` } onClick={ () => u( `isHighlighted`, !course!.isHighlighted ) }>{ course!.isHighlighted ? `Képzés kiemelve` : `Képzés kiemelése` }</button>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--05">

                        <div className="caption">Képzések tartalmi elemeinek megadása</div>

                        <div className="cols">

                            <div className="col course-image">

                                <div className="caption">Kép hozzáadása * (16:9)</div>
                                <AddImage imageUrl={ course!.image === null ? `` : course!.image.url! } text="Kép hozzáadása" width="215px" height="121px" onAddImage={ () => onAddImage( `image` ) } onDeleteImage={ () => onDeleteImage( `image` ) } />

                            </div>

                            <div className="col course-title">

                                <div className="caption">A képzés címe *</div>
                                <div className="info">Ajánlott karakter szám maximum: 120 karakter</div>
                                <TextField field={ { value: course!.title, error: `` } } onChange={ e => u( `title`, e.target.value ) } />

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Képzés lead szöveg megadása *</div>
                                <TextArea field={ { value: course!.lead, error: `` } } onChange={ e => u( `lead`, e.target.value ) } />

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Képzés szövegének megadása *</div>
                                <DescriptionTextArea field={ { value: course!.description, error: `` } } onChange={ html => u( `description`, html ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--06">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Házigazda *</div>

                                <div className="politician-selector">

                                    <div className="politician-image">

                                        { course!.politician ? (

                                            <img alt="" src={ course!.politician.imageFile!.url } />

                                        ) : null}

                                    </div>

                                    <div className="politician-data">

                                        <div className="politician-name">

                                            { course!.politician?.lastName } { course!.politician?.firstName }

                                        </div>

                                        <button className="button button--ghost" onClick={ () => setShowPoliticianModal( true ) }>

                                            Szerkesztés

                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--07">

                        <div className="cols">

                            <div className="col award-name">

                                <div className="caption">Kitüntetések</div>

                                <div className="caption caption--small">Kitüntetés megnevezése *</div>
                                <div className="info">Ez a megnevezés fog megjelenni a  felhasználók profiljában és a képzések oldalán, bronz, ezüst és arany előtaggal</div>
                                <TextField field={ { value: course!.award.name, error: `` } } onChange={ e => uA( `name`, e.target.value ) } />

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col award-images"></div>

                            <div className="col bronze-award-image">

                                <div className="caption caption--small">Bronz * (.png)</div>
                                <AddImage imageUrl={ course!.award.bronzeImage ? course!.award.bronzeImage.url! : `` } text="Bronz kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `bronzeImage` ) } onDeleteImage={ () => onDeleteImage( `bronzeImage` ) } />

                            </div>

                            <div className="col silver-award-image">

                                <div className="caption caption--small">Ezüst * (.png)</div>
                                <AddImage imageUrl={ course!.award.silverImage ? course!.award.silverImage.url! : `` } text="Ezüst kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `silverImage` ) } onDeleteImage={ () => onDeleteImage( `silverImage` ) } />

                            </div>

                            <div className="col gold-award-image">

                                <div className="caption caption--small">Arany * (.png)</div>
                                <AddImage imageUrl={ course!.award.goldImage ? course!.award.goldImage.url! : `` } text="Arany kitüntetés" width="128px" height="128px" onAddImage={ () => onAddImage( `goldImage` ) } onDeleteImage={ () => onDeleteImage( `goldImage` ) } />

                            </div>

                        </div>

                        <div className="cols award-experiences">

                            <div className="col award-experience">

                                <img alt="" src="/image/exp.svg" />

                            </div>

                            <div className="col bronze-award-experience">

                                <TextField field={ { value: course!.award.bronzeExperience.toString(), error: `` } } onChange={ e => uA( `bronzeExperience`, e.target.value ) } />

                            </div>

                            <div className="col silver-award-experience">

                                <TextField field={ { value: course!.award.silverExperience.toString(), error: `` } } onChange={ e => uA( `silverExperience`, e.target.value ) } />

                            </div>

                            <div className="col gold-award-experience">

                                <TextField field={ { value: course!.award.goldExperience.toString(), error: `` } } onChange={ e => uA( `goldExperience`, e.target.value ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--08">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Leckék</div>

                                <div className="lessons">

                                    { course!.lessons.map( lesson => (

                                        <div className="lesson" key={ lesson.id }>

                                            <div className="lesson__title">

                                                <NavLink to={ `/kepzesek/${ course!.id }/leckek/${ lesson.id }` }>

                                                    { lesson.n }. lecke: { lesson.name }

                                                </NavLink>

                                            </div>

                                            <div className="lesson__info">

                                                { lesson.length } perc, { lesson.quiz === null ? 0 : lesson.quiz.questions.length } kérdés

                                            </div>

                                        </div>

                                    ) ) }

                                </div>

                            </div>

                        </div>

                    </div>

                    <footer>

                        { course!.id === undefined ? (

                            <>

                                Leckék hozzáadásához előbb mentse el a képzést!

                            </>

                        ) : (

                            <NavLink to={ `/kepzesek/${ course!.id }/leckek/uj` }>

                                <div className="new-lesson">

                                    <img alt="" src="/image/add.svg" />
                                    <span>Új lecke hozzáadása</span>

                                </div>

                            </NavLink>

                        ) }

                    </footer>

                </div>

            </div>

            { showMediaLibrary ? (

                <MediaLibrary onMediaSelected={ ( file: MediaLibraryFile ) => {

                    setFile( file )
                    setShowMediaLibrary( false )
                    setShowImageEditor( true )

                } } onClose={ () => setShowMediaLibrary( false ) } />

            ) : null }

            { showImageEditor ? (

                <ImageEditor aspectRatio={ imageType === `image` ? { name: `Képzés képe`, ratio: 16 / 9 } : { name: `Kitüntetés képe`, ratio: 1 / 1 } } mediaLibraryFile={ file! } onReady={ f => onImageSelected( f ) } onClose={ () => setShowImageEditor( false ) } />

            ) : null }

            { showPoliticianModal ? (

                <PoliticianModal onCloseClick={ () => {

                    setShowPoliticianModal( false )

                } } onModalClose={ () => setShowPoliticianModal( false ) } />

            ) : null }

            { infoModalVisibility ? (

                <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => setInfoModalVisibility( false ) } />

            ) : null }

        </main>

    )

}