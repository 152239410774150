import { Quest as QuestEntity } from '../../entity'
import './quest.scss'
import { TextField } from '../field'
import { Dropdown } from '../dropdown'
import { useState } from 'react'
import { TagSelector } from '../tag-selector'

interface QuestProps {

    quest: QuestEntity
    hideFooter?: boolean
    hideLeagues?: boolean
    onFieldChange?: ( field: string, value: any ) => void
    onDeleteQuest?: () => void

}

export const Quest = ( props: QuestProps ) => {

    const questConditions = [
        { id: 1, value: `Nyiss meg egy modult!` },
        { id: 3, value: `Tölts ki egy kérdőívet!` },
        { id: 4, value: `Legyél ott egy eseményen!` },
        { id: 5, value: `Olvass el egy hírt!` },
        { id: 6, value: `Nézd meg egy politikus oldalát!` },
        { id: 7, value: `Végezz a napi véleményvezérek játékkal!` }
    ]

    const moduleConditions = [
        { id: 0, value: `Hírek` },
        { id: 1, value: `Politikusok` },
        { id: 2, value: `Kérdőívek` },
        { id: 3, value: `Véleményvezérek` },
        { id: 4, value: `Események` },
    ]


    const onFieldChange = ( field: string, value: any ) => {

        if ( props.onFieldChange ) {
            if ( field === `contentId` && value !== null ) props.onFieldChange( `repeats`, 1 )
            props.onFieldChange( field, value )
        }

    }

    const onDeleteQuest = () => {

        if ( props.onDeleteQuest ) props.onDeleteQuest()

    }

    const [ selectedLeagues, setSelectedLeagues ] = useState( ( props.quest.leagues || [] ).map( l => l.id ) )

    const onLeagueClick = ( leagueId: number ) => {

        let newleagues = []

        if ( selectedLeagues.indexOf( leagueId ) !== -1 ) {

            newleagues = selectedLeagues.filter( l => l !== leagueId )

        } else {

            newleagues = selectedLeagues
            newleagues.push( leagueId )

        }

        setSelectedLeagues( [ ... newleagues ] )
        onFieldChange( `selectedLeagues`, newleagues )

    }

    const leagues = [
        { id: 2, name: `Bronz liga`, icon: `/image/leagues/0002.png` },
        { id: 3, name: `Ezüst liga`, icon: `/image/leagues/0003.png` },
        { id: 4, name: `Arany liga`, icon: `/image/leagues/0004.png` },
        { id: 5, name: `DK liga`, icon: `/image/leagues/0005.png` }
    ]

    return (

        <div className="quest">

            <div className="columns">

                <div className="field">

                    <label>Feladat megnevezése *</label>
                    <TextField field={{ value: props.quest.name, error: `` }} onChange={ e => onFieldChange( `name`, e.target.value ) } />

                </div>

                <div className="field">

                    <label></label>
                    <Dropdown value={ props.quest.type } onChange={ ( id: number ) => onFieldChange( `type`, id ) } compact={ true } items={ [ { id: 0, value: `Appon belüli` }, { id: 1, value: `Appon kívüli` } ] } />

                </div>

            </div>

            { props.quest.type === 0 ? (

                <>

                    <div className="field">

                        <label>Tevékenység meghatározása*</label>
                        <Dropdown value={ props.quest.questConditionId } onChange={ ( id: number ) =>  {
                            onFieldChange( `questConditionId`, id )
                            onFieldChange( `contentId`, props.quest.questConditionId === 1 ? 0 : "" )

                          } }  compact={ true } items={ questConditions } />

                    </div>

                    <div className="field" style={{ display: props.quest.questConditionId === 1 ? `block` : `none` }}>

                        <label>Modul megadása*</label>
                        <Dropdown value={ props.quest.contentId } onChange={ ( id: number ) => onFieldChange( `contentId`, id ) } compact={ true } items={ moduleConditions } />

                    </div>

                    <div className="field" style={{ display: props.quest.questConditionId === 1 ? `none` : `block` }}>

                        <label>Tartalom ID megadása</label>
                        <TextField field={{ value: props.quest.contentId?.toString(), error: `` }} onChange={ e => onFieldChange( `contentId`, isNaN( parseInt( e.target.value ) ) ? null : parseInt( e.target.value ) ) } />

                    </div>

                    <div className="field">

                        <label>Hányszor kell megismételnie a tevékenységet?*</label>
                        <TextField field={{ value: props.quest.repeats?.toString(), error: `` }} onChange={ e => onFieldChange( `repeats`, isNaN( parseInt( e.target.value ) ) ? 1 : parseInt( e.target.value ) ) } disabled={ props.quest.contentId !== null && ( props.quest as any ).contentId !== "" } />

                    </div>

                </>

            ) : (

                <div className="field">

                    <label>Külső hivatkozás *</label>
                    <TextField field={{ value: props.quest.url, error: `` }} onChange={ e => onFieldChange( `url`, e.target.value ) } />

                </div>

            ) }

            { props.hideFooter !== true ? (

                <footer>

                    <button className="button button--ghost" style={{minWidth: 226}}>PUSH küldése</button>
                    <button className="button button--ghost button--warning" style={{minWidth: 170}} onClick={ onDeleteQuest }>Küldetés törlése</button>

                </footer>

            ) : (

                <>

                    <div className="card-div experience-div">

                        <div className="field">

                            <div className="field">

                                <label>Tapasztalati pont értéke *</label>
                                <TextField field={{ value: props.quest.exp?.toString(), error: `` }} onChange={ e => onFieldChange( `exp`, isNaN( parseInt( e.target.value ) ) ? 0 : parseInt( e.target.value ) ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div tags-div">

                        <TagSelector tags={ props.quest.tags } onTagAdd={ tag => onFieldChange( `tags`, [ ...props.quest.tags, tag ] ) } onTagDelete={ tag => onFieldChange( `tags`, [ ...props.quest.tags.filter( t => t.id !== tag.id ) ] ) } />

                    </div>

                    { props.hideLeagues !== true ? (

                        <div className="league-selector">

                            <div className="field">

                                <label>Ligába elérhető</label>

                                <div className="leagues">

                                    { leagues.map( league => (

                                        <div className={ `league${ selectedLeagues.indexOf( league.id ) !== -1 ? ` league--selected` : `` }` } onClick={ e => onLeagueClick( league.id ) }>

                                            <div className="league__icon">

                                                <img src={ league.icon } />

                                            </div>

                                            <div className="league__name">

                                                { league.name }

                                            </div>

                                        </div>

                                    ) ) }

                                </div>

                            </div>

                        </div>

                    ) : null }

                </>

            ) }

        </div>

    )

}