import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { getEvents } from '../../action/events'
import { EventsTable } from '../../component/events'
import { SearchButton, SearchInput } from '../../component/search'
import { State } from '../../state'
import './events-list.page.scss'
import { Dropdown } from '../../component'

interface EventsListProps { }

export const EventsList = ( props: EventsListProps ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { politicians } = useSelector( ( state: State ) => state.politicians )
    const { search, showArchive, selectedPoliticianForSearch } = useSelector( ( state: State ) => state.events )

    const toggleShowArchive = () => {
        dispatch( { type: `EVENTS_TOGGLE_SHOW_ARCHIVE` } )
        getEvents()
    }

    const setSelectedPolitician = async ( id: number ) => {

        dispatch( { type: `SET_SELECTED_POLITICIAN_FOR_SEARCH`, selectedPolitician: id } )
        await getEvents()

    }

    return (

        <main className="page page--events-list">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/esemenyek">
                        <h1 className="title-text-h1">Események &gt;</h1>
                    </NavLink>
                    <h1 className="title-text-h1">{ !showArchive ? `Aktív` : `Archivált` } események</h1>

                </div>

                <div className="toolbar">

                    <SearchInput onSearch={ e => getEvents() } placeholder="Keresés az események között" onChange={ event => dispatch( { type: `EVENTS_UPDATE_SEARCH`, value: event.target.value } ) } />
                    <SearchButton onClick={ e => getEvents() } />
                    <NavLink to="/esemenyek/naptar">
                        <button className="button calendar-button">
                            <img src="/image/calendar.svg" />
                        </button>
                    </NavLink>
                    <button className="button new-button" onClick={ e => navigate( `/esemenyek/uj` ) }>+ Új esemény</button>

                </div>

                <div className="actions">

                    <button className="button" onClick={ e => toggleShowArchive() }>
                        { showArchive ? `Aktuális események` : `Archív események` }
                    </button>

                </div>

                <div className="filters">

                    <div className="politician-filter">

                        <Dropdown value={ selectedPoliticianForSearch } items={ [ { id: -1, value: `Bármelyik politikus` }, ... ( politicians || [] ).map( p => ( { id: p.id, value: `${ p.lastName } ${ p.firstName }` } ) ) ] } onChange={ setSelectedPolitician } compact={ false } />

                    </div>

                </div>

                <EventsTable />

            </div>

        </main>

    )

}



