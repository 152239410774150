import { Expose, Type } from 'class-transformer'
import { IsDefined, ValidateNested } from 'class-validator'
import { Questionnaire } from '../../entity'

export class GetQuestionnairesDTO {

    @Expose()
    @ValidateNested()
    @Type( () => Questionnaire )
    questionnaires!: Questionnaire[]

    @Expose()
    @IsDefined()
    pagination!: {
        page: number
        from: number
        to: number
        count: number
    }

}