import './statistics.page.scss'
import Chart from 'react-apexcharts'
import { TabView } from '../../component/tab-view'
import { TabViewPage } from '../../component/tab-view/tab-view-page'
import { State } from '../../state'
import { useDispatch, useSelector } from 'react-redux'
import { NewsStatisticsTab } from './component/news-statistics.tab'
import { EventsStatisticsTab } from './component/events-statistics.tab'
import { QuestionnairesStatisticsTab } from './component/questionnaires-statistics.tab'
import { QuotesStatisticsTab } from './component/quotes-statistics.tab'
import { getEventsStatistics, getNewsStatistics, getQuestionnairesStatistics, getQuotesStatistics, getStatistics } from '../../action/statistics'
import { GET_STATISTICS_ACTIONS } from '../../action/statistics/statistics.constants'
import  DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react'

interface StatisticsProps { }

export const Statistics = ( props: StatisticsProps ) => {

    const dispatch = useDispatch()

    const { dateRange, applicationLaunchedCount, applicationLaunchedXAxis, applicationLaunchedData, userCount,  usersChartXAxis, usersChartData, iOSCount, androidCount } = useSelector( ( state: State ) => state.statistics )

    useEffect( () => {
        let statDate = new Date();
        statDate.setHours( 0, 0, 0, 0 )

        let day = statDate.getDay() || 7         
        if( day !== 1 ) {                               
            statDate.setHours(-24 * (day - 1))    
        }                          

        let statDateCopy = new Date(statDate)
        let endDate = new Date(statDateCopy.setDate(statDateCopy.getDate() - 7))

        dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_SET_DATE_RANGE, dateRange: [statDate, endDate] } )
    }, [] )


    const lineOptions = {
        chart: {
            id: 'line-chart',    
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth" as "smooth",
        },
        colors: ['#0267AA'],
        xaxis: {
          categories: applicationLaunchedXAxis
        }
    }

    const lineSeries = [{
        name: 'Megnyitások száma',
        data: applicationLaunchedData
    }]


    const barOptions = {
        chart: {
          id: 'bar-chart'
        },
        dataLabels: {
            enabled: true
        },
        colors: ['#0267AA'],

        xaxis: {
          categories: usersChartXAxis
        }
    }

    const barSeries = [{
        name: 'Felhasználók száma',
        data: usersChartData
    }]
    

    const pieOptions = {
        chart: {
          id: 'donout-chart'
        },
        dataLabels: {
            enabled: true
        },
        colors: ['#0267AA', '#FFD201'],
        labels: ['iOS', 'Android'],
    }

    const pieSeries = [iOSCount, androidCount]


    const onDateRangeChange = async ( dates: [Date | null, Date | null], event: any ) => {

        var statDate = new Date(dates[0]?  dates[0] : new Date()) 
        statDate.setHours( 0, 0, 0, 0 )

        var statDateCopy = new Date(statDate)
        var endDate = new Date(statDateCopy.setDate(statDateCopy.getDate() + 6)) 
        endDate.setHours( 23, 59, 0, 0 )

        dispatch( { type: GET_STATISTICS_ACTIONS.GET_STATISTICS_SET_DATE_RANGE, dateRange: [statDate, endDate] } )

        await getStatistics( statDate, endDate )
        await getNewsStatistics( statDate, endDate)
        await getEventsStatistics( statDate, endDate)
        await getQuestionnairesStatistics( statDate, endDate)
        await getQuotesStatistics( statDate, endDate )
    }

    return (
        <main className="page page-statistics">

            <div className="main-div">

                <div className="navigation-title">
                    <h1 className="title-text-h1">Statisztikák</h1>
                </div>

                <div className="card-div container-top-margin">
                    <header>
                        <h2 className="title-text-h2">Alap statisztikai adatok</h2>
                    </header>

                    <div className="caption">
                        Az alap adatok itt jelennek meg a kiválasztott hét alapján:
                    </div>

                    <DatePicker
                        selectsRange = { true }
                        startDate ={  dateRange[0] }
                        endDate = { dateRange[1] }
                        selected = { dateRange[0] }
                        onChange = { onDateRangeChange }
                        isClearable = { false }
                        dateFormat = "yyyy. MM. dd."
                    />

                    <div className="container">
                        <div className="card-div card-div-right-margin">

                            <div className="container column">
                                <div className="info-slot">
                                    <div className="info-slot__description">
                                        <span className="caption"> Megnyitások száma </span>
                                        <span className="additional-info"> Hányan nyitották meg az appot </span>
                                    </div>

                                    <div className="info-slot__info">
                                        <span className="value">{ applicationLaunchedCount }</span> { "db" }
                                    </div>
                                </div>

                                <Chart options={lineOptions} series={lineSeries} type="area" height={275} />
                            </div>

                        </div>

                        <div className="card-div card-div-right-margin">

                            <div className="container column">
                                <div className="info-slot">
                                    <div className="info-slot__description">
                                        <span className="caption"> Felhasználók száma </span>
                                        <span className="additional-info"> Melyik napon hány felhasználó </span>
                                    </div>

                                    <div className="info-slot__info">
                                        <span className="value">{ userCount }</span> { "db" }
                                    </div>
                                </div>

                                <Chart options={barOptions} series={barSeries} type="bar" height={260} />
                            </div>

                        </div>

                        <div className="card-div">

                            <div className="container column">
                                <div className="info-slot">
                                    <div className="info-slot__description">
                                        <span className="caption"> Eszközök eloszlása </span>
                                        <span className="additional-info"> iOS - Anroid eszközök eloszlása </span>
                                    </div>
                                </div>

                                <Chart options={pieOptions} series={pieSeries} type="donut" />

                            </div>

                        </div>

                    </div>
                
                </div>

                <TabView tabs={ { news: 'Hírek', events: 'Események', questionnaires: 'Kérdőívek', quotes: 'Véleményvezérek'} }>

                    <TabViewPage key="news">
                        <NewsStatisticsTab />
                    </TabViewPage>

                    <TabViewPage key="events">
                        <EventsStatisticsTab />
                    </TabViewPage>

                    <TabViewPage key="questionnaires">
                        <QuestionnairesStatisticsTab />
                    </TabViewPage>          

                    <TabViewPage key="quotes">
                        <QuotesStatisticsTab />
                    </TabViewPage>          

                </TabView>

            </div>
            
        </main>
    )
}


