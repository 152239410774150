import { NEWS_ACTIONS } from '../../action/news/news.constants'
import { NewsState } from '../../state/news'

const initialNewsState: NewsState  = {
    news: undefined,

    selectedPolitician: -1,
    search: "",
    order: 'DESC',
    deleted: 0,

    pagination: {
        page: 1,
        from: 0,
        to: 0,
        count: 0
    }
}

export const newsReducer = ( state: NewsState = initialNewsState, action: any ): NewsState => {
    switch ( action.type ) {
        case NEWS_ACTIONS.GET_NEWS_OK:
            return { ...state, news: action.news, pagination: action.pagination}
        case NEWS_ACTIONS.GET_NEWS_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case NEWS_ACTIONS.GET_NEWS_INVALID_JSON:
            return { ...state, news: null }
        case NEWS_ACTIONS.GET_NEWS_INVALID_RESPONSE:
            return { ...state, news: null }
        case NEWS_ACTIONS.GET_NEWS_SEARCH:
            return { ...state,  search: action.search }
        case NEWS_ACTIONS.GET_NEWS_ORDER:
            return { ...state, order: state.order == 'ASC' ? 'DESC' : 'ASC'}
        case NEWS_ACTIONS.GET_NEWS_DELETED:
            return { ...state, search: "", deleted: state.deleted == 0 ? 1 : 0}
        case `NEWS_SET_SELECTED_POLITICIAN`:
            return { ...state, selectedPolitician: action.selectedPolitician }
        default:
            return state
    }
}
