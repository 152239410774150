import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnairesDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { GET_QUESTIONNAIRES_ACTIONS } from './questionnaires.constants'

export const getQuestionnaires= async () => {

    const page = store.getState().questionnaires.pagination.page
    const search = store.getState().questionnaires.search
    const order = store.getState().questionnaires.order
    const deleted = store.getState().questionnaires.deleted

    const promise = createCall(`questionnaires?page=`+page+`&search=`+search+`&order=`+order+`&deleted=`+deleted, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetQuestionnairesDTO

        try {
            body = plainToClass( GetQuestionnairesDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: GET_QUESTIONNAIRES_ACTIONS.GET_QUESTIONNAIRES_OK, questionnaires: body.questionnaires, pagination: body.pagination } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
