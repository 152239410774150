import { QuestionnaireQuestion, QuestionnaireQuestionAnswer, QuestionnaireQuestionUserAnswer } from '../../../../entity'
import './questionnaire-user-answer-element.scss'
import { QuestionnaireUserAnswerAnswerElement } from './questionnaire-user-answer-answer-element'
import { UserTextAnswerElement } from './user-text-answer-element'
import { UserImageAnswerElement } from './user-image-answer-element'

interface QuestionnaireUserAnswerElementProps {
    questionElement: QuestionnaireQuestion
    disabled?: boolean
}

export const QuestionnaireUserAnswerElement = ( props: QuestionnaireUserAnswerElementProps ) => {

    let hashMap = new Map()
    let scale = 0
    let scaleCount = 0
    let scaleAvg = 0

    if(props.questionElement.userAnswers != null){
        props.questionElement.userAnswers.map(( userAnswerElement: QuestionnaireQuestionUserAnswer  ) => {
            if(hashMap.has(userAnswerElement.questionId)){
                hashMap.set(userAnswerElement.questionId, hashMap.get(userAnswerElement.questionId)+1)
            }else {
                hashMap.set(userAnswerElement.questionId, 1)
            }
        })

        props.questionElement.userAnswers.map(( userAnswerElement: QuestionnaireQuestionUserAnswer  ) => {

            if(props.questionElement.answers != null){
                props.questionElement.answers.map(( answerElement: QuestionnaireQuestionAnswer  ) => {

                    answerElement.allAnswerCount = hashMap.get(userAnswerElement.questionId)

                    if(userAnswerElement.answerId === answerElement.id){
               
                        if(answerElement.userAnswers == null){
                            answerElement.userAnswers = []
                        }
        
                        let index = answerElement.userAnswers.findIndex(x => x.id==userAnswerElement.id)
                        if(index === -1){
                            answerElement.userAnswers.push(userAnswerElement)
                        }
                    }

                })
            }
        })
    }

    const questionElements: JSX.Element[] = []
    if(props.questionElement.answers != null){
        props.questionElement.answers.map(( answerElement: QuestionnaireQuestionAnswer, index  ) => {
            questionElements.push( <QuestionnaireUserAnswerAnswerElement answerElement={ answerElement } position={ index+1 }  /> )
        })
    }

    const answerElements: JSX.Element[] = []
    if(props.questionElement.userAnswers != null){
        props.questionElement.userAnswers.map(( userAnswerElement: QuestionnaireQuestionUserAnswer ) => {
            if(userAnswerElement.answer != null && userAnswerElement.answer != ""){
                answerElements.push( <UserTextAnswerElement answerElement={ userAnswerElement }  /> )
            }

            if(userAnswerElement.scale != null && userAnswerElement.scale != undefined){
                scale += userAnswerElement.scale
                scaleCount += 1

                scaleAvg = scale/scaleCount
            }
        })
    }

    return (
        <li key={ props.questionElement.id } className="questionnaire-user-answer-table__questionnaire-user-answer-element" >

            <div className="questionnaire-user-answer-element-indicator"/>

            <div className="questionnaire-user-answer-element-container">

                <div className="text-section-container">

                    <div className="input-container-header ">
                        <h3 className="title-text-h3 title-text-bottom-padding">{props.questionElement.question}</h3>
                    </div>

                    <div className="input-container-header ">
                        <h3 className="label-text">{props.questionElement.description}</h3>
                    </div>

                    { props.questionElement.imageUrl !== "" ? <img className= "question-image" src= { props.questionElement.imageUrl } alt= "Kérdéshez tartozó kép" /> : null }
                    
                 </div>


                {/* --- Válaszok ---  */}
                { ( props.questionElement.type < 3 ) ?

                    <div className="question-scale-container-div">
                        <ul id={"questionnaire_answer_answer_table"} className="questionnaire-question-answer-table">
                            { questionElements.length != 0 ? questionElements : null }
                        </ul>
                    </div>
                    : null 
                }


                {/* --- Skála ---  */}
                { ( props.questionElement.type == 3 ) ?
                    <div className="question-scale-container-div">
                      
                        <div className="input-container-header">
                            <h3 className="title-text-h3">Skála “{props.questionElement.scaleFrom}“ - {props.questionElement.scaleFromTitle}</h3>
                        </div>

                        <div className="input-container-header">
                            <h3 className="title-text-h3">{scaleAvg.toFixed(1)}</h3>
                        </div>

                        <div className="input-container-header" >
                            <h3 className="title-text-h3">Skála “{props.questionElement.scaleTo}” - {props.questionElement.scaleToTitle}</h3>
                        </div>
                                        
                    </div>
                    : null 
                } 
               

                {/* --- Szabadválasz ---  */}
                { ( props.questionElement.type == 4 ) ?

                    <ul id={"user-text-answer_table"} className="user-text-answer-table">
                        { answerElements.length != 0 ? answerElements : null }
                    </ul>
                    : null 
                }


                {/* --- Képválasztós ---  */}
                { ( props.questionElement.type == 5 ) ?

                    <div className="questionnaire-question-answer-image-table-container">

                        <div className="questionnaire-question-answer-image-div-container">

                            <UserImageAnswerElement questionElement={props.questionElement} position={0} />                                

                            <UserImageAnswerElement questionElement={props.questionElement} position={1} />                                

                        </div>

                        <div className="questionnaire-question-answer-image-div-container">

                            <UserImageAnswerElement questionElement={props.questionElement} position={2} />                                

                            <UserImageAnswerElement questionElement={props.questionElement} position={3} />   
                                
                        </div>

                    </div>
                    : null 
                }

            </div>

        </li >
    )

}
