import { LoaderFunctionArgs } from 'react-router-dom'
import { getCourse } from '../../action/courses'
import { listTags } from '../../action/tags'
import { store } from '../../store'

export const lessonLoader = async ( args: LoaderFunctionArgs ) => {

    await listTags()
    await getCourse( parseInt( args.params.courseId! ) )

    if ( isNaN( parseInt( args.params.id! ) ) ) {

        store.dispatch( { type: `CREATE_LESSON` } )

    } else {

        store.dispatch( { type: `SELECT_LESSON`, id: parseInt( args.params.id! ) } )

    }

}