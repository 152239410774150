import { TagsState } from '../state'
import { message } from '../helper'

const initialState: TagsState = {

    tagGroups: []

}

export const tagsReducer = ( state: TagsState = initialState, action: any ): TagsState => {

    switch ( action.type ) {

        case `LIST_TAGS_OK`:
            return { ...state, tagGroups: action.tagGroups }


        case `SAVE_TAG_GROUP_OK`:
            message( `A címke csoport sikeresen mentésre került!` )
            return state

        case `DELETE_TAG_GROUP_OK`:
            message( `A címke csoport sikeresen mentésre került!` )
            return state

        case `SAVE_TAG_OK`:
            message( `A címke sikeresen mentésre került!` )
            return state

        case `DELETE_TAG_OK`:
            message( `A címke sikeresen törlésre került!` )
            return state


        default:
            return state

    }

}
