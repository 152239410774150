import { EventsState } from '../state'
import { message } from '../helper'

const initialEventsState: EventsState = {
    hostPoliticians: [],
    selectedPoliticianForSearch: -1,
    notifications: [],
    search: ``,
    showArchive: false,
    event: null,
    events: [],
    eventStat: null,
    form: {
        id: null,
        startDate: { error: ``, required: true, value: `` },
        endDate: { error: ``, required: true, value: `` },
        visibility: { error: ``, required: true, value: `0` },
        title: { error: ``, required: true, value: `` },
        description: { error: ``, required: true, value: `` },
        liveUrl: { error: ``, required: false, value: `` },
        location: { error: ``, required: true, value: `` },
        imageFile: { error: ``, required: true, value: null },
        lat: { error: ``, required: true, value: 47.497913 },
        lng: { error: ``, required: true, value: 19.040236 },
        tags: [],
        summary: { error: ``, required: false, value: `` }
    },
    stats: {
        eventCount: 0,
        userCount: 0,
        uploadedImageCount: 0,
        leastInterestingEvent: null,
        mostInterestingEvent: null,
        nextEvent: null
    },
    pagination: {
        page: 1,
        from: 0,
        to: 0,
        count: 0
    },
    eventImages: {
        eventImageGroups: [],
        reportedEventImageGroups: [],
        pagination: {
            count: 0,
            from: 0,
            page: 0,
            to: 0
        }
    },
    orderBy: `endDate`,
    orderDirection: `DESC`,
    selectedPolitician: null
}

export const eventsReducer = ( state: EventsState = initialEventsState, action: any ): EventsState => {

    let form

    switch ( action.type ) {

        case `EVENTS_CHANGE_ORDER`:
            return { ...state, orderBy: action.orderBy, orderDirection: action.orderDirection }

        case `EVENTS_CHANGE_PAGE`:
            return { ...state, pagination: { ...state.pagination, page: action.page } }

        case `EVENTS_UPDATE_SEARCH`:
            return { ...state, search: action.value }

        case `EVENTS_TOGGLE_SHOW_ARCHIVE`:
            return { ...state, showArchive: !state.showArchive }

        case `EVENTS_CLEAR_FORM`:
            return {
                ...state, form: {
                    id: null,
                    tags: [],
                    summary: { error: ``, required: false, value: `` },
                    startDate: { error: ``, required: true, value: `` },
                    endDate: { error: ``, required: true, value: `` },
                    visibility: { error: ``, required: true, value: `0` },
                    title: { error: ``, required: true, value: `` },
                    description: { error: ``, required: true, value: `` },
                    liveUrl: { error: ``, required: false, value: `` },
                    location: { error: ``, required: true, value: `` },
                    imageFile: { error: ``, required: true, value: null },
                    lat: { error: ``, required: true, value: 47.497913 },
                    lng: { error: ``, required: true, value: 19.040236 }
                }
            }

        case `EVENTS_FORM_ERROR`:
            const f: any = state.form
            f[ action.field ].error = action.value
            return { ...state, form: f }

        case `EVENTS_UPDATE_FORM`:
            form = { ...state.form } as any
            if ( form[ action.field ] !== undefined ) {

                form[ action.field ].value = action.value
                // form[ action.field ].error = ``

            }

            return { ...state, form: form }

        case `GET_EVENT_OK`:
            form = state.form as any
            form.id = parseInt( action.event.id )
            for ( const field of Object.keys( action.event ) ) {
                if ( form[ field ] !== undefined && typeof form[ field ] === "object" ) {
                    if ( field === `startDate` || field === `endDate` ) {
                        const d = new Date( action.event[ field ] * 1000 )
                        form[ field ].value = action.event[ field ] === null ? null : `${ d.getFullYear() }-${ ( d.getMonth() + 1 ).toString().padStart( 2, `0` ) }-${ ( d.getDate() ).toString().padStart( 2, `0` ) }T${ ( d.getHours() ).toString().padStart( 2, `0` ) }:${ ( d.getMinutes() ).toString().padStart( 2, `0` ) }`
                    } else if ( field === `visibility` ) {
                        form[ field ].value = action.event[ field ].toString()
                    } else if ( field === `notifications` ) {

                    } else if ( field === `tags` ) {
                        form.tags = action.event.tags
                    } else {
                        form[ field ].value = action.event[ field ]
                    }
                }
            }
            return { ...state, notifications: action.event.notifications, form: form, event: action.event }

        case `GET_EVENTS_OK`:
            return { ...state, events: action.events, pagination: action.pagination }

        case `GET_EVENT_STATS_OK`:
            return { ...state, stats: action.stats }

        case `GET_EVENT_IMAGES_OK`:
            const eventImages = { ...state.eventImages }
            eventImages[ action.reported ? `reportedEventImageGroups` : `eventImageGroups` ] = action.eventImageGroups
            if ( !action.reported ) {
                eventImages.pagination = action.pagination
            }
            return { ...state, eventImages: eventImages }

        case `EVENTS_ADD_POLITICIAN`:
            return { ...state, selectedPolitician: action.selectedPolitician }

        case `EVENT_ADD_TAG`:
            return { ...state, form: { ...state.form, tags: [ ...state.form.tags, action.tag ] } }

        case `EVENT_REMOVE_TAG`:
            return { ...state, form: { ...state.form, tags: state.form.tags.filter( t => t.id !== action.tag.id ) } }

        case `GET_EVENT_STAT_OK`:
            return { ...state, eventStat: action.eventStat }

        case `CREATE_NOTIFICATION_OK`:
            message( `Az értesítés sikeresen elküldésre került!` )
            return state

        case `DELETE_EVENT_OK`:
            message( `Az esemény sikeresen törlésre került!` )
            return state

        case `SAVE_EVENT_OK`:
            message( `Az esemény sikeresen mentésre került!` )
            return state

        case `SET_SELECTED_POLITICIAN_FOR_SEARCH`:
            return { ...state, selectedPoliticianForSearch: action.selectedPolitician }

        case `EVENT_REMOVE_POLITICIAN`:
            return { ...state, hostPoliticians: state.hostPoliticians.filter( politician => politician.id !== action.id ) }

        case `EVENT_ADD_POLITICIAN`:
            const politicians = state.hostPoliticians!
            if ( !politicians.find( p => p.id === action.politician.id ) ) { politicians.push( action.politician ) }
            return { ...state, hostPoliticians: [ ...politicians ] }

        case `EVENT_SET_POLITICIANS`:
            return { ...state, hostPoliticians: action.politicians }

        default:
            return state

    }
}
