import './experience-points.page.scss'
import { TextField } from '../../component/field'
import { useSelector } from 'react-redux'
import { State } from '../../state'
import { listDefaultExperiencePoints, updateDefaultExperiencePoint } from '../../action/gamification'

export const ExperiencePointsPage = () => {

    const { defaultExperiencePoints } = useSelector( ( state: State ) => state.gamification )
    const onFieldChange = async ( field: string, value: any ) => {

        if ( field.indexOf( `low_percentage` ) !== -1 ) {

            if ( isNaN( parseInt( value ) ) ) { value = 100 } else { value = parseInt( value ) }
            if ( parseInt( value ) < 0 ) { value = 0 }
            if ( parseInt( value ) > 100 ) { value = 100 }

        }

        await updateDefaultExperiencePoint( field, value )
        await listDefaultExperiencePoints()

    }

    return (

        <main className="page page--experience-points">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Tapasztalati pontok</h1>

                </div>

                <div className="card-div">

                    <div className="caption">Regisztrációs Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Név megadása *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `name` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `name`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `user_low_percentage` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `user_low_percentage`, parseInt( e.target.value ) ) } />


                    </div>

                    <div className="row-field">

                        <label>Személyes adatok *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `personaldata` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `personaldata`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Lakóhely adatok *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `address` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `address`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                    <div className="row-field">

                        <label>Minden adat kitöltésre került *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `alldata` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `alldata`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Hírek modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Hír végigolvasása *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `newsread` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `newsread`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `news_low_percentage` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `news_low_percentage`, parseInt( e.target.value ) ) } />

                    </div>

                    <div className="row-field">

                        <label>Hír kvíz helyes kitöltése *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `newsquiz` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `newsquiz`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Politikus modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Politikus legörgetése *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `politicianread` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `politicianread`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `politician_low_percentage` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `politician_low_percentage`, parseInt( e.target.value ) ) } />

                    </div>

                    <div className="row-field">

                        <label>Politikus kvíz helyes kitöltése *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `politicianquiz` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `politicianquiz`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Kérdőívek modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Kitöltött egy kérdőívet *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `questionairecompleted` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `questionairecompleted`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `questionnaire_low_percentage` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `questionnaire_low_percentage`, parseInt( e.target.value ) ) } />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Események modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Képfeltöltése egy eseményhez *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `eventuploaded` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `eventuploaded`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                        <label className="percentage">egy hét után csak %-ot</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `event_low_percentage` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `event_low_percentage`, parseInt( e.target.value ) ) } />

                    </div>

                </div>

                <div className="card-div">

                    <div className="caption">Véleményvezér modul Tapasztalati pontok megadása</div>

                    <div className="row-field">

                        <label>Elhúzta a napi 5 kártyát *</label>
                        <TextField field={{ value: defaultExperiencePoints.find( dep => dep.name === `quotescompleted` )?.experience.toString() ?? `0`, error: `` }} onChange={ e => onFieldChange( `quotescompleted`, parseInt( e.target.value ) ) } />
                        <img src="/image/exp.svg" />

                    </div>

                </div>

            </div>

        </main>

    )

}