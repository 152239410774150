import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createNews, deleteNews } from '../../../action'
import { CREATE_NEWS_ACTIONS } from '../../../action/news/news.constants'
import { News } from '../../../entity'
import { MenuButton } from '../../button'
import { Modal } from '../../modal'
import { useState } from 'react'
import { getNewsItemStats, modifyNewsStatus } from '../../../action/news'
import { NewsStatus } from '../../../enum/news-status.enum'

interface NewsTableMenuButtonProps {
    newsElement: News
    onPreviewClick: any
}

export const NewsTableMenuButton = ( props: NewsTableMenuButtonProps ) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onMenuItemChange = async ( itemId: any ) => {
        switch(itemId) {
            case 1:
                props.newsElement.highlighted = true
                
                dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_MODIFY_NEWS, news: props.newsElement } )
    
                await createNews()
    
                dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CLEAR } )
                break
            case 2:
                getNewsItemStats( props.newsElement.id )

                dispatch( { type: CREATE_NEWS_ACTIONS.CREATE_NEWS_MODIFY_NEWS, news: props.newsElement } )
                navigate("/hirek/uj")
                break
            case 3:
                props.onPreviewClick(props.newsElement)
                break
            default:
                setDeleteFunc( () => {
                    return async () => {
                        deleteNews(props.newsElement.id)
                    }
                } )
                setShowConfirmModal( true )
                break
        }
    }

    return (
        <>
            <MenuButton
                hasBackground={ true }
                items={ [
                    { id: 2, value: 'Szerkesztés', icon: "edit.svg" },
                    { id: 1, value: 'Kiemelés', icon: "pin.svg" },
                    { id: 3, value: 'Megtekintés', icon: "eye.svg" },
                    { id: 4, value: 'Törlés', icon: "trash.svg" },
                ] }
                onChange={ onMenuItemChange }
            />

            { showConfirmModal ? (

                <Modal className="news-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a hírt?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )

}

const onMenuItemChange = async ( itemId: any ) => {
 
}