import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetUserActivitiesDTO } from '../../dto'
import { store } from '../../store'

export const getUserActivities = async ( membershipId: string, types: number[] = [] ) => {

    let body: GetUserActivitiesDTO
    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/users/${ membershipId }/activity?types=${ types.join( `,` ) }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USER_ACTIVITIES_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_USER_ACTIVITIES_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_USER_ACTIVITIES_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetUserActivitiesDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USER_ACTIVITIES_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_USER_ACTIVITIES_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_USER_ACTIVITIES_OK', userActivities: body.activities } )

}
