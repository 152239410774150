import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'

export const sendPushMessage = async (title: string, body: string, data: string, topic: string) => {

    const messageBody = JSON.stringify( {
        title: title,
        body: body,
        data: data,
        topic: topic
    } )

    store.dispatch( { type: 'SEND_PUSH_MESSAGE_STARTED' } )

    const promise = createCall(`firebase/send-push`, BASE_METHODS.POST, messageBody)
    promise.then(async response => {
        store.dispatch( { type: 'SEND_PUSH_MESSAGE_OK', response: response } )
    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })
   
}
