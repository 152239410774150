import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetEventsDTO } from '../../dto/events'
import { store } from '../../store'

export const getEvents = async () => {

    let body, response
    const { search, showArchive, orderBy, orderDirection, selectedPoliticianForSearch } = store.getState().events
    const page = store.getState().events.pagination.page

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/events?archive=${ showArchive }&search=${ search }&page=${ page }&orderBy=${ orderBy }&orderDirection=${ orderDirection }&selectedPolitician=${ selectedPoliticianForSearch }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_EVENTS_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_EVENTS_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_EVENTS_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetEventsDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_EVENTS_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_EVENTS_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_EVENTS_OK', events: body.events, pagination: body.pagination } )

}
