import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetAllNewsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { NEWS_ACTIONS } from './news.constants'

export const getAllNews = async () => {

    const page = store.getState().news.pagination.page
    const search = store.getState().news.search
    const order = store.getState().news.order
    const deleted = store.getState().news.deleted
    const selectedPolitician = store.getState().news.selectedPolitician

    const promise = createCall(`news?page=`+page+`&search=`+search+`&order=`+order+`&deleted=`+deleted+`&selectedPolitician=`+selectedPolitician, BASE_METHODS.GET)
    promise.then(async response => {

        let body: GetAllNewsDTO

        try {
            body = plainToClass( GetAllNewsDTO, JSON.parse( await response!.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( body ) ).length > 0 ) {
            store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: NEWS_ACTIONS.GET_NEWS_OK, news: body.news, pagination: body.pagination } )

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
