import { QuizesState } from '../state'
import { v4 as uuid } from 'uuid'
import { message } from '../helper'

const initialState: QuizesState = {

    type: `quiz`,
    highlighted: null,
    archive: false,
    quiz: null,
    quizes: [],
    quizTopics: [],
    lesson: null,
    keyword: ``,
    pagination: {
        page: 1,
        from: 1,
        to: 1,
        count: 1
    },
    order: {
        by: `id`,
        direction: `ASC`
    },
    stats: {
        newest: null,
        mostAnswer: null,
        leastAnswer: null,
        courses: 0,
        answers: 0
    }

}

export const quizesReducer = ( state: QuizesState = initialState, action: any ): QuizesState => {

    switch ( action.type ) {

        case `SET_QUIZ_TYPE`:
            return { ...state, type: action.quizType }

        case `TOGGLE_QUIZ_ARCHIVE`:
            return { ...state, archive: !state.archive }

        case `SET_QUIZ_KEYWORD`:
            return { ...state, keyword: action.keyword }

        case `QUIZ_ORDER_CHANGE`:
            return { ...state, order: action }


        case `GET_QUIZ_STATS_OK`:
            return { ...state, stats: action.stats }


        case `LIST_QUIZES_OK`:
            return { ...state, quizes: action.quizes, pagination: action.pagination, highlighted: action.highlighted }


        case `GET_QUIZ_OK`:
            if ( action.quiz === null ) {
                action.quiz = {
                    description: ``,
                    lead: ``,
                    quizTopic: state.quizTopics.length === 0 ? null : state.quizTopics[ 0 ],
                    answerExperience: 25,
                    experience: 200,
                    image: null,
                    visibleFrom: new Date().getTime() / 1000,
                    visibleTo: null,
                    tags: [],
                    questions: [],
                    possibleResults: []
                }
            }
            return { ...state, quiz: action.quiz }


        case `QUIZ_ADD_TAG`:
            if ( state.quiz!.tags.find( t => t.id === action.tag.id ) === undefined ) state.quiz!.tags.push( action.tag )
            return { ...state, quiz: { ...state.quiz!, tags: state.quiz!.tags } }

        case `QUIZ_REMOVE_TAG`:
            state.quiz!.tags = state.quiz!.tags.filter( t => t.id !== action.tag.id )
            return { ...state, quiz: { ...state.quiz!, tags: state.quiz!.tags } }

        case `UPDATE_QUIZ`:
            const quiz: any = { ...state.quiz! }
            quiz[ action.field ] = action.value
            return { ...state, quiz: quiz }


        case `LIST_QUIZ_TOPICS_OK`:
            return { ...state, quizTopics: action.quizTopics }


        case `CREATE_QUIZ_QUIZ_QUESTION`:
            const newQuizQuestion: any = {
                id: uuid(),
                isNew: true,
                description: null,
                type: 0,
                image: null,
                answers: [
                    { id: uuid(), isNew: true, tags: [], title: ``, image: null, isSolution: true, results: [] }, { id: uuid(), isNew: true, tags: [], title: ``, image: null, isSolution: false, results: [] }, { id: uuid(), isNew: true, tags: [], title: ``, image: null, isSolution: false, results: [] }, { id: uuid(), isNew: true, tags: [], title: ``, image: null, isSolution: false, results: [] }
                ]
            }
            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions, newQuizQuestion ] } }


        case `UPDATE_QUIZ_QUIZ_QUESTION`:
            const quizQuestionIndex = state.quiz!.questions.findIndex( q => q.id === action.id );
            ( state.quiz!.questions[ quizQuestionIndex ] as any )[ action.field ] = action.value
            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }


        case `QUIZ_QUIZ_ANSWER_ADD_TAG`:
            const quizQuestionIndex3 = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex2 = state.quiz!.questions[ quizQuestionIndex3 ].answers.findIndex( a => a.id === action.id );
            if ( ( state.quiz!.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.find( ( t: any ) => t.id === action.tag.id ) === undefined ) {
                ( state.quiz!.questions[ quizQuestionIndex3 ].answers[ quizAnswerIndex2 ] as any ).tags.push( action.tag )
            }

            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }

        case `QUIZ_QUIZ_ANSWER_REMOVE_TAG`:
            const quizQuestionIndex4 = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex3 = state.quiz!.questions[ quizQuestionIndex4 ].answers.findIndex( a => a.id === action.id );
            state.quiz!.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags = state.quiz!.questions[ quizQuestionIndex4 ].answers[ quizAnswerIndex3 ].tags.filter( t => t.id !== action.tag.id )

            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }

        case `QUIZ_ANSWER_ADD_RESULT`:
            let quizQuestionI = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            let quizAnswerI = state.quiz!.questions[ quizQuestionI ].answers.findIndex( a => a.id === action.id );
            if ( ( state.quiz!.questions[ quizQuestionI ].answers[ quizAnswerI ] as any ).results.find( ( t: any ) => t.id === action.result.id ) === undefined ) {
                ( state.quiz!.questions[ quizQuestionI ].answers[ quizAnswerI ] as any ).results.push( action.result )
            }

            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }

        case `QUIZ_ANSWER_REMOVE_RESULT`:
            quizQuestionI = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            quizAnswerI = state.quiz!.questions[ quizQuestionI ].answers.findIndex( a => a.id === action.id );
            state.quiz!.questions[ quizQuestionI ].answers[ quizAnswerI ].results = state.quiz!.questions[ quizQuestionI ].answers[ quizAnswerI ].results!.filter( t => t.id !== action.result.id )

            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }

        case `UPDATE_QUIZ_QUIZ_ANSWER`:
            const quizQuestionIndex2 = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            const quizAnswerIndex = state.quiz!.questions[ quizQuestionIndex2 ].answers.findIndex( a => a.id === action.id );
            ( state.quiz!.questions[ quizQuestionIndex2 ].answers[ quizAnswerIndex ] as any )[ action.field ] = action.value
            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }


        case `UPDATE_QUIZ_QUIZ_SOLUTION`:
            const quizQuestionIndex5 = state.quiz!.questions.findIndex( q => q.id === action.questionId );
            state.quiz!.questions[ quizQuestionIndex5 ].answers.forEach( q => q.isSolution = false )
            const quizAnswerIndex4 = state.quiz!.questions[ quizQuestionIndex5 ].answers.findIndex( a => a.id === action.id );
            state.quiz!.questions[ quizQuestionIndex5 ].answers[ quizAnswerIndex4 ].isSolution = true

            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions ] } }


        case `DELETE_QUIZ_QUIZ_QUESTION`:
            return { ...state, quiz: { ...state.quiz!, questions: [ ...state.quiz!.questions.filter( q => q.id !== action.id ) ] } }


        case `QUIZ_RESULT_SAVE`:
            const possibleResults = state.quiz!.possibleResults!
            if ( action.result.id === null ) {
                action.result.id = uuid()
                action.result.isNew = true
                possibleResults.push( action.result )
            } else {
                possibleResults[ state.quiz!.possibleResults!.findIndex( r => r.id === action.result.id ) ] = action.result
            }
            return { ...state, quiz: { ...state.quiz!, possibleResults: [ ... possibleResults ] } }

        case `QUIZ_RESULT_DELETE`:
            return { ...state, quiz: { ...state.quiz!, possibleResults: state.quiz!.possibleResults!.filter( result => result.id !== action.id ) } }


        case `SAVE_QUIZ_OK`:
            message( `A kvíz sikeresen mentésre került!` )
            return state

        case `SAVE_TEST_OK`:
            message( `A teszt sikeresen mentésre került!` )
            return state

        case `DELETE_QUIZ_OK`:
            message( `A kvíz sikeresen törlésre került!` )
            return state

        case `DELETE_TEST_OK`:
            message( `A teszt sikeresen törlésre került!` )
            return state

        case `SAVE_QUIZ_TOPIC_OK`:
            message( `A téma sikeresen mentésre került!` )
            return state

        case `DELETE_QUIZ_TOPIC_ERROR`:
            message( `A téma törlése sikertelen volt, mert a téma tartalmaz kvízeket!`, `Hiba` )
            return state

        case `DELETE_QUIZ_TOPIC_OK`:
            message( `A téma sikeresen törlésre került!` )
            return state

        default:
            return state

    }

}
