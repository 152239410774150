import { store } from '../../store'
import { BASE_ACTIONS } from './base.constants'

export const createCall = async ( url: string, method: string, body?: string ): Promise< Response > => {

    let response: Response 

    const token = store.getState().session.token
  
    if ( token === null ) {
        return new Promise<Response>((res, reject) => {
            reject( BASE_ACTIONS.ERROR_NO_USER )
        })
    }

    try {
        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/`+url, {
            body: body ,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: method
        } )
    } catch ( exception ) {
        return new Promise<Response>((res, reject) => {
            reject( BASE_ACTIONS.ERROR_TIMEOUT )
        })
    }

    if ( response.status === 401 ) {
        return new Promise<Response>((res, reject) => {
            reject( BASE_ACTIONS.ERROR_UNAUTHORIZED )
        })
    }

    //Ha hiba jön a szerverről, ezt minden actin egyedileg kezeli a saját hibakódja szerint
    if ( response.status === 400 ) {
        return new Promise<Response>((res, reject) => {
            res(response)
        })
    }

    if ( ![200, 201].includes(response.status) ) {
        return new Promise<Response>((res, reject) => {
            reject( BASE_ACTIONS.ERROR_UNEXPECTED_RESPONSE)
           // reject(new Error('failed'));
        })
        //store.dispatch( { type: BASE_ACTIONS.ERROR_UNEXPECTED_RESPONSE } )
    }

    return new Promise<Response>((res, rej) => {
        res(response)
    })

}
