import { NavigateFunction } from 'react-router-dom'
import { MenuButton, Modal } from '../../../../component'
import { User } from '../../../../entity'
import { store } from '../../../../store'
import { deleteUser } from '../../../../action'
import { useState } from 'react'

interface UsersTableMenuButtonProps {

    user: User
    users: User[]
    navigate: NavigateFunction

}

export const UsersTableMenuButton = ( props: UsersTableMenuButtonProps ) => {

    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const [ showConfirmModal, setShowConfirmModal ] = useState( false )

    const onDelete = async () => {

        await deleteFunc!()
        setShowConfirmModal( false )

    }

    const onMenuButtonClick = async ( users: User[], menuItemId: number, membershipId: string, navigate: NavigateFunction ) => {

        switch ( menuItemId ) {

            case 0:
                const user = users.filter( u => u.membershipId === membershipId )[ 0 ]
                if ( user === undefined ) {
                    break
                }
                store.dispatch( { type: 'SELECT_USER', user: user } )
                break
            case 1:
                navigate( `/tagok/mobil-app-felhasznalok/${ membershipId }` )
                break
            case 2:
                setDeleteFunc( () => {
                    return async () => {
                        await deleteUser( membershipId )
                    }
                } )
                setShowConfirmModal( true )
                break

        }

    }

    return (

        <>

            <MenuButton
                hasBackground={ true }
                items={ [
                    { id: 0, value: 'Szerkesztés', icon: 'pin.svg' },
                    { id: 1, value: 'Megtekintés', icon: 'eye.svg' },
                    { id: 2, value: 'Törlés', icon: 'trash.svg', disabled: props.user?.userGroups?.find( userGroup => userGroup.name === 'master_admins' ) !== undefined }
                ] }
                onChange={ ( id: number ) => onMenuButtonClick( props.users, id, props.user.membershipId, props.navigate ) }
            />

            { showConfirmModal ? (

                <Modal className="users-table-confirm-modal" onModalClose={ () => setShowConfirmModal( false ) }>

                    <div className="modal-title">Biztosan törlöd a felhasználót?</div>
                    <div className="modal-description">Ezt a műveletet nem lehet visszavonni! Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowConfirmModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ onDelete }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </>

    )


}
