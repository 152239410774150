import './quest-group-quests.scss'
import { Quest as QuestEntity } from '../../entity'
import { Quest } from '../quest'

interface QuestGroupsQuestsProps {

    quests: QuestEntity[]

    onFieldChange?: ( index: number, field: string, value: any ) => void
    onDeleteQuest?: ( index: number ) => void

}

export const QuestGroupQuests = ( props: QuestGroupsQuestsProps ) => {

    const onFieldChange = ( index: number, field: string, value: any ) => {

        if ( props.onFieldChange ) props.onFieldChange( index, field, value )

    }

    const onDeleteQuest = ( index: number ) => {

        if ( props.onDeleteQuest ) props.onDeleteQuest( index )

    }

    return (

        <div className="quest-group-quests">

            <div className="caption">Feladatok</div>

            { props.quests.map( ( quest, index ) => (

                <Quest key={ quest.id } quest={ quest } onFieldChange={ ( field, value ) => onFieldChange( index, field, value ) } onDeleteQuest={ () => onDeleteQuest( index ) } />

            ) ) }

        </div>

    )

}