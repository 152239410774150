import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetQuestionnaireDTO } from '../../dto/questionnaires'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { getQuestionnairesStats } from './get-questionnaires-stats.action'
import { CREATE_QUESTIONNAIRE_ACTIONS } from './questionnaires.constants'

export const createQuestionaire = async () => {

    store.dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_STARTED } )

    const questionnaireId = store.getState().createQuestionnaire.form.id

    let questions = undefined

    const questionArray = store.getState().createQuestionnaire.form.questions

    if(questionArray != null){
        questions = []

        questionArray.forEach(function (item, index) {
            let answerArray = undefined

            if(item.answers != null && item.answers != undefined && item.answers.length != 0){
                answerArray = []

                item.answers.forEach(function ( answerItem ) {
                    answerArray.push({
                        id: answerItem.isNew === true ? null : answerItem.id,
                        answer: answerItem.answerField.value,
                        mediaLibraryFileId: answerItem.mediaLibraryFile?.id ?? null,
                        tags: answerItem.tags
                    })
                })
            }
           
            questions.push({
                id: item.isNew === true ? null : item.id,
                question: item.questionField.value,
                description: item.descriptionField.value,
                order: index,
                scaleFrom: item.scaleFrom,
                scaleTo: item.scaleTo,
                scaleToTitle: item.scaleToTitleField.value,
                scaleFromTitle: item.scaleFromTitleField.value,
                typeId: item.type, 
                mediaLibraryFileId: item.mediaLibraryFile?.id ?? null, 
                answers: answerArray
            })
        })
    }

    const body = JSON.stringify( {
        title: store.getState().createQuestionnaire.form.title.value,
        sendPush: store.getState().createQuestionnaire.form.sendPush,
        experience: parseInt( store.getState().createQuestionnaire.form.experience.value ),
        lead: store.getState().createQuestionnaire.form.lead.value,
        numberOfCompleteRequired: Number(store.getState().createQuestionnaire.form.numberOfCompleteRequired.value),
        description: store.getState().createQuestionnaire.form.description.value,
        visibilityId: store.getState().createQuestionnaire.form.visibility,
        statusId: store.getState().createQuestionnaire.form.status ,
        visibleFrom: new Date(store.getState().createQuestionnaire.form.visibleFrom).getTime() / 1000,
        visibleTo: new Date(store.getState().createQuestionnaire.form.visibleTo).getTime() / 1000,
        mediaLibraryFileId: store.getState().createQuestionnaire.form.mediaLibraryFile?.id ?? null,
        questions: questions,
        tags: store.getState().createQuestionnaire.form.tags
    } )

    const promise = createCall( `questionnaires`+ (questionnaireId != null ? (`/`+questionnaireId) : ''), questionnaireId != null ?  BASE_METHODS.PUT :  BASE_METHODS.POST, body )
    promise.then(async response => {
        let responseBody: GetQuestionnaireDTO

        try {
            responseBody = plainToClass( GetQuestionnaireDTO, JSON.parse( await response.text() ) )
        } catch ( exception ) {
            store.dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_INVALID_JSON } )
            return
        }
    
        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_INVALID_RESPONSE } )
            return
        }
    
        store.dispatch( { type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_OK, questionnaire: responseBody.questionnaire } )
    
        getQuestionnairesStats()

    })

    promise.catch(error => {
        store.dispatch( { type: error } )
    })

}
