import { combineReducers, legacy_createStore as createStore } from 'redux'
import { achievementsReducer, applicationVersionsReducer, coursesReducer, floatingMessageReducer, gamificationReducer, loginReducer, mediaLibraryReducer, pushReducer, quizesReducer, quotesReducer, seasonsReducer, sessionReducer, statisticsReducer, tagsReducer, usersReducer } from '../reducer'
import { baseReducer } from '../reducer/base'
import { dashboardReducer } from '../reducer/dashboard.reducer'
import { eventsReducer } from '../reducer/events.reducer'
import { newsReducer, createNewsReducer, deleteNewsReducer, newsStatsReducer } from '../reducer/news'
import { createNewsCategoryReducer, deleteNewsCategoryReducer, newsCategoriesReducer } from '../reducer/news-category'
import { createPoliticianQuestionReducer, createPoliticianReducer, createPoliticianTitleReducer, politiciansReducer } from '../reducer/politicians'
import { createQuestionnaireReducer, questionnaireStatsReducer } from '../reducer/questionnaires'
import { createQuestionnaireTagReducer } from '../reducer/questionnaires/create-questionnaire-tag.reducer'
import { questionnairesReducer } from '../reducer/questionnaires/questionnaires.reducer'

export const store = createStore( combineReducers( {
    base: baseReducer,
    login: loginReducer,
    session: sessionReducer,
    users: usersReducer,
    news: newsReducer,
    createNews: createNewsReducer,
    deleteNews: deleteNewsReducer,
    newsStats: newsStatsReducer,
    newsCategories: newsCategoriesReducer,
    createNewsCategory: createNewsCategoryReducer,
    deleteNewsCategory: deleteNewsCategoryReducer,
    politicians: politiciansReducer,
    createPolitician: createPoliticianReducer,
    createPoliticianQuestion: createPoliticianQuestionReducer,
    createPoliticianTitle: createPoliticianTitleReducer,
    mediaLibrary: mediaLibraryReducer,
    questionnaireStats: questionnaireStatsReducer,
    questionnaires: questionnairesReducer,
    createQuestionnaire: createQuestionnaireReducer,
    createQuestionnaireTag: createQuestionnaireTagReducer,
    events: eventsReducer,
    quotes: quotesReducer,
    dashboard: dashboardReducer,
    push: pushReducer,
    statistics: statisticsReducer,
    applicationVersions: applicationVersionsReducer,
    gamification: gamificationReducer,
    tags: tagsReducer,
    floatingMessages: floatingMessageReducer,
    courses: coursesReducer,
    quizes: quizesReducer,
    seasons: seasonsReducer,
    achievements: achievementsReducer
} ) )
