import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUsers, listAdminActivities } from "../../action";
import { AdminModal, MenuButton, Table } from "../../component";
import { AdminActivity } from "../../component/admin-activity";
import { User } from "../../entity";
import {
  county,
  createFormattedDateTimeString,
  getUserGroupName,
} from "../../helper";
import { State } from "../../state";
import "./admin-users.page.scss";

interface AdminUsersProps {}

const items = [
  { name: `Hírek`, activityTypes: [`News`, `NewsCategory`] },
  { name: `Véleményvezérek`, activityTypes: [`Quote`] },
  { name: `Kérdőívek`, activityTypes: [`Questionnaire`] },
  { name: `Események`, activityTypes: [`Event`] },
  { name: `Politikusaink`, activityTypes: [`Politician`] },
  { name: `Kvízek és tesztek`, activityTypes: [`Quiz`, `Test`] },
  { name: `Képzések`, activityTypes: [`Course`, `Lesson`] },
  { name: `Küldetések`, activityTypes: [`Quest`, `QuestGroup`] },
  { name: `Értesítések`, activityTypes: [`PushMessage`] },
  { name: `Floating messages`, activityTypes: [`FloatingMessage`] },
  { name: `Címkék`, activityTypes: [`Tag`, `TagGroup`] },
];

export const AdminUsers = (props: AdminUsersProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order, adminActivities, stats, users, selectedUser, pagination } =
    useSelector((state: State) => state.users);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const onMenuButtonClick = async (
    menuItemId: number,
    membershipId: string
  ) => {
    switch (menuItemId) {
      case 1:
        navigate(`/tagok/adminok/${membershipId}`);
        break;
    }
  };

  const selectItem = async (item: {
    name: string;
    activityTypes: string[];
  }) => {
    await listAdminActivities(item.activityTypes);
    setSelectedItem(item);
  };

  const onOrderChange = (column: string) => {
    if (order.by === column) {
      if (order.direction === "ASC") {
        dispatch({ type: "CHANGE_ORDER", by: order.by, direction: "DESC" });
      } else {
        dispatch({ type: "CHANGE_ORDER", by: "", direction: "ASC" });
      }
    } else {
      dispatch({ type: "CHANGE_ORDER", by: column, direction: "ASC" });
    }
    getUsers([2, 3, 4]);
  };

  const columns = [
    {
      align: "center",
      caption: "ID",
      key: "membershipId",
      name: "user.membershipId",
      width: 200,
    },
    {
      align: "left",
      caption: "Név",
      name: "name",
      render: (row: User) => `${row.lastName} ${row.firstName}`,
      width: 300,
    },
    {
      align: "center",
      caption: "Megye",
      name: "user.county",
      render: (row: User) => `${county(row.county)}`,
      width: 200,
    },
    {
      align: "center",
      caption: "Hatáskör",
      render: (row: User) => getUserGroupName(row.userGroups[0]),
      name: "userGroup.id",
      width: 200,
    },
    {
      align: "center",
      caption: "Utolsó aktivitás",
      name: "lastAdminActivity",
      render: (row: User) =>
        `${
          row.lastAdminActivity
            ? createFormattedDateTimeString(row.lastAdminActivity)
            : `nincs adat`
        }`,
      width: 200,
    },
    {
      align: "center",
      canOrder: false,
      render: (user: User) => (
        <MenuButton
          hasBackground={true}
          items={[{ id: 1, value: "Megtekintés", icon: "eye.svg" }]}
          onChange={(id: number) => onMenuButtonClick(id, user.membershipId)}
        />
      ),
      width: 100,
    },
  ];

  return (
    <main className="page page--admin-users">
      <div className="main-div">
        <header>
          <h1>Adminok</h1>
          <button
            className="button button--primary"
            onClick={() => setShowAdminModal(true)}
          >
            Admin meghívása
          </button>
        </header>

        <div className="tabs-select card-div">
          <div className="tabs">
            <div
              className={`tab ${tabSelected === 0 ? `tab--selected` : ``}`}
              onClick={() => setTabSelected(0)}
            >
              Adminok
            </div>
            <div
              className={`tab ${tabSelected === 1 ? `tab--selected` : ``}`}
              onClick={() => setTabSelected(1)}
            >
              Tevékenységek
            </div>
          </div>
        </div>

        {tabSelected === 0 ? (
          <div className="card-div">
            <Table
              columns={columns}
              dataset={users}
              order={order}
              onOrderChange={onOrderChange}
              onRowClick={(row) => onMenuButtonClick(1, row.membershipId)}
              pagination={{
                ...pagination,
                onPageChange: (page) => getUsers([2, 3, 4], page),
              }}
            />
          </div>
        ) : null}

        {tabSelected === 1 ? (
          <div className="activities card-div">
            <div className="tabs">
              {items.map((item) => (
                <a
                  className={
                    selectedItem !== null && selectedItem.name === item.name
                      ? `selected`
                      : ``
                  }
                  onClick={() => selectItem(item)}
                >
                  {item.name}
                </a>
              ))}
            </div>

            <div className="admin-activities">
              {adminActivities.length === 0 ? (
                <div className="user-activities__empty">
                  Nincs megjeleníthető elem
                </div>
              ) : null}

              {adminActivities.map((adminActivity) => (
                <AdminActivity
                  key={adminActivity.id}
                  adminActivity={adminActivity}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {showAdminModal ? (
        <AdminModal onModalClose={() => setShowAdminModal(false)} />
      ) : null}
    </main>
  );
};
