import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { store } from '../../store'
import { CreateQuestDTO } from '../../dto/gamification'

export const sendPush = async ( title: string, message: string, leagues: number[] ) => {

    let response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/leagues/push`, {
            body: JSON.stringify( {
                title: title,
                body: message,
                leagues: leagues
            } ),
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'SEND_PUSH_LEAGUE_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'SEND_PUSH_LEAGUE_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'SEND_PUSH_LEAGUE_UNEXPECTEDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'SEND_PUSH_LEAGUE_OK' } )

}
