import './quests.page.scss'
import { LeagueCard, Modal } from '../../component'
import { QuestGroupCard } from '../../component/quest-group-card'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { createQuestGroup, deleteQuest, deleteQuestGroup } from '../../action/gamification'
import { QuestCard } from '../../component/quest-card'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

export const QuestsPage = () => {

    const dispatch = useDispatch()
    const [ showDeleteModal, setShowDeleteModal ] = useState( false )
    const [ deleteFunc, setDeleteFunc ] = useState( null as Function | null )
    const { quests, questGroups, leagues } = useSelector( ( state: State ) => state.gamification )

    const confirmDelete = async () => {

        if ( deleteFunc !== null ) {

            await deleteFunc()

        }
        setShowDeleteModal( false )
        setDeleteFunc( null )

    }

    const onQuestGroupDelete = async ( index: number ) => {

        const questGroup = questGroups[ index ]

        const deleteFunc = async () => {

            if ( questGroup.id !== null ) {
                await deleteQuestGroup( questGroup.id )
            }
            dispatch( { type: `QUEST_GROUPS_DELETE`, index: index } )

        }
        setDeleteFunc( () => deleteFunc )
        setShowDeleteModal( true )

    }

    const onQuestDelete = async ( index: number ) => {

        const quest = quests[ index ]

        const deleteFunc = async () => {

            if ( quest.id !== null ) {
                await deleteQuest( quest.id )
            }
            dispatch( { type: `QUEST_DELETE`, index: index } )

        }
        setDeleteFunc( () => deleteFunc )
        setShowDeleteModal( true )

    }

    const onQuestGroupCreate = async () => {

        const uid = uuid()

        dispatch( { type: 'CREATE_QUEST_GROUP_OK', questGroup: {
            uuid: uid,
            id: null,
            name: ``,
            lead: ``,
            description: ``,
            quests: [],
            exp: 300,
            tags: [],
            leagues: [],
            image: null,
            deleteQuests: [],
            created: Math.round( new Date().getTime() / 1000 )
        } } )
        setTimeout( () => { window.location.href = `#${ uid }` }, 200 )

    }

    const onQuestCreate = async () => {

        const uid = uuid()

        dispatch( { type: 'CREATE_QUEST_OK', quest: {
            uuid: uid,
            id: null,
            name: ``,
            type: 0,
            questConditionId: 5,
            contentId: null,
            repeats: 1,
            exp: 100,
            tags: [],
            leagues: [],
            created: Math.round( new Date().getTime() / 1000 )
        } } )

        setTimeout( () => { window.location.href = `#${ uid }` }, 200 )

    }

    return (

        <main className="page page--quests">

            <div className="main-div">

                <div className="navigation-title">

                    <h1 className="title-text-h1">Ligák és kihívások</h1>

                </div>

                <div className="toolbar">

                    <NavLink to="/tapasztalati-pontok"><button className="button">Tapasztalati pontok</button></NavLink>
                    <NavLink to="/nyertesek"><button className="button">Nyertesek</button></NavLink>

                </div>

                <div className="leagues">

                    { leagues.filter( l => l.id > 1 ).sort( ( a, b ) => a.id - b.id ).map( league => (

                        <LeagueCard key={ league.id } id={ league.id } name={ league.name } icon={ `/image/leagues/000${ league.id }.png` } quests={ league.questsCount! } users={ league.usersCount! } />

                    ) ) }

                </div>

                <div className="quests-and-questgroups">

                    <h1 className="title-text-h1">Kihívások</h1>

                    <div className="quests-toolbar">

                        <button className="new-quest-button" onClick={ onQuestCreate }>Új kihívás</button>
                        <button className="new-quest-group-button" onClick={ onQuestGroupCreate }>Új kihívás csoport</button>

                    </div>

                    { questGroups.sort( ( a, b ) => b.created - a.created ).map( ( questGroup, index ) => (

                        <QuestGroupCard index={ index } key={ questGroup.id } questGroup={ questGroup } onDelete={ () => onQuestGroupDelete( index ) } />

                    ) ) }

                    { quests.sort( ( a, b ) => b.created - a.created ).map( ( quest, index ) => (

                        <QuestCard index={ index } key={ quest.id } quest={ quest } onDelete={ () => onQuestDelete( index ) } />

                    ) ) }

                </div>

            </div>

            { showDeleteModal ? (

                <Modal onModalClose={ () => setShowDeleteModal( false ) }>

                    <div className="modal-title">Biztosan törlöd az elemet az összes ligából?</div>
                    <div className="modal-description">Minden ligából törlésre kerül az elem, ha a föoldalon kerül erre sor. Biztosan törlöd?</div>
                    <footer>
                        <button className="button button--primary" onClick={ () => setShowDeleteModal( false ) }>Mégsem</button>
                        <button className="button button--ghost button--warning" onClick={ () => confirmDelete() }>Törlés</button>
                    </footer>

                </Modal>

            ) : null }

        </main>

    )

}