import { listLeagues, listQuestGroups } from '../../action/gamification'
import { listQuests } from '../../action/gamification/list-quests.action'
import { listTags } from '../../action/tags'

export const questsPageLoader = async () => {

    await listTags()
    await listLeagues()
    await listQuestGroups()
    await listQuests()

}
