import { LoaderFunctionArgs } from 'react-router-dom'
import { getUser, getUserActivities } from '../../action'
import { listTags } from '../../action/tags'
import { getInterests } from '../../action/users/get-interest.action'

export const mobileAppUserLoader = async ( args: LoaderFunctionArgs ) => {

    await listTags()
    await getUser( args.params.membershipId as string )
    await getUserActivities( args.params.membershipId as string, [ 18 ] )
    await getInterests( args.params.membershipId as string )

}
