import { CREATE_QUESTIONNAIRE_ACTIONS, DELETE_QUESTIONNAIRE_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { QuestionnaireQuestion, QuestionnaireQuestionAnswer } from '../../entity'
import { NewsStatus } from '../../enum/news-status.enum'
import { NewsVisibility } from '../../enum/news-visibility.enum'
import { getLocalISOTime, createLocalISOTime, getLocalISOTimePlus, message } from '../../helper'
import { CreateQuestionnaireState } from '../../state/questionnaires'

const initialCreateQuestionnaireState: CreateQuestionnaireState = {
    form: {
        id: null,
        experience: {
            error: '',
            value: '',
            required: true
        },
        sendPush: false,
        visibleFrom: getLocalISOTime(),
        visibleTo: getLocalISOTimePlus( 1 ),
        status: NewsStatus.Draft,
        visibility: NewsVisibility.Open,
        mediaLibraryFile: null,
        imageUrl: "",
        tags: [],
        messages: [],
        title: {
            error: '',
            value: '',
            required: true
        },
        lead: {
            error: '',
            value: '',
            required: true
        },
        description: {
            error: '',
            value: '',
            required: true
        },
        numberOfCompleteRequired: {
            error: '',
            value: '',
            required: true
        },
        questions: []
    },
    saveDate: null,
    questionnaire: null
}

export const createQuestionnaireReducer = ( state: CreateQuestionnaireState = initialCreateQuestionnaireState, action: any ): CreateQuestionnaireState => {
    switch ( action.type ) {
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_MEDIA_LIBRARY_FILE:
            return { ...state, form: { ...state.form, mediaLibraryFile: action.mediaLibraryFile, imageUrl: action.mediaLibraryFile ? action.mediaLibraryFile.url : "" } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_FROM:
            return { ...state, form: { ...state.form, visibleFrom: action.visibleFrom } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_TO:
            return { ...state, form: { ...state.form, visibleTo: action.visibleTo } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_STATUS:
            return { ...state, form: { ...state.form, status: action.status } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBILITY:
            return { ...state, form: { ...state.form, visibility: action.visibility } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_TITLE:
            return { ...state, form: { ...state.form, title: { ...state.form.title, value: action.title } } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_NUMBER_OF_COMPLETE_REQUIRED:
            return { ...state, form: { ...state.form, numberOfCompleteRequired: { ...state.form.numberOfCompleteRequired, value: action.numberOfCompleteRequired } } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_LEAD:
            return { ...state, form: { ...state.form, lead: { ...state.form.lead, value: action.lead } } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_DESCRIPTION:
            return { ...state, form: { ...state.form, description: { ...state.form.description, value: action.description } } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_STARTED:
            return { ...state, saveDate: new Date() }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_INVALID_JSON:
            return { ...state, saveDate: null, questionnaire: null }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_INVALID_RESPONSE:
            return { ...state, saveDate: null, questionnaire: null }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_OK:
            message( `A kérdőív sikeresen mentésre került!` )
            return { ...state, saveDate: null, questionnaire: action.questionnaire, form: { ...state.form, questions: [],  } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CLEAR:
            state.form.questions = []
            return initialCreateQuestionnaireState
        case `QUESTIONNAIRE_CHANGE_EXPERIENCE`:
            if ( isNaN( action.experience ) ) { return state }
            if ( action.experience === `` ) { action.experience = `0` }
            return { ...state, form: { ...state.form, experience: { ...state.form.experience, value: action.experience } } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ORDER_QUESTION:
            return { ...state, form: { ...state.form, questions: action.questions }  }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_QUESTION:
                if ( state.form.questions == null ) {
                    state.form.questions = []
                }
    
                state.form.questions.push( {
                        id: state.form.questions.length,
                        question: '',
                        questionField: {
                            error: '',
                            value: '' ,
                            required: true
                        },
                        description: '',
                        descriptionField: {
                            error: '',
                            value: '' ,
                            required: false
                        },
                        order: 1,
                        scaleFrom: null,
                        scaleTo: null,
                        scaleFromTitle:null,
                        scaleFromTitleField: {
                            error: '',
                            value: '' ,
                            required: false
                        },
                        scaleToTitle:null,
                        scaleToTitleField: {
                            error: '',
                            value: '' ,
                            required: false
                        },
                        type: 1,
                        imageFile: null,
                        mediaLibraryFile: null,
                        imageUrl: "",
                        answers: [],
                        isNew: true
                    }
                )
                    
                return { ...state, form: { ...state.form, questions: state.form.questions } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_DESCRIPTION:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.descriptionField.value = action.description    
                    }
                })
            }
                
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_QUESTION:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.questionField.value = action.question    
                    }
                })
            }
                    
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TYPE:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.type = action.typeId   
                        
                        questionItem.answers = []
                    }
                })
            }
                        
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_FROM_SCALE_TITLE:
             if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.scaleFromTitleField.value = action.fromScaleTitle   
                    }
                })
            }
                            
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE_TITLE:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.scaleToTitleField.value = action.toScaleTitle   
                    }
                })
            }
                               
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ITEM_TO_SCALE:
             if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.scaleTo = action.toScale   
                        questionItem.scaleFrom = 1   
                    }
                })
             }
                        
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_QUESTION:
            return { ...state, form: { ...state.form, questions: state.form.questions.filter( item => action.selectedQuestionId !== item.id ) } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_MEDIA_LIBRARY_FILE:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        questionItem.mediaLibraryFile= action.mediaLibraryFile
                        questionItem.imageUrl = action.mediaLibraryFile? action.mediaLibraryFile.url : ""
                    }
                })
            }
                           
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_MEDIA_LIBRARY_FILE:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){

                        if ( questionItem.answers == null ||questionItem.answers.length == 0 ) {
                            questionItem.answers = []

                            for (let i = 0; i < 4; i++) {
                       
                                questionItem.answers.push( 
                                    {
                                        id: questionItem.answers.length,
                                        answer: '',
                                        answerField: {
                                            error: '',
                                            value: '' ,
                                            required: true
                                        },
                                        imageFile: null,
                                        mediaLibraryFile: null,
                                        imageUrl: "",
                                        isNew: true
                                    }
                                )
                            }
                        }

                        questionItem.answers[action.answerPosition].mediaLibraryFile= action.mediaLibraryFile
                        questionItem.answers[action.answerPosition].imageUrl = action.mediaLibraryFile ? action.mediaLibraryFile.url : ""
                    }
                })
            }
                               
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_QUESTION_ANSWER:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.id){
                        if ( questionItem.answers == null ) {
                            questionItem.answers = []
                        }
                    
                        questionItem.answers.push( {
                            id: questionItem.answers.length,
                            answer: '',
                            answerField: {
                                error: '',
                                value: '' ,
                                required: true,
                            },
                            imageFile: null,
                            isNew: true,
                            tags: []
                        })
                    }
                })
            }
          
            return { ...state, form: { ...state.form, questions: state.form.questions } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_QUESTION_ANSWER_ITEM_ANSWER:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.questionId){
                        if ( questionItem.answers !== null ) {
                            questionItem.answers.map(( answerItem: any  ) => {
                                if(answerItem.id == action.id){
                                    answerItem.answerField.value = action.answer    
                                }
                            })
                        }
                    }
                })
            }
                    
            return { ...state, form: { ...state.form, questions: state.form.questions } }  
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_QUESTION_ANSWER:
            if ( state.form.questions !== null ) {
                state.form.questions.map(( questionItem: any  ) => {
                    if(questionItem.id == action.questionId){
                        if ( questionItem.answers !== null ) {
                            questionItem.answers = questionItem.answers.filter( (item: any) => action.selectedAnswerId !== item.id ) 
                        }
                    }
                })
            }

            return { ...state, form: { ...state.form, questions: state.form.questions } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_ADD_TAG:
            if ( state.form.tags.some( item => action.selectedTag.id === item.id ) ) {
                return state
            }
            return { ...state, form: { ...state.form, tags: [ ...state.form.tags, action.selectedTag ] } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_TAG:
            return { ...state, form: { ...state.form, tags: state.form.tags.filter( item => action.selectedTagId !== item.id ) } }
        case `QUESTIONNAIRE_CLEAR_QUESTIONS`:
            return { ...state, form: { ...state.form, questions: [] } }
        case CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_MODIFY_QUESTIONNAIRE:

            if ( action.questionnaire.questions !== null && action.questionnaire.questions !== undefined) {
                action.questionnaire.questions.map(( question: QuestionnaireQuestion  ) => {

                    if ( question.answers !== null && question.answers !== undefined) {
                        question.answers.map(( answer: QuestionnaireQuestionAnswer  ) => {
                            answer.answerField = {
                                error: "",
                                value: answer.answer,
                                required: true
                            }
                            answer.imageUrl = answer.imageFile != null ? answer.imageFile.url : ''
                            answer.mediaLibraryFile = {
                                id: answer.imageFile != null ? answer.imageFile.id : -1,
                                extension: "png",
                                name: "name",
                                width: 1,
                                height: 1,
                                size: "size",
                                thumbnail: `thumbnail`,
                                type: `file`,
                                url: `url`,
                                created: 1
                            }
                        })
                    }

                    question.questionField = {
                        error: "",
                        value: question.question,
                        required: false
                    }
                    question.descriptionField = {
                        error: "",
                        value: question.description,
                        required: false
                    }
                    question.scaleFromTitleField = {
                        error: "",
                        value: question.scaleFromTitle ? question.scaleFromTitle : "",
                        required: false
                    }
                    question.scaleToTitleField = {
                        error: "",
                        value: question.scaleToTitle ? question.scaleToTitle : "",
                        required: false
                    }
                    question.imageUrl = question.imageFile != null ? question.imageFile.url : ''

                })
            }

            return {
                ...state,
                form: {
                    ...state.form,
                    sendPush: action.questionnaire.sendPush,
                    id: action.questionnaire.id,
                    visibleTo: createLocalISOTime( action.questionnaire.visibleTo ),
                    visibleFrom: createLocalISOTime( action.questionnaire.visibleFrom ),
                    status: action.questionnaire.status,
                    visibility: action.questionnaire.visibility,
                    tags: action.questionnaire.tags,
                    messages: action.questionnaire.messages,
                    title: {
                        error: '',
                        value: action.questionnaire.title,
                        required: true
                    },
                    experience: {
                        error: '',
                        value: action.questionnaire.experience,
                        required: true
                    },
                    lead: {
                        error: '',
                        value: action.questionnaire.lead,
                        required: true
                    },
                    description: {
                        error: '',
                        value: action.questionnaire.description,
                        required: true
                    },
                    numberOfCompleteRequired: {
                        error: '',
                        value: action.questionnaire.numberOfCompleteRequired,
                        required: true
                    },
                    imageUrl: action.questionnaire.imageFile != null ? action.questionnaire.imageFile.url : '',
                    mediaLibraryFile: action.questionnaire.imageFile != null ? {
                        id: action.questionnaire.imageFile.id ,
                        extension: "jpg",
                        name: "",
                        width: 0,
                        height: 0,
                        size: "",
                        thumbnail: "",
                        type: "file",
                        url: "",
                        created: 0
                    } : null,
                    questions: action.questionnaire.questions,
                }
            }

        case `QUESTIONNAIRE_ADD_TAG`:
            return { ...state, form: { ...state.form, tags: [ ...state.form.tags, action.tag ] } }

        case `QUESTIONNAIRE_REMOVE_TAG`:
            return { ...state, form: { ...state.form, tags: state.form.tags.filter( t => t.id !== action.tag.id ) } }

        case `QUESTIONNAIRE_ANSWER_ADD_TAG`:
            state.form.questions.forEach( q => {
                q.answers?.forEach( a => {
                    if ( a.id === action.id ) {
                        a.tags.push( action.tag )
                    }
                } )
            } )
            return { ...state, form: { ...state.form, questions: [ ... state.form.questions ] } }

        case `QUESTIONNAIRE_ANSWER_REMOVE_TAG`:
                state.form.questions.forEach( q => {
                q.answers?.forEach( a => {
                    if ( a.id === action.id ) {
                        a.tags = a.tags.filter( t => t.id !== action.tag.id )
                    }
                } )
            } )
            return { ...state, form: { ...state.form, questions: [ ... state.form.questions ] } }

        case `QUESTIONNAIRE_CREATE_NOTIFICATION_OK`:
            message( `Az értesítés sikeresen elküldésre került!` )
            return state

        case `QUESTIONNAIRE_DELETE_NOTIFICATION_OK`:
            message( `Az értesítés sikeresen törlésre került!` )
            return state

        case DELETE_QUESTIONNAIRE_ACTIONS.DELETE_QUESTIONNAIRE_OK:
            message( `A kérdőív sikeresen törlésre került!` )
            return state

        case `SEND_QUESTIONNAIRE_PUSH_OK`:
            message( `Az értesítés sikeresen elküldésre került!` )
            return state

        case `QUESTIONNAIRE_CHANGE_SEND_PUSH`:
            return { ...state, form: { ...state.form, sendPush: action.sendPush } }

        default:
            return state
    }
}

