import { DELETE_NEWS_ACTIONS } from '../../action/news/news.constants'
import { DeleteNewsState } from '../../state/news'
import { message } from '../../helper'

const initialDeleteNewsState: DeleteNewsState  = {
    deleteStarted: null,
    response: null
}

export const deleteNewsReducer = ( state: DeleteNewsState = initialDeleteNewsState, action: any ): DeleteNewsState => {
    switch ( action.type ) {
        case DELETE_NEWS_ACTIONS.DELETE_NEWS_STARTED:
            return { ...state, deleteStarted: new Date(), response: null}
        case DELETE_NEWS_ACTIONS.DELETE_NEWS_OK:
            message( `A hír sikeresen törlésre került!` )
            return { ...state, deleteStarted: null, response: action.response }
        default:
            return state
    }
}

