import { ChangeEvent, createRef, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'
import { createQuestionaire } from '../../action/questionnaires/create-questionnaire.action'
import { CREATE_QUESTIONNAIRE_ACTIONS, CREATE_QUESTIONNAIRE_TAG_ACTIONS } from '../../action/questionnaires/questionnaires.constants'
import { AddImage } from '../../component/add-image'
import { NumberField, TextField } from '../../component/field'
import { ImageEditor } from '../../component/image-editor/image-editor'
import { MediaLibrary } from '../../component/media-library'
import { TabView } from '../../component/tab-view'
import { TabViewPage } from '../../component/tab-view/tab-view-page'
import { TextArea } from '../../component/textarea'
import { MediaLibraryFile, Questionnaire } from '../../entity'
import { NewsStatus } from '../../enum/news-status.enum'
import { NewsVisibility } from '../../enum/news-visibility.enum'
import { State } from '../../state'
import { QuestionnaireQuestionTable } from './component/questionnaire-question-table'
import { QuestionnaireTagModal } from './component/questionnaire-tag-modal/questionnaire-tag-modal'
import { QuestionnaireUserAnswerTable } from './component/questionnaire-user-answers-table'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './create-questionnaire.page.scss'
import { TagSelector } from '../../component/tag-selector'
import { Modal } from '../../component'
import { deleteNotification, createNotification, modifyQuestionnaireStatus, sendQuestionnairePush } from '../../action/questionnaires'
import { getQuestionnaire } from '../../action/questionnaires/get-questionnaire.action'
import { InputModal, MessageModal } from '../../component/modal'
import { format } from '../../helper'

interface CreateQuestionnaireProps {}

export const CreateQuestionnaire = (props: CreateQuestionnaireProps) => {
    const changeWithinLimits = (type: string, fieldName: string, e: ChangeEvent<HTMLInputElement>, low: number, high: number) => {
        let value = e.target.value
        if (value.trim().length === 0) {
            value = low.toString()
        }
        if (isNaN(parseInt(value))) {
            return
        }
        if (parseInt(value) > high) {
            value = high.toString()
        }
        if (parseInt(value) < low) {
            value = low.toString()
        }

        const action: any = { type: type }
        action[fieldName] = value
        dispatch(action)
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [infoModalVisibility, setInfoModalVisibility] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState('')
    const [infoModalDescription, setInfoModalDescription] = useState('')

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deleteFunc, setDeleteFunc] = useState(null as Function | null)
    const [notificationValue, setNotificationValue] = useState(``)
    const [showInputModal, setShowInputModal] = useState(false)
    const [mediaLibraryFile, setMediaLibraryFile] = useState(null as MediaLibraryFile | null)
    const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false)
    const [imageEditorVisibility, setImageEditorVisibility] = useState(false)

    const sendPush = async (event: MouseEvent) => {
        event.preventDefault()
        await sendQuestionnairePush(form.id!)
    }

    const onNotificationSave = async () => {
        await createNotification(form.id!, notificationValue)
        setNotificationValue(``)
        setShowInputModal(false)
        await getQuestionnaire(form.id)
    }

    const onNotificationDelete = async (notificationId: number) => {
        setDeleteFunc(() => {
            return async () => {
                await deleteNotification(notificationId!)
                await getQuestionnaire(form.id)
            }
        })
        setShowConfirmDelete(true)
    }

    const deleteMessage = () => {
        if (deleteFunc !== null) {
            deleteFunc()
        }
        setDeleteFunc(null)
        setShowConfirmDelete(false)
    }

    const [questionnaireTagModalVisibility, setQuestionnaireTagModalVisibility] = useState<boolean>(false)

    const saveDate = useSelector((state: State) => state.createQuestionnaire.saveDate)
    const questionnaire = useSelector((state: State) => state.createQuestionnaire.questionnaire)

    const form = useSelector((state: State) => state.createQuestionnaire.form)

    useEffect(() => {
        if (form.id === null) {
            dispatch({ type: `QUESTIONNAIRE_CLEAR_QUESTIONS` })
        }
    }, [])

    const formElement = createRef<HTMLFormElement>()

    const isActive = form.id != null && form.status === 2 && new Date(form.visibleFrom).getTime() < new Date().getTime()

    const { defaultExperiencePoints } = useSelector((state: State) => state.gamification)

    const readExperiencePoint = defaultExperiencePoints.find((dep) => dep.name === `questionairecompleted`)
    if (form.experience.value === `` && readExperiencePoint) {
        dispatch({
            type: `QUESTIONNAIRE_CHANGE_EXPERIENCE`,
            experience: readExperiencePoint.experience.toString(),
        })
    }

    if (questionnaire !== null) {
        return <Navigate to="/kerdoivek/lista" />
    }

    const onBackClick = (event: MouseEvent) => {
        event.preventDefault()
        navigate(`/kerdoivek/lista`)
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!checkFields()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await createQuestionaire()
    }

    const checkFields = (): boolean => {
        if (form.imageUrl === '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdőívhez tartozó kép megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.experience.value == '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Tapasztalati pont a kitöltésért megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.title.value == '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdőív megnevezésének megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.lead.value == '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdőív lead megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.description.value == '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdőív leírásának megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (form.numberOfCompleteRequired.value == '') {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Hány ember kell a sikeres kitöltéshez megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (!(form.questions.length > 0)) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdés megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        var emptyQuestionField = false
        var emptyAnswersField = false
        var emptyImagesField = false

        form.questions.some((question) => {
            if (question.questionField.value == '') {
                emptyQuestionField = true
            }

            if (question.type == 1 || question.type == 2) {
                if (question.answers == undefined || question.answers == null || question.answers.length == 0) {
                    emptyAnswersField = true
                }
            }

            if (question.type == 5) {
                if (question.answers == undefined || question.answers == null || question.answers.length == 0) {
                    emptyImagesField = true
                }

                if (question.answers != undefined && question.answers != null && question.answers.length != 0) {
                    question.answers.some((answer) => {
                        if (answer.imageUrl == null || answer.imageUrl == '') {
                            emptyImagesField = true
                        }
                    })
                }
            }
        })

        if (emptyQuestionField) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdés megnevezésének megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (emptyAnswersField) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdés válaszának megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        if (emptyImagesField) {
            setInfoModalTitle('Figyelmeztetés')
            setInfoModalDescription('Kérdés képeinek megadása kötelező!')
            setInfoModalVisibility(true)
            return false
        }

        return true
    }

    const onVisibleFromChange = (date: Date | null, event: any) => {
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_FROM,
            visibleFrom: date!.toISOString(),
        })
    }

    const onVisibleToChange = (date: Date | null, event: any) => {
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBLE_TO,
            visibleTo: date!.toISOString(),
        })
    }

    const onStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_STATUS,
            status: parseInt(event.target.value),
        })

        if (form.id != null) {
            modifyQuestionnaireStatus([form.id], parseInt(event.target.value))
        }
    }

    const onNewsVisibilityChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_VISIBILITY,
            visibility: parseInt(event.target.value),
        })
    }

    const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_TITLE,
            title: event.target.value,
        })
    }

    const onLeadChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_LEAD,
            lead: event.target.value,
        })
    }

    const onNumberOfCompleteRequiredChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_NUMBER_OF_COMPLETE_REQUIRED,
            numberOfCompleteRequired: event.target.value,
        })
    }

    const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_DESCRIPTION,
            description: event.target.value,
        })
    }

    const onQuestionnaireTagButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        dispatch({
            type: CREATE_QUESTIONNAIRE_TAG_ACTIONS.CREATE_QUESTIONNAIRE_TAG_CLEAR,
        })

        setQuestionnaireTagModalVisibility(true)
    }

    const onQuestionnaireTagCloseButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        setQuestionnaireTagModalVisibility(false)
    }

    const onQuestionnaireTagRemoceButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        dispatch({
            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_REMOVE_TAG,
            selectedTagId: parseInt(event.currentTarget.id),
        })
    }

    const onSendPushChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: `QUESTIONNAIRE_CHANGE_SEND_PUSH`,
            sendPush: event.target.value === `1`,
        })
    }

    return (
        <main className="page page-questionnaires page-create-questionnaire">
            <div className="main-div">
                <div className="navigation-title">
                    <NavLink to="/kerdoivek">
                        <h1 className="title-text-h1">Kérdőívek áttekintése {'>'}</h1>
                    </NavLink>
                    <NavLink to="/kerdoivek/lista">
                        <h1 className="title-text-h1">Kérdőívek lista {'>'}</h1>
                    </NavLink>
                    <h1 className="title-text-h1">{form.id == null ? 'Új kérdőív' : 'Kérdőív szerkesztése'} </h1>
                </div>

                <div className="toolbar">
                    <button className="button button--ghost back-button" onClick={onBackClick}>
                        Vissza
                    </button>

                    <button className="button" disabled={isActive} onClick={onSaveButtonClick}>
                        Mentés
                    </button>
                </div>

                {form.id && form.status == NewsStatus.Active ? (
                    <div className="card-div questionnaire-push">
                        <div className="caption">Kérdőív értesítései</div>

                        <button className="button button--primary" style={{ minWidth: 249 }} onClick={(e) => setShowInputModal(true)}>
                            + Új rövid üzenet
                        </button>

                        <div className="questionnaire__messages">
                            {(form.messages.sort((a, b) => b.created - a.created).slice(0, 3) || []).map((message) => (
                                <div key={message.id} className="card-div questionnaire-message">
                                    <div className="questionnaire-message__body">{message.body}</div>
                                    <div className="questionnaire-message__footer">
                                        <div className="questionnaire-message__date">{format(`%mm. %d. %h:%i`, message.created)}</div>
                                        <span
                                            className="questionnaire-message__delete"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onNotificationDelete(message.id)
                                            }}
                                        >
                                            <span className="material-symbols-outlined">delete</span>
                                            Törlés
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}

                <form action="" className="create-news-form" ref={formElement}>
                    <div className="container main-container">
                        <div className="card-div card-div-right-margin">
                            <div className="card-sub-div-right-margin">
                                <label htmlFor="visibilityFrom" className="title-text-h2">
                                    Megjelenés időpontja
                                </label>

                                <DatePicker
                                    selected={new Date(form.visibleFrom)}
                                    onChange={onVisibleFromChange}
                                    showTimeSelect
                                    timeIntervals={1}
                                    minDate={new Date()}
                                    minTime={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())}
                                    maxTime={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59)}
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MM. dd. HH:mm"
                                    disabled={isActive}
                                />
                            </div>

                            <div className="card-sub-div-right-margin">
                                <h2 className="title-text-h2">Elrejtés időpontja</h2>

                                <DatePicker
                                    selected={new Date(form.visibleTo)}
                                    onChange={onVisibleToChange}
                                    showTimeSelect
                                    timeIntervals={1}
                                    minDate={new Date(form.visibleFrom)}
                                    minTime={
                                        new Date(form.visibleFrom).getFullYear() == new Date(form.visibleTo).getFullYear() && new Date(form.visibleFrom).getMonth() == new Date(form.visibleTo).getMonth() && new Date(form.visibleFrom).getDate() == new Date(form.visibleTo).getDate()
                                            ? new Date(new Date(form.visibleFrom).getFullYear(), new Date(form.visibleFrom).getMonth(), new Date(form.visibleFrom).getDate(), new Date(form.visibleFrom).getHours(), new Date(form.visibleFrom).getMinutes())
                                            : new Date(new Date(form.visibleTo).getFullYear(), new Date(form.visibleTo).getMonth(), new Date(form.visibleTo).getDate(), 0, 0)
                                    }
                                    maxTime={
                                        new Date(form.visibleFrom).getFullYear() == new Date(form.visibleTo).getFullYear() && new Date(form.visibleFrom).getMonth() == new Date(form.visibleTo).getMonth() && new Date(form.visibleFrom).getDate() == new Date(form.visibleTo).getDate()
                                            ? new Date(new Date(form.visibleFrom).getFullYear(), new Date(form.visibleFrom).getMonth(), new Date(form.visibleFrom).getDate(), 23, 59)
                                            : new Date(new Date(form.visibleTo).getFullYear(), new Date(form.visibleTo).getMonth(), new Date(form.visibleTo).getDate(), 23, 59)
                                    }
                                    timeFormat="HH:mm"
                                    dateFormat="yyyy. MM. dd. HH:mm"
                                    disabled={isActive}
                                />
                            </div>

                            <div className="">
                                <h2 className="title-text-h2">Státusz</h2>
                                <div className="check-box-group">
                                    <div>
                                        <input checked={form.status == NewsStatus.Active} type="radio" id="status_active" name="status" value={NewsStatus.Active} onChange={onStatusChange} disabled={isActive} />
                                        <label className="label-text" htmlFor="status_active">
                                            Aktív
                                        </label>

                                        <input checked={form.status == NewsStatus.Draft} type="radio" id="status_draft" name="status" value={NewsStatus.Draft} onChange={onStatusChange} disabled={isActive} />
                                        <label className="label-text" htmlFor="status_draft">
                                            Sablon
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* --- kérdőív láthatósága --- */}
                        <div className="card-div card-div-right-margin">
                            <div className="">
                                <h2 className="title-text-h2">Kérdőív láthatósága </h2>
                                <div className="check-box-group">
                                    <div className="input-group-div">
                                        <input checked={form.visibility == NewsVisibility.Open} type="radio" id="news_visibility_open" name="news_visibility" value={NewsVisibility.Open} onChange={onNewsVisibilityChange} disabled={isActive} />
                                        <label className="label-text checkbox-label" htmlFor="news_visibility_open">
                                            Külső ív
                                        </label>

                                        <input checked={form.visibility == NewsVisibility.Internal} type="radio" id="news_visibility_internal" name="news_visibility" value={NewsVisibility.Internal} onChange={onNewsVisibilityChange} disabled={isActive} />
                                        <label className="label-text checkbox-label" htmlFor="news_visibility_internal">
                                            Belső ív
                                        </label>

                                        <input checked={form.visibility == NewsVisibility.Closed} type="radio" id="news_visibility_closed" name="news_visibility" value={NewsVisibility.Closed} onChange={onNewsVisibilityChange} disabled={isActive} />
                                        <label className="label-text checkbox-label" htmlFor="news_visibility_closed">
                                            Zárt ív
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-div card-div--push">
                        <h2 className="title-text-h2">“Értesítés” küldése</h2>
                        <div>
                            <div className="check-box-group">
                                <div className="input-group-div">
                                    <input checked={form.sendPush === true} type="radio" id="questionnaire_send_push_true" name="questionnaire_send_push" value="1" onChange={onSendPushChange} disabled={isActive} />
                                    <label className="label-text checkbox-label" htmlFor="questionnaire_send_push_true">
                                        A kérdőív élesedésénél kapjanak “értesítést”
                                    </label>

                                    <input checked={form.sendPush === false} type="radio" id="questionnaire_send_push_false" name="questionnaire_send_push" value="0" onChange={onSendPushChange} disabled={isActive} />
                                    <label className="label-text checkbox-label" htmlFor="questionnaire_send_push_false">
                                        Ne kapjanak “értesítést”
                                    </label>
                                </div>
                            </div>

                            <button className="button button--ghost" disabled={form.id === null} onClick={sendPush}>
                                Azonnali Push küldés
                            </button>
                        </div>
                    </div>

                    <div style={{ display: `flex` }}>
                        <div className="card-div" style={{ marginTop: 30, width: 405 }}>
                            <TagSelector tags={form.tags} onTagAdd={(tag) => dispatch({ type: `QUESTIONNAIRE_ADD_TAG`, tag: tag })} onTagDelete={(tag) => dispatch({ type: `QUESTIONNAIRE_REMOVE_TAG`, tag: tag })} />
                        </div>

                        <div
                            className="card-div"
                            style={{
                                marginTop: 30,
                                marginLeft: 30,
                                width: `calc( 100% - 42px - 405px - 30px )`,
                            }}
                        >
                            <div className="xp-editor">
                                <img alt="" src="/image/exp.svg" />

                                <div className="field">
                                    <label>Tapasztalati pont a kitöltésért (300-500 TP) *</label>
                                    <TextField field={form.experience} onChange={(e) => changeWithinLimits(`QUESTIONNAIRE_CHANGE_EXPERIENCE`, `experience`, e, 300, 500)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <TabView tabs={{ questions: 'Kérdések', answers: 'Válaszok' }}>
                        <TabViewPage key="questions">
                            {/* --- Kéréds tartalmi elemeinek megadása --- */}
                            <div className="container container-top-margin">
                                <div className="card-div">
                                    <div className="container column">
                                        {/* --- Kép--- */}

                                        <div className="image-div-container">
                                            <AddImage
                                                width={'255px'}
                                                height={'148px'}
                                                imageUrl={form.imageUrl}
                                                text={'Kép hozzáadása a kérdőívhez (16:9)'}
                                                onAddImage={() => setMediaLibraryVisibility(true)}
                                                onDeleteImage={() => {
                                                    setMediaLibraryFile(null)
                                                    dispatch({
                                                        type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_MEDIA_LIBRARY_FILE,
                                                        mediaLibraryFile: null,
                                                    })
                                                }}
                                                disabled={isActive}
                                            />
                                        </div>

                                        {/* --- Lead szöveg megadása--- */}
                                        <div className="text-section-container container-top-margin">
                                            <div className="input-container-header container-top-margin">
                                                <h3 className="title-text-h3 title-text-bottom-padding">Kérdőív megnevezése *</h3>
                                            </div>
                                            <TextField field={form.title} maxlength={255} onChange={onTitleChange} placeholder="" category="position" disabled={isActive} />

                                            <div className="input-container-header container-top-margin">
                                                <h3 className="title-text-h3 title-text-bottom-padding">Kérdőív lead megadása *</h3>
                                            </div>
                                            <TextField field={form.lead} maxlength={255} onChange={onLeadChange} placeholder="" category="position" disabled={isActive} />

                                            <div className="input-container-header container-top-margin">
                                                <h3 className="title-text-h3 title-text-bottom-padding">Kérdőív leírásának megadása *</h3>
                                                <span className="label-text info">Írd le, hogy milyen kérdőívet tölt ki a felhasználó, milyen előnyei származnak ebből? </span>
                                            </div>
                                            <TextArea field={form.description} onChange={onDescriptionChange} placeholder="" category="position" disabled={isActive} />

                                            <div className="input-container-header container-top-margin">
                                                <h3 className="title-text-h3 title-text-bottom-padding">Hány ember kell a sikeres kitöltéshez? *</h3>
                                            </div>
                                            <NumberField field={form.numberOfCompleteRequired} onChange={onNumberOfCompleteRequiredChange} category="position" maxlength={8} disabled={isActive} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* --- Kérdések --- */}
                            <div className="">
                                <QuestionnaireQuestionTable questionArray={form.questions || []} disabled={isActive} />
                            </div>
                        </TabViewPage>

                        <TabViewPage key="answers">
                            <QuestionnaireUserAnswerTable questionArray={form.questions || []} disabled={isActive} />
                        </TabViewPage>
                    </TabView>
                </form>

                <footer>
                    <button className="button" disabled={isActive} onClick={onSaveButtonClick}>
                        Mentés
                    </button>
                </footer>
            </div>

            {mediaLibraryVisibility ? (
                <MediaLibrary
                    onClose={() => setMediaLibraryVisibility(false)}
                    onMediaSelected={(file: MediaLibraryFile) => {
                        setMediaLibraryFile(file)
                        setMediaLibraryVisibility(false)
                        setImageEditorVisibility(true)
                    }}
                />
            ) : null}

            {imageEditorVisibility ? (
                <ImageEditor
                    mediaLibraryFile={mediaLibraryFile!}
                    aspectRatio={{ name: '16:9', ratio: 16 / 9 }}
                    onClose={() => setImageEditorVisibility(false)}
                    onReady={(file) => {
                        dispatch({
                            type: CREATE_QUESTIONNAIRE_ACTIONS.CREATE_QUESTIONNAIRE_CHANGE_MEDIA_LIBRARY_FILE,
                            mediaLibraryFile: file,
                        })
                        setImageEditorVisibility(false)
                    }}
                />
            ) : null}

            {questionnaireTagModalVisibility ? <QuestionnaireTagModal onCloseClick={onQuestionnaireTagCloseButtonClick} /> : null}

            {showConfirmDelete ? (
                <Modal className="confirm-delete-message-modal" onModalClose={() => setShowConfirmDelete(false)}>
                    <div className="modal-title">Értesítés törlése</div>
                    <div className="modal-description">Biztosan törlöd az értesítést?</div>
                    <footer>
                        <button className="button button--ghost" onClick={() => setShowConfirmDelete(false)}>
                            Mégse
                        </button>
                        <button className="button button--primary" onClick={deleteMessage}>
                            Törlés
                        </button>
                    </footer>
                </Modal>
            ) : null}

            {showInputModal ? (
                <InputModal
                    title="Új értesítés"
                    placeholder=""
                    field={{ error: '', required: true, value: notificationValue }}
                    onInputChange={(event) => {
                        setNotificationValue((event as any).target.value)
                    }}
                    onCloseClick={() => setShowInputModal(false)}
                    onSaveClick={onNotificationSave}
                    onModalClose={() => setShowInputModal(false)}
                />
            ) : null}

            {infoModalVisibility ? (
                <MessageModal
                    title={infoModalTitle}
                    message={infoModalDescription}
                    onCloseClick={() => {
                        setInfoModalVisibility(false)
                    }}
                />
            ) : null}
        </main>
    )
}
