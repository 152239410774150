import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { GetUserDTO } from '../../dto'
import { store } from '../../store'

export const getUser = async ( membershipId: string ) => {

    let body: GetUserDTO
    let response: Response

    try {

        response = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/1.0.0/cms/users/${ membershipId }`, {
            headers: {
                'Authorization': 'Bearer ' + store.getState().session.token,
                'Content-Type': 'application/json'
            },
            method: 'get'
        } )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USER_TIMEOUT' } )
        return

    }

    if ( response.status === 401 ) {

        store.dispatch( { type: 'GET_USER_UNAUTHORIZED' } )
        return

    }

    if ( response.status !== 200 ) {

        store.dispatch( { type: 'GET_USER_UNEXPECTEDRESPONSE' } )
        return

    }

    try {

        body = plainToClass( GetUserDTO, JSON.parse( await response.text() ) )

    } catch ( exception ) {

        store.dispatch( { type: 'GET_USER_INVALIDJSON' } )
        return

    }

    if ( ( await validate( body ) ).length > 0 ) {

        store.dispatch( { type: 'GET_USER_INVALIDRESPONSE' } )
        return

    }

    store.dispatch( { type: 'GET_USER_OK', user: body.user } )
    return body.user

}
