import './lesson.page.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state'
import { TextField } from '../../../component/field'
import { useState } from 'react'
import { TextArea } from '../../../component/textarea'
import { LessonPage } from '../../../component/lesson-page'
import { LessonQuizQuestion } from '../../../component/lesson-quiz-question'
import { saveLesson } from '../../../action/courses'
import { MessageModal } from '../../../component/modal'

export const Lesson = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ activeTab, setActiveTab ] = useState( 0 )
    const { course, lesson } = useSelector( ( state: State ) => state.courses )


    const [ infoModalVisibility, setInfoModalVisibility ] = useState( false )
    const [ infoModalTitle, setInfoModalTitle ] = useState( `` )
    const [ infoModalDescription, setInfoModalDescription ] = useState( `` )

    const checkFields = (): boolean => {


        if ( ! lesson!.name || lesson!.name.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke megnevezésének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.length || lesson!.length.toString().trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke elvégzési idejének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( isNaN( lesson!.length ) ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke elvégzési ideje csak szám lehet!` )
            setInfoModalVisibility( true )

            return false

        }


        if ( ! lesson!.oneStarExperience || lesson!.oneStarExperience.toString().trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke egy csillagos tapasztalati pont értékének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( isNaN( lesson!.oneStarExperience ) ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke egy csillagos tapasztalati pont értéke csak szám lehet!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.twoStarExperience || lesson!.twoStarExperience.toString().trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke két csillagos tapasztalati pont értékének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( isNaN( lesson!.twoStarExperience ) ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke két csillagos tapasztalati pont értéke csak szám lehet!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.threeStarExperience || lesson!.threeStarExperience.toString().trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke három csillagos tapasztalati pont értékének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( isNaN( lesson!.threeStarExperience ) ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke három csillagos tapasztalati pont értéke csak szám lehet!` )
            setInfoModalVisibility( true )

            return false

        }


        if ( ! lesson!.oneStarCongratulation || lesson!.oneStarCongratulation.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke egy csillagos zárószövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.twoStarCongratulation || lesson!.twoStarCongratulation.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke két csillagos zárószövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.threeStarCongratulation || lesson!.threeStarCongratulation.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke három csillagos zárószövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }


        if ( ! lesson!.welcomeScreenTitle || lesson!.welcomeScreenTitle.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke welcome screen címének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.welcomeScreenLead || lesson!.welcomeScreenLead.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke welcome screen leadjének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.welcomeScreenDescription || lesson!.welcomeScreenDescription.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke welcome screen szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }


        if ( ! lesson!.quiz.welcomeScreenTitle || lesson!.quiz.welcomeScreenTitle.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke kvíz welcome screen címének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.quiz.welcomeScreenLead || lesson!.quiz.welcomeScreenLead.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke kvíz welcome screen leadjének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        if ( ! lesson!.quiz.welcomeScreenDescription || lesson!.quiz.welcomeScreenDescription.trim().length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke kvíz welcome screen szövegének megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        for ( const lessonPage of lesson!.pages ) {

            if ( ! lessonPage.lead || lessonPage.lead.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A tananyag leadjének megadása mindenhol kötelező!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( ! lessonPage.description || lessonPage.description.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A tananyag szövegének megadása kötelező a következő tartalomnál: "${ lessonPage.lead }"!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( lessonPage.type === 1 && lessonPage.image === null ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A tananyag képének kiválasztása kötelező a következő tartalomnál: "${ lessonPage.lead }"!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( lessonPage.type === 2 && ( ! lessonPage.videoUrl || lessonPage.videoUrl.trim().length === 0 ) ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A tananyag videó urljének megadása kötelező a következő tartalomnál: "${ lessonPage.lead }"!` )
                setInfoModalVisibility( true )

                return false

            }

        }

        if ( lesson!.quiz.questions.length === 0 ) {

            setInfoModalTitle( `Figyelmeztetés` )
            setInfoModalDescription( `A lecke kvízéhez legalább egy kérdés megadása kötelező!` )
            setInfoModalVisibility( true )

            return false

        }

        for ( const question of lesson!.quiz.questions ) {

            if ( ! question.title || question.title.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A kvíz kérdés megnevezésének megadása mindenhol kötelező!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( ! question.goodAnswerResponse || question.goodAnswerResponse.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A házigazda jó válasz esetén adott szövegének megadása kötelező a következő kérdésnél: "${ question.title }"!` )
                setInfoModalVisibility( true )

                return false

            }

            if ( ! question.badAnswerResponse || question.badAnswerResponse.trim().length === 0 ) {

                setInfoModalTitle( `Figyelmeztetés` )
                setInfoModalDescription( `A házigazda rossz válasz esetén adott szövegének megadása kötelező a következő kérdésnél: "${ question.title }"!` )
                setInfoModalVisibility( true )

                return false

            }

            for ( let i = 0; i < 4; i++ ) {

                const condition = ( question.type === 0 ) ? ( ! question.answers[ i ].answer || question.answers[ i ].answer.trim().length === 0 ) : ( question.answers[ i ].image === null )

                if ( condition ) {

                    setInfoModalTitle( `Figyelmeztetés` )
                    setInfoModalDescription( `Minden válaszlehetőség megadása kötelező a következő kérdésnél: "${ question.title }"!` )
                    setInfoModalVisibility( true )

                    return false

                }

            }

        }

        return true

    }

    const u = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_LESSON`, field, value } )

    }

    const uQ = ( field: string, value: any ) => {

        dispatch( { type: `UPDATE_LESSON_QUIZ`, field, value } )

    }

    const createLessonPage = () => {

        dispatch( { type: `CREATE_LESSON_PAGE` } )

    }

    const createQuizQuestion = () => {

        dispatch( { type: `CREATE_QUIZ_QUESTION` } )

    }

    const onSave = async () => {

        if ( !checkFields() ) return
        const l = await saveLesson( course!.id, lesson )
        if ( l !== null ) {

            navigate( `/kepzesek/${ course!.id }/leckek/${ l.id }` )

        }

    }

    return (

        <main className="page page--lesson">

            <div className="main-div">

                <div className="navigation-title">

                    <NavLink to="/kepzesek">

                        <h1 className="title-text-h1">Képzések &gt;</h1>

                    </NavLink>

                    <NavLink to="/kepzesek/lista">

                        <h1 className="title-text-h1">Képzések lista &gt;</h1>

                    </NavLink>

                    <NavLink to={ `/kepzesek/${ course!.id }` }>

                        <h1 className="title-text-h1">{ course!.title } &gt;</h1>

                    </NavLink>

                    <h1 className="title-text-h1">{ ( lesson as any ).id === null ? `Új lecke` : lesson!.name }</h1>

                </div>

                <div className="toolbar">

                    <NavLink to={ `/kepzesek/${ course!.id }` }>

                        <button className="button button--ghost">Vissza</button>

                    </NavLink>

                    <button className="button button--primary" onClick={ onSave }>Mentés</button>

                </div>

                <div className="grid">

                    <div className="card-div">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Lecke szerkesztése</div>

                                <div className="caption caption--small">Lecke megnevezése</div>
                                <div className="info">Ajánlott karakter szám maximum: 120 karakter</div>
                                <TextField field={ { value: lesson!.name, error: `` } } onChange={ e => u( `name`, e.target.value ) } />

                                <div className="caption caption--small">Elvégzés ideje (percben)</div>
                                <div className="info"></div>
                                <TextField field={ { value: lesson!.length.toString(), error: `` } } onChange={ e => u( `length`, e.target.value ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--01">

                        <div className="cols">

                            <div className="col">

                                <div className="caption">Lecke tapasztalati értéke és záró mondatai</div>

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col lesson-experience"></div>

                            <div className="col lesson-onestar-experience">

                                <div className="caption caption--small">1 csillag</div>

                            </div>

                            <div className="col lesson-twostar-experience">

                                <div className="caption caption--small">2 csillag</div>

                            </div>

                            <div className="col lesson-threestar-experience">

                                <div className="caption caption--small">3 csillag</div>

                            </div>

                        </div>

                        <div className="cols lesson-experiences">

                            <div className="col lesson-experience">

                                <img alt="" src="/image/exp.svg" />

                            </div>

                            <div className="col lesson-onestar-experience">

                                <TextField field={ { value: lesson!.oneStarExperience.toString(), error: `` } } onChange={ e => u( `oneStarExperience`, e.target.value ) } />

                            </div>

                            <div className="col lesson-twostar-experience">

                                <TextField field={ { value: lesson!.twoStarExperience.toString(), error: `` } } onChange={ e => u( `twoStarExperience`, e.target.value ) } />

                            </div>

                            <div className="col lesson-threestar-experience">

                                <TextField field={ { value: lesson!.threeStarExperience.toString(), error: `` } } onChange={ e => u( `threeStarExperience`, e.target.value ) } />

                            </div>

                        </div>

                        <div className="cols">

                            <div className="col">

                                <div className="caption caption--small">1 csillag zárószövege</div>
                                <div className="info"></div>
                                <TextField field={ { value: lesson!.oneStarCongratulation, error: `` } } onChange={ e => u( `oneStarCongratulation`, e.target.value ) } />

                                <div className="caption caption--small">2 csillag zárószövege</div>
                                <div className="info"></div>
                                <TextField field={ { value: lesson!.twoStarCongratulation, error: `` } } onChange={ e => u( `twoStarCongratulation`, e.target.value ) } />

                                <div className="caption caption--small">3 csillag zárószövege</div>
                                <div className="info"></div>
                                <TextField field={ { value: lesson!.threeStarCongratulation, error: `` } } onChange={ e => u( `threeStarCongratulation`, e.target.value ) } />

                            </div>

                        </div>

                    </div>

                    <div className="card-div card-div--02">

                        <div className="tabs">

                            <div className={ `tab ${ activeTab === 0 ? `tab--active` : `` }` } onClick={ () => setActiveTab( 0 ) }>

                                Tananyag

                            </div>

                            <div className={ `tab ${ activeTab === 1 ? `tab--active` : `` }` } onClick={ () => setActiveTab( 1 ) }>

                                Teszt

                            </div>

                        </div>

                    </div>

                    { activeTab === 0 ? (

                        <div className="lesson-pages">

                            <div className="card-div card-div--03">

                                <div className="cols">

                                    <div className="col">

                                        <div className="caption caption--small">Welcome screen címe *</div>
                                        <div className="info"></div>
                                        <TextField field={ { value: lesson!.welcomeScreenTitle, error: `` } } onChange={ e => u( `welcomeScreenTitle`, e.target.value ) } />

                                        <div className="caption caption--small">Welcome screen lead *</div>
                                        <div className="info"></div>
                                        <TextArea field={ { value: lesson!.welcomeScreenLead, error: `` } } onChange={ e => u( `welcomeScreenLead`, e.target.value ) } />

                                        <div className="caption caption--small">Welcome screen szövege *</div>
                                        <div className="info"></div>
                                        <TextArea field={ { value: lesson!.welcomeScreenDescription, error: `` } } onChange={ e => u( `welcomeScreenDescription`, e.target.value ) } />

                                    </div>

                                </div>

                            </div>

                            { lesson!.pages.map( lessonPage => (

                                <LessonPage key={ lessonPage.id } page={ lessonPage } />

                            ) ) }

                            <footer>

                                <div className="new-lesson-page" onClick={ createLessonPage }>

                                    <img alt="" src="/image/add.svg" />
                                    <span>Új tananyag hozzáadása</span>

                                </div>

                            </footer>

                        </div>

                    ) : null }

                    { activeTab === 1 ? (

                        <div className="quiz-questions">

                            <div className="card-div card-div--03">

                                <div className="cols">

                                    <div className="col">

                                        <div className="caption caption--small">Welcome screen címe *</div>
                                        <div className="info"></div>
                                        <TextField field={ { value: lesson!.quiz.welcomeScreenTitle, error: `` } } onChange={ e => uQ( `welcomeScreenTitle`, e.target.value ) } />

                                        <div className="caption caption--small">Welcome screen lead *</div>
                                        <div className="info"></div>
                                        <TextArea field={ { value: lesson!.quiz.welcomeScreenLead, error: `` } } onChange={ e => uQ( `welcomeScreenLead`, e.target.value ) } />

                                        <div className="caption caption--small">Welcome screen szövege *</div>
                                        <div className="info"></div>
                                        <TextArea field={ { value: lesson!.quiz.welcomeScreenDescription, error: `` } } onChange={ e => uQ( `welcomeScreenDescription`, e.target.value ) } />

                                    </div>

                                </div>

                            </div>

                            { lesson!.quiz.questions.map( question => (

                                <LessonQuizQuestion key={ question.id } question={ question } />

                            ) ) }

                            <footer>

                                <div className="new-lesson-page" onClick={ createQuizQuestion }>

                                    <img alt="" src="/image/add.svg" />
                                    <span>Új kérdés hozzáadása</span>

                                </div>

                            </footer>

                        </div>

                    ) : null }

                </div>

                <div className="toolbar toolbar--bottom">

                    <button className="button button--primary" onClick={ onSave }>Mentés</button>

                </div>

            </div>

            { infoModalVisibility ? (

                <MessageModal title={ infoModalTitle } message={ infoModalDescription } onCloseClick={ () => setInfoModalVisibility( false ) } />

            ) : null }

        </main>

    )

}