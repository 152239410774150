
import { CREATE_FLOATING_MESSAGE_ACTIONS, DELETE_FLOATING_MESSAGE_ACTIONS, GET_FLOATING_MESSAGES_ACTIONS } from '../action/floating-messages/floating-messages.constants'
import { FloatingMessagesState } from '../state'
import { message } from '../helper'

const initialFloatingMessagesState: FloatingMessagesState = {
    form: {
        id: null,
        message: {
            error: '',
            value: '',
            required: true
        },
        type: 1,
        imageId: 1,
    },
    search: "",
    order: 'DESC',
    floatingMessages: [],
    pagination: {
        count: 0,
        from: 0,
        page: 1,
        to: 0
    }
}

export const floatingMessageReducer = ( state: FloatingMessagesState = initialFloatingMessagesState, action: any ): FloatingMessagesState => {
    switch ( action.type ) {
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_TYPE:
            return { ...state, form: { ...state.form, type: action.typeId } }
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_MESSAGE:
            return { ...state, form: { ...state.form, message: { ...state.form.message, value: action.message } } }
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CHANGE_IMAGE_ID:
            return { ...state, form: { ...state.form, imageId: action.imageId } }
        case DELETE_FLOATING_MESSAGE_ACTIONS.DELETE_FLOATING_MESSAGE_OK:
            message( `A floating üzenet sikeresen törlésre került!` )
            return state
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_OK:
            message( `A floating üzenet sikeresen mentésre került!` )
            return { ...state, form: { ...state.form, message: { ...state.form.message, value: "" }, imageId: 1, type: 1 } }
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_CLEAR:
            return { ...state, form: { ...state.form, message: { ...state.form.message, value: "" }, imageId: 1, type: 1 } }
        case CREATE_FLOATING_MESSAGE_ACTIONS.CREATE_FLOATING_MESSAGE_MODIFY_FLOATING_MESSAGE:
            return { ...state, form: { ...state.form, id: action.floatingMessage.id, message: { ...state.form.message, value: action.floatingMessage.message }, imageId: action.floatingMessage.imageId, type: action.floatingMessage.type} }

        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_SEARCH:
            return { ...state, search: action.search }
        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_ORDER:
            return { ...state, order: state.order == 'ASC' ? 'DESC' : 'ASC'}
        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_OK:
            return { ...state, floatingMessages: action.floatingMessages, pagination: action.pagination }
        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_INVALID_JSON:
            return { ...state, floatingMessages: [] }
        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_INVALID_RESPONSE:
            return { ...state, floatingMessages: [] }
        case GET_FLOATING_MESSAGES_ACTIONS.GET_FLOATING_MESSAGES_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } } 
        default:
            return state
    }
}

