import { TabView } from "../tab-view";
import { TabViewPage } from "../tab-view/tab-view-page";
import { Quest } from "../quest";
import { Quest as QuestEntity } from "../../entity";
import "./quest-card.scss";
import { useDispatch } from "react-redux";
import { saveQuest, sendPush } from "../../action/gamification";
import { Table } from "../table";
import { county, position } from "../../helper";
import { TextArea } from "../textarea";
import { useState } from "react";
import { MessageModal } from "../modal";

interface QuestCardProps {
  quest: QuestEntity;
  hideLeagues?: boolean;
  index: number;

  onDelete?: () => void;
}

const leagues = [
  { id: 2, name: `Bronz liga`, icon: `/image/leagues/0002.png` },
  { id: 3, name: `Ezüst liga`, icon: `/image/leagues/0003.png` },
  { id: 4, name: `Arany liga`, icon: `/image/leagues/0004.png` },
  { id: 5, name: `DK liga`, icon: `/image/leagues/0005.png` },
];

export const QuestCard = (props: QuestCardProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [pushMessage, setPushMessage] = useState(``);

  const [infoModalVisibility, setInfoModalVisibility] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalDescription, setInfoModalDescription] = useState("");

  const onSaveQuest = async () => {
    if (!checkFields()) {
      return;
    }

    const id = await saveQuest(props.quest.id, props.quest);
    if (id) {
      dispatch({
        type: `QUEST_UPDATE_FIELD`,
        index: props.index,
        field: `id`,
        value: id,
      });
    }
  };

  const checkFields = (): boolean => {
    if (props.quest.name.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Feladat megnevezésének megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }

    return true;
  };

  const onDelete = () => {
    if (props.onDelete) props.onDelete();
  };
  const onFieldChange = (field: string, value: any) =>
    dispatch({
      type: `QUEST_UPDATE_FIELD`,
      index: props.index,
      field: field,
      value: value,
    });

  const onSendClick = async () => {
    if (pushMessage.trim().length === 0) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("A Push szöveg megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    await sendPush(
      props.quest.name,
      pushMessage,
      props.quest.selectedLeagues
        ? props.quest.selectedLeagues
        : props.quest.leagues.map((l) => l.id)
    );
  };

  return (
    <div
      id={(props.quest as any).uuid}
      className={`card-div quest-card${open ? ` quest-card--open` : ``}`}
    >
      {open ? (
        <TabView
          tabs={{
            settings: `Beállítások`,
            stats: `Statisztika`,
            push: `Értesítések`,
          }}
        >
          <TabViewPage key="settings">
            <Quest
              quest={props.quest}
              hideFooter={true}
              onFieldChange={onFieldChange}
              hideLeagues={props.hideLeagues === true}
            />

            <footer>
              <button
                className="button button--primary"
                style={{ minWidth: 182 }}
                onClick={onSaveQuest}
              >
                Mentés
              </button>
              <button
                className="button button--ghost button--warning"
                style={{ minWidth: 234 }}
                onClick={onDelete}
              >
                Küldetés törlése
              </button>
              <button
                className="button button--expand"
                onClick={() => setOpen(false)}
              >
                <img src="/image/chevron-left.svg" />
              </button>
            </footer>
          </TabViewPage>

          <TabViewPage key="stats">
            {props.quest.stats ? (
              <div className="stat-cards">
                <div className="card-div">
                  <div className="label">Összes felhasználó a kihíváson:</div>
                  <div className="value">{props.quest.stats.users} fő</div>
                </div>

                <div className="card-div">
                  <div className="label">Elvégezte:</div>
                  <div className="value">{props.quest.stats.users} fő</div>
                </div>

                <div className="card-div">
                  <div className="label">Elvégezte 50 év alatt:</div>
                  <div className="value">
                    {props.quest.stats.completedUnder50Percent}%
                  </div>
                </div>

                <div className="card-div">
                  <div className="label">Innen oldották meg a legtöbben:</div>
                  <div className="value">
                    {props.quest.stats.mostCompletedFromCounty === -1
                      ? `Nem meghatározható`
                      : county(props.quest.stats.mostCompletedFromCounty)}
                  </div>
                </div>

                <div className="card-div">
                  <div className="label">Átlagos elvégzési idő:</div>
                  <div className="value">
                    {props.quest.stats.averageCompletionTime} nap
                  </div>
                </div>
              </div>
            ) : (
              <>Még nem jeleníthetőek meg statisztikák ehhez a küldetéshez!</>
            )}

            {/*
                    <div className="users-completed">

                        <div className="caption">Felhasználók, akik elvégezték a kihívást:</div>

                        <Table columns={ [
                            {
                                align: `center`,
                                name: `id`,
                                headerRender: () => `ID`,
                                render: user => user.membershipId,
                                width: 150
                            },
                            {
                                align: `left`,
                                name: `name`,
                                headerRender: () => `Név`,
                                render: user => `${ user.lastName } ${ user.firstName }`,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `county`,
                                headerRender: () => `Megye`,
                                render: user => county( user.county ),
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `position`,
                                headerRender: () => `Pozíció`,
                                render: user => position( user.position ),
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `experience`,
                                headerRender: () => `TP`,
                                render: user => user.experience,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `interest`,
                                headerRender: () => `Érdeklődés`,
                                render: user => ``,
                                width: 300
                            },
                            {
                                align: `center`,
                                name: `league`,
                                headerRender: () => `Liga`,
                                render: user => user.league?.name,
                                width: 300
                            }
                        ] } dataset={ [] } />

                    </div>
                    */}
          </TabViewPage>

          <TabViewPage key="push">
            <div className="push-form">
              <div className="field">
                <label>Push szöveg megadadása *</label>
                <TextArea
                  field={{ value: pushMessage, error: `` }}
                  onChange={(e) => setPushMessage(e.target.value)}
                />
              </div>

              <footer>
                <button
                  className="button button--primary"
                  onClick={onSendClick}
                  style={{ minWidth: 176 }}
                >
                  Küldés
                </button>
              </footer>
            </div>
          </TabViewPage>
        </TabView>
      ) : (
        <div className="quest-card-header">
          <div className="quest-card-header__title">{props.quest.name}</div>
          <div className="quest-card-header__leagues">
            {leagues.map((league) => (
              <div
                className={`league${
                  props.quest.selectedLeagues
                    ? props.quest.selectedLeagues?.indexOf(league.id) !== -1
                      ? ` league--selected`
                      : ``
                    : props.quest.leagues
                        .map((l) => l.id)
                        .indexOf(league.id) !== -1
                    ? ` league--selected`
                    : ``
                }`}
              >
                <div className="league__icon">
                  <img src={league.icon} />
                </div>
              </div>
            ))}
          </div>
          <button
            className="button button--expand"
            onClick={() => setOpen(true)}
          >
            <img src="/image/chevron-left.svg" />
          </button>
        </div>
      )}

      {infoModalVisibility ? (
        <MessageModal
          title={infoModalTitle}
          message={infoModalDescription}
          onCloseClick={() => {
            setInfoModalVisibility(false);
          }}
        />
      ) : null}
    </div>
  );
};
