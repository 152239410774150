import { ChangeEvent, createRef, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { createNews } from "../../action";
import { sendPushMessage } from "../../action/firebase";
import { CREATE_NEWS_ACTIONS } from "../../action/news/news.constants";
import { Dropdown, InfoTextbox } from "../../component";
import { AddImage } from "../../component/add-image";
import { TextField } from "../../component/field";
import { ImageEditor } from "../../component/image-editor/image-editor";
import { MediaLibrary } from "../../component/media-library";
import { NewsModal } from "../../component/news";
import { PoliticianCard, PoliticianModal } from "../../component/politicians";
import { TextArea } from "../../component/textarea";
import { DescriptionTextArea } from "../../component/textarea/description";
import {
  ImageFile,
  MediaLibraryFile,
  News,
  NewsCategory,
  Politician,
} from "../../entity";
import { NewsStatus } from "../../enum/news-status.enum";
import { NewsVisibility } from "../../enum/news-visibility.enum";
import { State } from "../../state";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./create-news.page.scss";
import { TabView } from "../../component/tab-view";
import { TabViewPage } from "../../component/tab-view/tab-view-page";
import { NewsStatsTab } from "./components/news-stats.tab";
import { MessageModal } from "../../component/modal";
import { TagSelector } from "../../component/tag-selector";

interface CreateNewsProps {}

export const CreateNews = (props: CreateNewsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { defaultExperiencePoints } = useSelector(
    (state: State) => state.gamification
  );

  const [mediaLibraryFile, setMediaLibraryFile] = useState(
    null as MediaLibraryFile | null
  );
  const [mediaLibraryVisibility, setMediaLibraryVisibility] = useState(false);
  const [imageEditorVisibility, setImageEditorVisibility] = useState(false);
  const [infoModalVisibility, setInfoModalVisibility] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalDescription, setInfoModalDescription] = useState("");

  const saveDate = useSelector((state: State) => state.createNews.saveDate);
  const news = useSelector((state: State) => state.createNews.news);
  const newsCategories = useSelector(
    (state: State) => state.newsCategories.newsCategories
  );
  const form = useSelector((state: State) => state.createNews.form);
  const correctAnswerId = useSelector(
    (state: State) => state.createNews.form.question.correctAnswerId
  );
  const relatedPoliticians = useSelector(
    (state: State) => state.createNews.form.relatedPoliticians
  );

  const changeWithinLimits = (
    type: string,
    fieldName: string,
    e: ChangeEvent<HTMLInputElement>,
    low: number,
    high: number
  ) => {
    let value = e.target.value;
    if (value.trim().length === 0) {
      value = low.toString();
    }
    if (isNaN(parseInt(value))) {
      return;
    }
    if (parseInt(value) > high) {
      value = high.toString();
    }
    if (parseInt(value) < low) {
      value = low.toString();
    }

    const action: any = { type: type };
    action[fieldName] = value;
    dispatch(action);
  };

  const formElement = createRef<HTMLFormElement>();

  const [previewNews, setPreviewNews] = useState<News | null>(null);
  const [polticiansModalVisibility, setPolticiansModalVisibility] =
    useState(false);

  let categoriesElements = [{ value: "Válassz egy kategóriát", id: -1 }];
  if (
    newsCategories !== null &&
    newsCategories !== undefined &&
    newsCategories.length != 0
  ) {
    categoriesElements = [];
    newsCategories.map((newsCategoryElement: NewsCategory) => {
      categoriesElements.push({
        value: newsCategoryElement.name,
        id: newsCategoryElement.id,
      });
    });
  }

  useEffect(() => {
    if (categoriesElements.length > 0) {
      if (
        categoriesElements.filter((e) => e.id === form.category).length == 0
      ) {
        //default kategória kiválasztása
        dispatch({
          type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_CATEGORY,
          category: categoriesElements[0].id,
        });
      }
    }
  }, []);

  const readExperiencePoint = defaultExperiencePoints.find(
    (dep) => dep.name === `newsread`
  );
  if (form.experience.value === `` && readExperiencePoint) {
    dispatch({
      type: `CREATE_NEWS_CHANGE_EXPERIENCE`,
      experience: readExperiencePoint.experience.toString(),
    });
  }

  const quizExperiencePoint = defaultExperiencePoints.find(
    (dep) => dep.name === `newsquiz`
  );
  if (form.quizExperience.value === `` && quizExperiencePoint) {
    dispatch({
      type: `CREATE_NEWS_CHANGE_QUIZ_EXPERIENCE`,
      quizExperience: quizExperiencePoint.experience.toString(),
    });
  }

  if (news !== null) {
    return <Navigate to="/hirek/lista" />;
  }

  const onBackClick = (event: MouseEvent) => {
    event.preventDefault();
    navigate(`/hirek/lista`);
  };

  const onPreviewButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    const news = new News();

    if (mediaLibraryFile != null) {
      news.imageFile = new ImageFile();
      news.imageFile.url = mediaLibraryFile?.url ?? "";
    }

    if (form.imageUrl != null && form.imageUrl.length != 0) {
      news.imageFile = new ImageFile();
      news.imageFile.url = form.imageUrl ?? "";
    }

    news.title = form.title.value;
    news.statementBy = form.statementBy.value;
    news.lead = form.lead.value;
    news.description = form.description.value;
    news.visibleFrom = new Date(form.visibleFrom).getTime() / 1000;
    news.highlighted = form.highlighted == 0 ? false : true;

    const category = new NewsCategory();

    if (newsCategories !== null && newsCategories !== undefined) {
      newsCategories.map((newsCategoryElement: NewsCategory) => {
        if (newsCategoryElement.id === form.category) {
          category.name = newsCategoryElement.name;
          category.color = newsCategoryElement.color;
        }
      });
    }

    news.category = category;

    setPreviewNews(news);
  };

  const onPreviewCloseButtonClick = (event: MouseEvent) => {
    setPreviewNews(null);
  };

  const onAddPoliticianButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    setPolticiansModalVisibility(true);
  };

  const onAddPoliticianCloseButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    setPolticiansModalVisibility(false);
  };

  const onSaveButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    if (!checkFields()) {
      return;
    }

    if (!formElement.current?.reportValidity()) {
      return;
    }

    await createNews();
  };

  const checkFields = (): boolean => {
    if (form.experience.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription(
        "Tapasztalati pont a végigolvasásért megadása kötelező!"
      );
      setInfoModalVisibility(true);
      return false;
    }
    if (form.quizExperience.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Tapasztalati pont a kvízért megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    if (form.category == -1) {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Kategória kiválasztása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    if (form.quizExperience.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Hír címének megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    if (form.title.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Hír címének megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    if (form.lead.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Lead szöveg megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    if (form.description.value == "") {
      setInfoModalTitle("Figyelmeztetés");
      setInfoModalDescription("Hír szövegének megadása kötelező!");
      setInfoModalVisibility(true);
      return false;
    }
    return true;
  };

  const onVisibleFromChange = (date: Date | null, event: any) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBLE_FROM,
      visibleFrom: date!.toISOString(),
    });
  };

  const onVisibleToChange = (date: Date | null, event: any) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBLE_TO,
      visibleTo: date!.toISOString(),
    });
  };

  const onStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_STATUS,
      status: parseInt(event.target.value),
    });
  };

  const onImageVisibilityInListChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_IMAGE_VISIBILITY_IN_LIST,
      imageVisibilityInList: parseInt(event.target.value),
    });
  };

  const onCategoryChange = (categoryId: any) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_CATEGORY,
      category: categoryId,
    });
  };

  const onNewsVisibilityChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_VISIBILITY,
      visibility: parseInt(event.target.value),
    });
  };

  const onHighlightedButtonClick = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch({ type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_HIGHLIGHTED });
  };

  const onPushButtonClick = async (event: MouseEvent) => {
    event.preventDefault();

    if (form.id != null) {
      await sendPushMessage(
        "DK Hír",
        form.title.value,
        form.id.toString(),
        "news"
      );

      setInfoModalTitle("Értesítés");
      setInfoModalDescription("Az értesítés kiküldése megtörtént!");
      setInfoModalVisibility(true);
    }
  };

  const onPushChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_PUSH,
      sendPush: event.target.value,
    });
  };

  const onStatementByChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_STATEMENT_BY,
      statementBy: event.target.value,
    });
  };

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_TITLE,
      title: event.target.value,
    });
  };

  const onLeadChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_LEAD,
      lead: event.target.value,
    });
  };

  const onDescriptionChange = (text: string) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_DESCRIPTION,
      description: text,
    });
  };

  const onQuestionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_QUESTION,
      question: event.target.value,
    });
  };

  const onAnswer1Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER1,
      answer: event.target.value,
    });
  };

  const onCorrectAnswerButtonClick = (answerId: number) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_CORRECT_ANSWER_ID,
      id: answerId,
    });
  };

  const onAnswer2Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER2,
      answer: event.target.value,
    });
  };

  const onAnswer3Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER3,
      answer: event.target.value,
    });
  };

  const onAnswer4Change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_ANSWER4,
      answer: event.target.value,
    });
  };

  const onDeletePoliticianClick = (event: MouseEvent) => {
    dispatch({
      type: CREATE_NEWS_ACTIONS.CREATE_NEWS_REMOVE_POLITICIAN,
      selectedPoliticianId: parseInt(event.currentTarget.id),
    });
  };

  const politicianElements: JSX.Element[] = [];

  if (relatedPoliticians !== null && relatedPoliticians !== undefined) {
    relatedPoliticians.map((politicianElement: Politician) => {
      politicianElements.push(
        <PoliticianCard
          politicianElement={politicianElement}
          onDeleteClick={onDeletePoliticianClick}
        />
      );
    });
  }

  return (
    <main className="page page-news page-create-news">
      <div className="main-div">
        <div className="navigation-title">
          <NavLink to="/hirek">
            <h1 className="title-text-h1"> Hírek áttekintése {">"} </h1>
          </NavLink>
          <NavLink to="/hirek/lista">
            <h1 className="title-text-h1"> Országos hírek {">"} </h1>
          </NavLink>
          <h1 className="title-text-h1">
            {" "}
            {form.id == null ? "Új hír" : "Hír szerkesztése"}{" "}
          </h1>
        </div>

        <TabView
          tabs={{ news: "Hír", stats: "Statisztika" }}
          forcedTab={form.id == null ? `news` : undefined}
        >
          <TabViewPage key="news">
            <div className="toolbar">
              <button
                className="button button--ghost back-button"
                onClick={onBackClick}
              >
                Vissza
              </button>

              <button
                className="button button-white preview-button"
                onClick={onPreviewButtonClick}
              >
                {" "}
                Előnézet{" "}
              </button>
              <button
                className="button save-button"
                onClick={onSaveButtonClick}
              >
                {" "}
                Mentés{" "}
              </button>
            </div>

            <form action="" className="create-news-form" ref={formElement}>
              <div className="container">
                <div className="card-div card-div-right-margin">
                  <div className="card-sub-div-right-margin">
                    <label htmlFor="visibilityFrom" className="title-text-h2">
                      Megjelenés időpontja *
                    </label>

                    <DatePicker
                      selected={new Date(form.visibleFrom)}
                      onChange={onVisibleFromChange}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={new Date()}
                      minTime={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDate(),
                          new Date().getHours(),
                          new Date().getMinutes()
                        )
                      }
                      maxTime={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDate(),
                          23,
                          59
                        )
                      }
                      timeFormat="HH:mm"
                      dateFormat="yyyy. MM. dd. HH:mm"
                    />
                  </div>

                  <div className="card-sub-div-right-margin">
                    <h2 className="title-text-h2">Elrejtés időpontja</h2>

                    <DatePicker
                      selected={
                        form.visibleTo != null ? new Date(form.visibleTo) : null
                      }
                      onChange={onVisibleToChange}
                      showTimeSelect
                      timeIntervals={1}
                      minDate={new Date(form.visibleFrom)}
                      minTime={
                        new Date(form.visibleFrom).getFullYear() ==
                          new Date(form.visibleTo!).getFullYear() &&
                        new Date(form.visibleFrom).getMonth() ==
                          new Date(form.visibleTo!).getMonth() &&
                        new Date(form.visibleFrom).getDate() ==
                          new Date(form.visibleTo!).getDate()
                          ? new Date(
                              new Date(form.visibleFrom).getFullYear(),
                              new Date(form.visibleFrom).getMonth(),
                              new Date(form.visibleFrom).getDate(),
                              new Date(form.visibleFrom).getHours(),
                              new Date(form.visibleFrom).getMinutes()
                            )
                          : new Date(
                              new Date(form.visibleTo!).getFullYear(),
                              new Date(form.visibleTo!).getMonth(),
                              new Date(form.visibleTo!).getDate(),
                              0,
                              0
                            )
                      }
                      maxTime={
                        new Date(form.visibleFrom).getFullYear() ==
                          new Date(form.visibleTo!).getFullYear() &&
                        new Date(form.visibleFrom).getMonth() ==
                          new Date(form.visibleTo!).getMonth() &&
                        new Date(form.visibleFrom).getDate() ==
                          new Date(form.visibleTo!).getDate()
                          ? new Date(
                              new Date(form.visibleFrom).getFullYear(),
                              new Date(form.visibleFrom).getMonth(),
                              new Date(form.visibleFrom).getDate(),
                              23,
                              59
                            )
                          : new Date(
                              new Date(form.visibleTo!).getFullYear(),
                              new Date(form.visibleTo!).getMonth(),
                              new Date(form.visibleTo!).getDate(),
                              23,
                              59
                            )
                      }
                      timeFormat="HH:mm"
                      dateFormat="yyyy. MM. dd. HH:mm"
                    />
                  </div>

                  <div className="">
                    <h2 className="title-text-h2">Státusz</h2>
                    <div className="check-box-group">
                      <div>
                        <input
                          checked={form.status == NewsStatus.Active}
                          type="radio"
                          id="status_active"
                          name="status"
                          value={NewsStatus.Active}
                          onChange={onStatusChange}
                        />
                        <label className="label-text" htmlFor="status_active">
                          Aktív
                        </label>

                        <input
                          checked={form.status == NewsStatus.Draft}
                          type="radio"
                          id="status_draft"
                          name="status"
                          value={NewsStatus.Draft}
                          onChange={onStatusChange}
                        />
                        <label className="label-text" htmlFor="status_draft">
                          Sablon
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-div">
                  <div className="">
                    <h2 className="title-text-h2">
                      Hír megjelenése a listában
                      <InfoTextbox text={"Itt állíthatod be a hír stílusát."} />
                    </h2>

                    <div className="check-box-group">
                      <div>
                        <input
                          checked={form.imageVisibilityInList == 0}
                          type="radio"
                          id="visibility_image"
                          name="visibility_in_list"
                          value={0}
                          onChange={onImageVisibilityInListChange}
                        />
                        <label
                          className="label-text"
                          htmlFor="visibility_image"
                        >
                          Képes hír
                        </label>

                        <input
                          checked={form.imageVisibilityInList == 1}
                          type="radio"
                          id="visibility_text"
                          name="visibility_in_list"
                          value={1}
                          onChange={onImageVisibilityInListChange}
                        />
                        <label className="label-text" htmlFor="visibility_text">
                          Szöveges hír
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container container-top-margin">
                {/* --- Kategória --- */}
                <div className="card-div-wrap-content card-div-right-margin">
                  <div className="">
                    <h2 className="title-text-h2">Kategória kiválasztása *</h2>
                    <Dropdown
                      value={form.category}
                      items={categoriesElements}
                      onChange={onCategoryChange}
                      compact={false}
                    />
                  </div>
                </div>

                {/* --- Hír láthatósága --- */}
                <div className="card-div card-div-right-margin">
                  <div className="">
                    <h2 className="title-text-h2">Hír láthatósága</h2>
                    <div className="check-box-group">
                      <div className="input-group-div">
                        <input
                          checked={form.visibility == NewsVisibility.Open}
                          type="radio"
                          id="news_visibility_open"
                          name="news_visibility"
                          value={NewsVisibility.Open}
                          onChange={onNewsVisibilityChange}
                        />
                        <label
                          className="label-text checkbox-label"
                          htmlFor="news_visibility_open"
                        >
                          Külső hír
                        </label>

                        <input
                          checked={form.visibility == NewsVisibility.Internal}
                          type="radio"
                          id="news_visibility_internal"
                          name="news_visibility"
                          value={NewsVisibility.Internal}
                          onChange={onNewsVisibilityChange}
                        />
                        <label
                          className="label-text checkbox-label"
                          htmlFor="news_visibility_internal"
                        >
                          Belső hír
                        </label>

                        <input
                          checked={form.visibility == NewsVisibility.Closed}
                          type="radio"
                          id="news_visibility_closed"
                          name="news_visibility"
                          value={NewsVisibility.Closed}
                          onChange={onNewsVisibilityChange}
                        />
                        <label
                          className="label-text checkbox-label"
                          htmlFor="news_visibility_closed"
                        >
                          Zárt hír
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --- Kiemelt hír--- */}
                <div className="card-div">
                  <div className="sub-title-text-center">
                    <h2 className="title-text-h2 sub-title-text-center">
                      Kiemelt hír
                    </h2>
                    <button
                      className="button button-white single-button-margin"
                      onClick={onHighlightedButtonClick}
                    >
                      {" "}
                      {form.highlighted == 0
                        ? "Hír kiemelése"
                        : "Hír kiemelésének megszüntetése"}{" "}
                    </button>
                  </div>
                </div>
              </div>

              {/* --- Push --- */}
              <div style={{ display: `flex` }}>
                <div className="container container-top-margin">
                  <div
                    className="card-div-wrap-content card-div-bottom-padding"
                    style={{ width: `100%` }}
                  >
                    <div className="">
                      <h2 className="title-text-h2">
                        ”Értesítés” küldése{" "}
                        <InfoTextbox
                          text={
                            "Itt állíthatod be, hogy a felhasználók kapjanak-e 'Értesítést'."
                          }
                        />
                      </h2>

                      <div className="">
                        <form>
                          <div className="check-box-group">
                            <div>
                              <input
                                checked={form.sendPush == 0}
                                type="radio"
                                id="push_yes"
                                name="push"
                                value={0}
                                onChange={onPushChange}
                              />
                              <label className="label-text" htmlFor="push_yes">
                                A hír élesedésénél kapjanak “értesítést”
                              </label>

                              <input
                                checked={form.sendPush == 1}
                                type="radio"
                                id="push_no"
                                name="push"
                                value={1}
                                onChange={onPushChange}
                              />
                              <label className="label-text" htmlFor="push_no">
                                Ne kapjanak “értesítést”
                              </label>

                              {form.id != null &&
                              form.status == NewsStatus.Active ? (
                                <button
                                  className="button button-white"
                                  onClick={onPushButtonClick}
                                >
                                  Azonnali Értesítés küldés
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card-div"
                  style={{ marginLeft: 20, marginTop: 20, width: 526 }}
                >
                  <TagSelector
                    tags={form.tags || []}
                    onTagAdd={(tag) =>
                      dispatch({ type: `NEWS_ADD_TAG`, tag: tag })
                    }
                    onTagDelete={(tag) =>
                      dispatch({ type: `NEWS_REMOVE_TAG`, tag: tag })
                    }
                  />
                </div>
              </div>

              <div className="card-div" style={{ marginTop: 20 }}>
                <div className="xp-editor">
                  <img alt="" src="/image/exp.svg" />

                  <div className="field">
                    <label>
                      Tapasztalati pont a végigolvasásért (1-50 TP) *
                    </label>
                    <TextField
                      field={form.experience}
                      onChange={(e) =>
                        changeWithinLimits(
                          `CREATE_NEWS_CHANGE_EXPERIENCE`,
                          `experience`,
                          e,
                          1,
                          50
                        )
                      }
                    />
                  </div>

                  <div className="field">
                    <label>Tapasztalati pont a kvízért (100-250 TP) *</label>
                    <TextField
                      field={form.quizExperience}
                      onChange={(e) =>
                        changeWithinLimits(
                          `CREATE_NEWS_CHANGE_QUIZ_EXPERIENCE`,
                          `quizExperience`,
                          e,
                          100,
                          250
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              {/* --- Hír tartalmi elemeinek megadása --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">
                      Hír tartalmi elemeinek megadása
                    </h2>

                    <div className="container">
                      {/* --- Kép--- */}
                      <div className="image-section-container">
                        <h3
                          className="title-text-h3 "
                          style={{ marginBottom: "16px" }}
                        >
                          Kép hozzáadása (16:9)
                        </h3>

                        <AddImage
                          width={"255px"}
                          height={"148px"}
                          imageUrl={form.imageUrl}
                          text={"Kép hozzáadása a hírhez (16:9)"}
                          onAddImage={() => setMediaLibraryVisibility(true)}
                          onDeleteImage={() => {
                            setMediaLibraryFile(null);
                            dispatch({
                              type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_MEDIA_LIBRARY_FILE,
                              mediaLibraryFile: null,
                            });
                          }}
                        />
                      </div>

                      {/* --- Ki tette a nyilatkozatot?--- */}
                      <div className="text-section-container">
                        <div className="input-container-header">
                          <h3 className="title-text-h3 title-text-bottom-padding">
                            Közlemény/hír aláírója
                          </h3>
                          <span className="label-text info">
                            Amennyiben nincs aláírója a hírnek, úgy ez a mező
                            üresen hagyható!
                          </span>
                        </div>
                        <TextField
                          field={form.statementBy}
                          maxlength={255}
                          onChange={onStatementByChange}
                          placeholder=""
                          category="statement"
                        />

                        <div className="input-container-header container-top-margin">
                          <h3 className="title-text-h3 title-text-bottom-padding">
                            A hír címe *
                          </h3>
                          <span className="label-text info">
                            Ajánlott karakter szám maximum: 120 karakter
                          </span>
                        </div>

                        <TextField
                          field={form.title}
                          maxlength={255}
                          onChange={onTitleChange}
                          placeholder=""
                          category="title"
                        />
                      </div>
                    </div>

                    {/* --- Lead szöveg megadása--- */}
                    <div className="text-section-container container-top-margin">
                      <h3 className="title-text-h3">Lead szöveg megadása *</h3>
                      <TextArea
                        field={form.lead}
                        onChange={onLeadChange}
                        placeholder=""
                        category="lead"
                        maxLength={65535}
                      />

                      <DescriptionTextArea
                        title={"Hír szövegének megadása *"}
                        field={form.description}
                        onChange={onDescriptionChange}
                        maxLength={65535}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Kapcsolódó politikusok --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">
                      Kapcsolódó politikusaink megadása
                    </h2>

                    <div className="politician-section-container">
                      <div
                        className="politician-container"
                        onClick={onAddPoliticianButtonClick}
                      >
                        <div className="image-container-image">
                          <span className="material-symbols-outlined icon-white">
                            add
                          </span>
                        </div>
                        <span className="label-text label-text-primary">
                          Új politikus hozzáadása
                        </span>
                      </div>

                      <ul
                        id={"related_politician_table"}
                        className="politician-list"
                      >
                        {politicianElements.length != 0
                          ? politicianElements
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* --- Hír kvíz --- */}
              <div className="container container-top-margin">
                <div className="card-div">
                  <div className="container column">
                    <h2 className="title-text-h2">Kvíz a hírhez</h2>

                    <div className="text-section-container container-top-margin">
                      <h3 className="title-text-h3">Kvízkérdés</h3>
                      <TextArea
                        field={form.question}
                        onChange={onQuestionChange}
                        placeholder=""
                        category="question"
                        maxLength={255}
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          1. Válasz
                        </h3>

                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 1
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(1)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer1}
                        onChange={onAnswer1Change}
                        placeholder=""
                        category="answer"
                        maxLength={255}
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          2. Válasz
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 2
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(2)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer2}
                        onChange={onAnswer2Change}
                        placeholder=""
                        category="answer"
                        maxLength={255}
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          3. Válasz
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 3
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(3)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer3}
                        onChange={onAnswer3Change}
                        placeholder=""
                        category="answer"
                        maxLength={255}
                      />

                      <div className="container container-center">
                        <h3 className="title-text-h3  header-text-center">
                          4. Válasz
                        </h3>
                        <button
                          className={`correct-answer-button ${
                            correctAnswerId == 4
                              ? ``
                              : `correct-answer-button--ghost`
                          }`}
                          type="button"
                          onClick={(e) => onCorrectAnswerButtonClick(4)}
                        >
                          Ez a helyes válasz
                        </button>
                      </div>
                      <TextArea
                        field={form.question.answer4}
                        onChange={onAnswer4Change}
                        placeholder=""
                        category="answer"
                        maxLength={255}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <footer>
                <button
                  className="button button-white preview-button"
                  onClick={onPreviewButtonClick}
                >
                  Előnézet
                </button>
                <button
                  className="button save-button"
                  onClick={onSaveButtonClick}
                >
                  Mentés
                </button>
              </footer>
            </form>
          </TabViewPage>

          <TabViewPage key="stats">
            <NewsStatsTab />
          </TabViewPage>
        </TabView>
      </div>

      {infoModalVisibility ? (
        <MessageModal
          title={infoModalTitle}
          message={infoModalDescription}
          onCloseClick={() => {
            setInfoModalVisibility(false);
          }}
        />
      ) : null}

      {previewNews !== null ? (
        <NewsModal
          newsElement={previewNews}
          relatedPoliticians={form.relatedPoliticians}
          onCloseClick={onPreviewCloseButtonClick}
          onModalClose={() => setPreviewNews(null)}
        />
      ) : null}

      {polticiansModalVisibility ? (
        <PoliticianModal
          onCloseClick={onAddPoliticianCloseButtonClick}
          onModalClose={() => setPolticiansModalVisibility(false)}
        />
      ) : null}

      {mediaLibraryVisibility ? (
        <MediaLibrary
          onClose={() => setMediaLibraryVisibility(false)}
          onMediaSelected={(file: MediaLibraryFile) => {
            setMediaLibraryFile(file);
            setMediaLibraryVisibility(false);
            setImageEditorVisibility(true);
          }}
        />
      ) : null}

      {imageEditorVisibility ? (
        <ImageEditor
          mediaLibraryFile={mediaLibraryFile!}
          aspectRatio={{ name: "16:9", ratio: 16 / 9 }}
          onClose={() => setImageEditorVisibility(false)}
          onReady={(file) => {
            dispatch({
              type: CREATE_NEWS_ACTIONS.CREATE_NEWS_CHANGE_MEDIA_LIBRARY_FILE,
              mediaLibraryFile: file,
            });
            setImageEditorVisibility(false);
          }}
        />
      ) : null}
    </main>
  );
};
