import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { getCourse, listCourseTopics } from '../../action/courses'
import { listTags } from '../../action/tags'
import { getPoliticians } from '../../action/politician'

export const courseLoader = async ( args: LoaderFunctionArgs ) => {

    await getPoliticians()

    await listTags()

    await listCourseTopics()

    if ( args.params.id ) {

        await getCourse( parseInt( args.params.id ) )

    } else {

        store.dispatch( { type: `GET_COURSE_OK`, course: null } )

    }

}