import { Expose, Type } from 'class-transformer'
import { IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator'
import { County, Gender, Position } from '../enum'
import { UserGroup } from './user-group.entity'
import { League } from './league.entity'
import { Tag } from './tag.entity'

export class User {

    checked: boolean = false

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    membershipId!: string

    @Expose()
    @IsDefined()
    @IsString()
    firstName!: string

    @Expose()
    @IsDefined()
    @IsString()
    lastName!: string

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    username!: string  | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf( ( object, value ) => value !== null )
    avatarId!: number  | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    email!: string  | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf( ( object, value ) => value !== null )
    birthdate!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn( Object.keys( Gender ).map( ( key: string ) => parseInt( key ) ).filter( ( key: number ) => !isNaN( key ) ) )
    gender!: number

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    phone!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    country!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn( Object.keys( County ).map( ( key: string ) => parseInt( key ) ).filter( ( key: number ) => !isNaN( key ) ) )
    county!: number

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    city!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    zipcode!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    address!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    floorAndDoor!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf( ( object, value ) => value !== null )
    houseNumber!: string | null

    @Expose()
    @IsDefined()
    @IsNumber()
    experience!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn( Object.keys( Position ).map( ( key: string ) => parseInt( key ) ).filter( ( key: number ) => !isNaN( key ) ) )
    position!: number

    @Expose()
    @IsOptional()
    @IsNumber()
    lastUserActivity?: number

    @Expose()
    @IsOptional()
    @ValidateNested()
    @Type( type => UserGroup )
    userGroups!: UserGroup[]

    @Expose()
    league!: League

    @Expose()
    tags!: Tag[]

    @Expose()
    lastAdminActivity!: number | null

}
